import Styles from "./ChatTip.module.scss";
import { useEffect, useRef, useState } from "react"
import coworkerIcon from "@/assets/images/coworker_small.png";

const getChatTipText = (path: string) => {
  const config = {
    '/designList': '担心跟家里不搭，缺货，想重新设计',
    '/scan': '担心跟家里不搭，缺货，想重新设计',
    '/detail': '担心跟家里不搭，缺货，想重新设计',
    '/poster': '担心跟家里不搭，缺少信息，缺货',
    '/selectExteriorStyle': '想要拼门，不确定门板',
    '/dtcSelectExteriorStyle': '想要拼门，不确定门板',
    '/template_overview': '不确定内配布局，想要替换调整',
    '/wardrobe_list': '找不到想要的内配布局，不知道如何调整',
    '/interior_design': '想要其他储物需求，不明白部分储物',
    '/editor': '想要替换配件，删除配件等精细调整', 
    '/':'想要定制衣柜，转角柜，更多衣柜信息'
  } as {
    [key: string] : string
  }
  let result = config['/']
  for (const i of Object.keys(config)) {
    if (path.includes(i)) {
      result = config[i]
      break
    }
  }
  return result
}

interface IChatProps {
  anchor: DOMRect
}

function ChatTip(props: IChatProps) {
  const { anchor } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [dynamicStyle, setDynamicStyle] = useState({})
  const [anchorStyle, setAnchorStyle] = useState({})
  useEffect(() => {
    if (!containerRef.current) return
    const viewportWidth = window.innerWidth
    const containerWidth = containerRef.current.offsetWidth
    const minLeft = 12
    const maxLeft = viewportWidth - containerWidth - 12
    const { x, y, bottom, right } = anchor;
    const anchorInCenterLeft = x - containerWidth / 2
    setDynamicStyle({
      left: (x > viewportWidth / 2) 
              ? Math.min(maxLeft, anchorInCenterLeft)
              : Math.max(minLeft, anchorInCenterLeft),
      top: bottom,
      visibility: 'visible',
      opacity: 1
    })
    setAnchorStyle({
      left: (right + x )/ 2,
      top: bottom,
      visibility: 'visible',
      opacity: 1
    })
  }, [anchor, containerRef])

  return (
    <>
      <div className={Styles.anchor} style={anchorStyle}></div>
      <div className={Styles.chatTip} style={dynamicStyle} ref={containerRef}>
        是否需要帮助？比如<b>{getChatTipText(window.location.pathname)}</b>等。可以联系设计师帮你解决。
        <img src={coworkerIcon} className={Styles.img} alt="" />
      </div>
    </>
  )
}

export default ChatTip