import { useEffect, useRef, useState } from "react";
import DownArrowIcon from "../../assets/icons/arrow_down.svg";
import UpArrowIcon from "../../assets/icons/arrow_up.svg";
import CheckedIcon from "../../assets/icons/checked.svg";
import Drawer from "../Drawer/Drawer";
import classnames from "clsx";
import styles from "./Filter.module.scss";
import { IRecommendType, SearchConfActionType, useConfig, } from "../../reducers/searchConfReducer";
import { filterDoorCombList, fittingList, IList, peopleList, PriceList, } from "./FilterConfig";
import { cloneDeep } from "lodash";
import dislikeMask from "../../assets/icons/dislike_mask2.svg";
import { BASE_SIZE, PeopleType } from "../../common/CommonType";
import { TopToast } from "../Toast/TopToast";
import { Tab, Tabs } from "@material-ui/core";
import Priority from "../../assets/images/ranking/priority.png";
import SaleVolume from "../../assets/images/ranking/saleVolume.png";
import StorageNeed from "../../assets/images/ranking/storageNeed.png";
import MCCSimilarity from "../../assets/images/ranking/mccSimilarity.png";
// import Performance from "../../assets/images/ranking/performance.png";
import TemplateTool from "@/utils/template-tool";
import { PaxActionType, usePaxConfig } from "@/reducers/paxReducer";
import ContCompForCombine from "./ContCompForCombine";
import { getGioPlatform } from '@/utils/ikea-sdk';
import { impression } from "@/utils/common-tool"
import { GIO, GioModuleVar, interior_scheme_tab_gio } from "@/utils/gioUtils";

export interface IProps {
  isTest?: boolean;
  width?: number;
}

export interface IItem {
  name: string;
  type: string;
  active?: boolean;
  list: IList[];
}
interface IRecommendItem {
  index: number;
  text: string;
  tip: string;
  type: string;
  img: string;
}

const recommendConfig: IRecommendItem[] = [{
  index: 0,
  text: '综合推荐',
  tip: '综合所有维度进行推荐',
  type: IRecommendType.Priority,
  img: Priority
},
{
  index: 1,
  text: '销量优先',
  tip: '侧重销量进行推荐',
  type: IRecommendType.SaleVolume,
  img: SaleVolume
},
{
  index: 2,
  text: '宜家设计师精选',
  tip: '专业衣柜设计师推荐',
  type: IRecommendType.MCCSimilarity,
  img: MCCSimilarity
},
{
  index: 3,
  text: '储物需求优先',
  tip: '更好的满足您所选的储物需求',
  type: IRecommendType.StorageNeed,
  img: StorageNeed
},
  // {
  //   index: 4,
  //   text: '性价比优先',
  //   tip: '根据专业设计来推荐',
  //   type: IRecommendType.Performance,
  //   img: Performance
  // }
]

const Filter: React.FC<IProps> = (props: IProps) => {
  const { isTest, width } = props
  const { state, dispatch } = useConfig();
  const { state: paxState, dispatch: PaxDispatch } = usePaxConfig();
  const [recommendItem, setRecommendItem] =
    useState<IRecommendItem>(recommendConfig[0])
  // 获取柜子的相关信息
  const templateTool = new TemplateTool(paxState.recommendTemplate?.wardrobeWithProduct)
  const doorList = filterDoorCombList(width || templateTool.wardrobeWidth || BASE_SIZE.WIDTH, state.doorType)
  const [combineList] = useState(doorList);
  // const showImgCombineFilter = sessionStorage.getItem('abTestCombineFilter') === 'img';
  const tabDoms = useRef<{
    name: string,
    dom: HTMLElement
  }[]>([])

  useEffect(() => {
    tabDoms.current.forEach(item => {
      impression(() => {
        interior_scheme_tab_gio(item.name)
      }, item.dom)
    })
  }, [tabDoms])

  // 将当前内配的可选组合存到context中，当选择自定义内配时可用到
  useEffect(() => {
    PaxDispatch({
      type: PaxActionType.Update,
      payload: {
        filterCombineList: combineList
      }
    })
  }, [PaxDispatch, combineList])

  const data: IItem[] = [
    {
      name: "价格排序",
      type: "priceType",
      active: false,
      list: PriceList,
    },
    {
      name: "配件筛选",
      type: "fittings",
      list: fittingList,
    },
    {
      name: "柜体组合",
      type: "combinationType",
      list: doorList,
    },
    {
      name: "使用人群",
      type: "people",
      list: peopleList,
    }
  ];
  const dataTemp = cloneDeep(data);

  // 是否显示抽屉
  const [show, setShow] = useState(false);
  // 所有项
  const [tabData, setTabData] = useState(dataTemp);
  // 选中的项
  const [currentItem, setCurrentItem] = useState(dataTemp[0]);
  // 切换选项
  const toggleItem = async (item: IItem) => {
    const isShow = show && item.type === currentItem.type ? false :true;
    setShow(isShow)
    // 对"配件筛选"和"柜体组合"进行埋点
    if (item.type === 'fittings' || item.type === 'combinationType') {
      const gioPlatform = await getGioPlatform();
      window.gio('track', 'clickData', {
        'eventType_var': "click",
        'platform_var': gioPlatform,
        'pageType_var': 'pax_recommend',
        'pageId_var': window.location.href,
        'pageName_var': '2/3选择内配方案',
        'module_var': 'filter',
        'objectName_var': item.type === 'combinationType' ? '柜体组合' : '配件筛选',
      });
    }
    isShow && GIO('track', 'clickData',  {
      eventType_var: 'click',
      module_var: GioModuleVar.filter,
      objectName_var: item.name
    })
    setCurrentItem(item);
  };
  const [isShowToast, setIsShowToast] = useState(false);

  const [initStatus, setInitStatus] = useState(true)
  const lastScrollTop = useRef<any>()
  const handleScroll = () => {
    const curScrollTop = document.querySelector('#WardrobeListWrap')?.scrollTop;
    if (curScrollTop !== undefined && curScrollTop >= 0) {
      // (curScrollTop > lastScrollTop.current) ? setToTop(false) : setToTop(true);
      lastScrollTop.current = curScrollTop;
      curScrollTop === 0 ? setInitStatus(true) : setInitStatus(false)
    }
  }

  useEffect(() => {
    if (width) {
      setTabData(dataTemp)
      setCurrentItem(dataTemp[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    if (!isTest) {
      document.querySelector('#WardrobeListWrap')?.addEventListener('scroll', () => { handleScroll() })
      return document.querySelector('#WardrobeListWrap')?.removeEventListener('scroll', () => { handleScroll() })
    }
  }, [isTest])

  // 选中内容
  const setCurrentType = (item: IList) => {
    currentItem.list.forEach((info) => {
      item.code === info.code ? (item.checked = true) : (info.checked = false);
    });

    const params: { fulfillLevel?: string; combination?: string } = {};
    tabData.forEach((info) => {
      if (info.type === "priceType" && currentItem.type === info.type)
        params.fulfillLevel = info.list.filter((val) => val.checked)[0].value;
      if (info.type === "combinationType" && currentItem.type === info.type)
        params.combination = info.list.filter((val) => val.checked)[0].value;
    });
    dispatch({
      type: SearchConfActionType.UpdateConfig,
      payload: Object.assign({}, state, {
        combination: params.combination,
        fulfillLevel: params.fulfillLevel,
      }),
    });
    setCurrentItem(currentItem);
    setTabData(tabData);
    setShow(false);
  };

  const checkOptions = (item: IList) => {
    if (currentItem.type === "fittings") {
      const checkedList = currentItem.list.filter((item) => item.checked);
      if (checkedList.length >= 3 && !item.checked) {
        setIsShowToast(true);
        setTimeout(() => {
          setIsShowToast(false);
        }, 2000);
        return false;
      }
      item.checked = !item.checked;
    } else {
      currentItem.list.forEach((info) => {
        item.code === info.code
          ? (item.checked = true)
          : (info.checked = false);
      });
      dispatch({
        type: SearchConfActionType.UpdateConfig,
        payload: {
          peopleType: item.value as PeopleType,
        },
      });
    }

    setCurrentItem({
      ...currentItem,
    });
  };

  const reset = () => {
    currentItem.list.forEach((item) => {
      item.checked = false;
    });

    if (currentItem.type === "fittings") {
      dispatch({
        type: SearchConfActionType.UpdateConfig,
        payload: { dislikeList: [] },
      });
    }

    if (currentItem.type === "people") {
      currentItem.list.forEach((item) => {
        if (item.value === PeopleType.COUPLE) {
          item.checked = true;
        }
      });
      dispatch({
        type: SearchConfActionType.UpdateConfig,
        payload: {
          peopleType: PeopleType.COUPLE,
        },
      });
    }

    setCurrentItem({
      ...currentItem,
    });
    setShow(false);
  };

  const confirm = () => {
    if (currentItem.type === "fittings") {
      const arr = currentItem.list.filter((item) => item.checked === true);
      const values = arr.map((item) => item.value);
      dispatch({
        type: SearchConfActionType.UpdateConfig,
        payload: { dislikeList: values },
      });
    }

    if (currentItem.type === "people") {
      const checked = currentItem.list.find((item) => item.checked === true);

      dispatch({
        type: SearchConfActionType.UpdateConfig,
        payload: {
          peopleType: checked ? (checked.value as PeopleType) : undefined,
        },
      });
    }

    setShow(false);
  };

  // 获取tab上显示的text
  const getShowText = (item: IItem) => {
    if (item.type === "priceType" || item.type === "combinationType") {
      const filterList = item.list.filter((ele: IList) => ele.checked);
      const _item = filterList[0];
      return _item && (filterList.length > 1 ? `${_item.text}...` : _item.text);
    }

    if (item.type === "fittings") {
      if (state.dislikeList && state.dislikeList.length > 0) {
        const checkedList = item.list.filter((item) =>
          state.dislikeList.includes(item.value)
        );
        const checkedTextList = checkedList.map((item) => item.text);
        return checkedTextList.length > 1
          ? `${checkedTextList[0]}...`
          : checkedTextList[0];
      }
      return "";
    }

    if (item.type === "people") {
      if (state.peopleType) {
        const checkedItem = item.list.find(
          (item) => item.value === state.peopleType
        );
        return checkedItem?.text;
      }
    }

    return "";
  };

  const setDefaultTabText = (info: IItem, type: string) => {
    info.list.forEach((item) => {
      item.checked = false;
      if (
        item.value ===
        (type === "combinationType" ? state.combination : state.fulfillLevel)
      )
        item.checked = true;
    });
  };
  // 选择推荐选项
  const handleChange = (event: any, index: any) => {
    if (recommendItem.index === index) return
    const curRecommendConfig = recommendConfig[index]
    setRecommendItem(curRecommendConfig)
    dispatch({
      type: SearchConfActionType.UpdateConfig,
      payload: Object.assign({}, state, {
        ranking: curRecommendConfig.type,
      }),
    });
    GIO('track', 'clickData', {
      eventType_var: "click",
      module_var: GioModuleVar.recommendOption,
      objectName_var: curRecommendConfig.text,
    })
  }

  useEffect(() => {
    // 初始化的时候赋值默认选中的combination和fulfillLevel值
    tabData.forEach((info) => {
      setDefaultTabText(info, info.type);
    });

    tabData[1].list = tabData[1].list.map((item) => {
      return {
        ...item,
        checked: state.dislikeList.includes(item.value),
      };
    });
    tabData[2].list = tabData[2].list.map((item) => {
      return {
        ...item,
        checked: state.peopleType === item.value,
      };
    });

    setTabData([...tabData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TabComp = (item: IItem) => (
    <div className={styles.TabComp}>
      <span className={styles.tabText}>{getShowText(item) || item.name}</span>
      <img
        className={styles.imgIcon}
        src={
          show && currentItem.type === item.type ? UpArrowIcon : DownArrowIcon
        }
        alt=""
      />
    </div>
  );

  const ContComp = () => (
    <ul className={classnames(styles.ulWrap)}>
      {currentItem.list.map((item, index) => {
        return (
          <li
            key={index}
            className={styles.line}
            onClick={() => setCurrentType(item)}
          >
            <span>{item.text}</span>
            {item.checked && (
              <img className={styles.checkedIcon} src={CheckedIcon} alt="" />
            )}
          </li>
        );
      })}
    </ul>
  );

  const ContComp2 = () => {
    return (
      <>
        <ul className={classnames(styles.ulWrap2)}>
          {currentItem.list.map((item, index) => {
            return (
              <li
                key={index}
                className={classnames(styles.boxItem, {
                  [styles.active]:
                    item.checked && currentItem.type === "people",
                })}
                onClick={() => checkOptions(item)}
              >
                {currentItem.type === "fittings" && (
                  <>
                    <div className={styles.imgBox}>
                      <img src={item.icon.default} alt="" />
                    </div>
                    <span>{item.text}</span>
                    {item.checked && (
                      <img
                        className={styles.dislikeMask}
                        src={dislikeMask}
                        alt=""
                      />
                    )}
                  </>
                )}

                {currentItem.type === "people" && (
                  <>
                    <div className={classnames(styles.peopleImgBox)}>
                      <img
                        src={item.checked ? item.activeIcon : item.icon}
                        alt=""
                      />
                    </div>
                    <div>
                      <p>{item.text}</p>
                      <p className={styles.itemDesc}>{item.desc}</p>
                    </div>
                  </>
                )}
              </li>
            );
          })}
        </ul>
        {currentItem.type !== "people" && (
          <div className={styles.btnBox}>
            <div className={styles.btnReset} onClick={reset}>
              重置
            </div>
            <div className={styles.btnConfirm} onClick={confirm}>
              确定
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isShowToast && (
        <TopToast
          desc="最多只能选择三个不需要的配件"
          onClose={() => setIsShowToast(false)}
        ></TopToast>
      )}
      <div className={styles.Container}>
        <Drawer
          tabData={tabData}
          tabComp={TabComp}
          contComp={
            currentItem.type === "priceType"
              ? ContComp
              : currentItem.type === "combinationType"
                ? () => (
                    <ContCompForCombine
                      list={currentItem.list}
                      onSelect={(item: IList) => () => setCurrentType(item)}
                    />
                  )
                // ? showImgCombineFilter
                //   ? () => (
                //       <ContCompForCombine
                //         list={currentItem.list}
                //         onSelect={(item: IList) => () => setCurrentType(item)}
                //       />
                //     )
                //   : ContComp
                : ContComp2
          }
          toggleItem={toggleItem}
          currentItem={currentItem}
          contShow={show}
          setContShow={setShow}
        />
      </div>

      <div className={classnames(styles.recommendWrap, { [styles.recommendWrapInit]: initStatus })}>
        <Tabs
          value={recommendItem.index}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
        >
          {recommendConfig.map((r, index) => {
            return (
              <Tab
                className={classnames({ [styles.bigTab]: initStatus })}
                classes={{
                  wrapper: 'init',
                  root: 'initRoot'
                }}
                key={r.type}
                label={
                  <div
                    className={classnames(styles.recommendItem, { [styles.active]: recommendItem.index === index })}
                    onClick={() => handleChange('', index)}
                    ref={(dom) => dom && tabDoms.current.push({
                      name: r.text,
                      dom,
                    })}
                  >
                    <img src={r.img} className={styles.recommendImg} alt="icon" />
                    <p className={styles.recommendName}>{r.text}</p>
                    <p className={styles.recommendTip}>{r.tip}</p>
                  </div>
                } />
            )
          })}
        </Tabs>
      </div>
      <div className={classnames(styles.recommendWrap, { [styles.recommendWrapSmall]: !initStatus })}>
        <Tabs
          value={recommendItem.index}
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleChange}
          variant="scrollable"
        >
          {recommendConfig.map(r => <Tab key={r.type} label={r.text}
            className={classnames({ [styles.smallTab]: !initStatus })} />)}
        </Tabs>
      </div>
    </>
  );
};

export default Filter;
