import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePaxConfig } from "@/reducers/paxReducer";
import BackIcon from "../../assets/icons/back.svg";
import LogoIcon from "@/assets/logo.svg";
import CloseIcon from "../../assets/icons/close.svg";
import styles from "./NavBar.module.scss";
import { FROM_FLOW, FROM_PAGE, PLATFORM, STORAGE_KEYS } from "@/common/CommonType";
import chat from "@/assets/images/chat.svg"
// import { useQuery } from "@/hooks";
import { Drawer } from "@material-ui/core";
import QuitDesign from "./QuitDesign";
import { routeBack } from "@/utils/route-tool";
import ChatTip from "./ChatTip";
import ReactDOM from 'react-dom';
import { useCSC } from "@/hooks";
import { GIO, GioModuleVar } from "@/utils/gioUtils";
import CSCNoWorkTime from "../CSCNoWorkTime/CSCNoWorkTime";
import { InfoType } from "@/hooks/userInfo";
import RegularModal from "../Modal/RegularModal";
import TemplateTool from "@/utils/template-tool";
import { useConfig } from "@/reducers/searchConfReducer";
import { BASE_SIZE } from "@/common/CommonType";
interface IProps {
  showBackIcon?: boolean;
  text?: string;
  img?: string;
  rootClass?: string;
  noClose?: boolean;
  noChat?: boolean;
  beforeGoBack?: Function | null;
  vTemplateId?: number; // 最后生成柜子的templateId:7001645
  saveDesign?: (showToast: boolean) => any; // 保存我的方案到我的设计方案列表
  customClose?: boolean;
  customCloseFn?: () => void;
  designCode?: string;
  cscCustomerText?: string
}

const getContainer = (function() {
  let container: HTMLDivElement | null = null;
  return () => {
    if (container) {
      return container
    }
    container = document.createElement('div')
    container.className = "chat-tip"
    document.body.appendChild(container)
    return container
  }
})()

function NavBar(props: IProps) {
  const { showBackIcon = true, text, rootClass, noClose, noChat,beforeGoBack, vTemplateId, img, saveDesign, customClose, customCloseFn, designCode, cscCustomerText } = props;
  const {
    state: { platform, recommendTemplate },
  } = usePaxConfig();
  const { state } = useConfig();
  const templateId = recommendTemplate?.templateId;

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const currentLocation = history.location;
  const currentPathName = currentLocation?.pathname;
  const currentSearch = currentLocation?.search;
  const isCloseIconVisible =
    !noClose &&
    [PLATFORM.APP, PLATFORM.H5].includes(platform);
  const isChatVisible = 
    !noChat &&
    [PLATFORM.APP, PLATFORM.H5].includes(platform);
  // const fromPage = localStorage.getItem(STORAGE_KEYS.FROM_PAGE)
  // const fromFlow = localStorage.getItem(STORAGE_KEYS.FROM_FLOW)
  // const isShowStepGuide = sessionStorage.getItem('isShowStepGuide') === 'SHOW';
  const chatIconRef = useRef<HTMLDivElement>(null)
  const [chatTipShow, setChatTipShow] = useState(false);
  const [isShown, setIsShown] = useState(JSON.parse(sessionStorage.getItem(`chatTip${window.location.pathname}`) || 'false'));
  // const [isShown, setIsShown] = useState(false);
  const [secondCount, setSecondCount] = useState(0)
  const timerRef = useRef<NodeJS.Timeout>()
  const timerHandlerRef = useRef<() => void>()
  const timerHandler = () => {
    let current = secondCount + 1
    if (current >= 15 && current < 25) {
      setChatTipShow(true)
    } else if (current >= 25) {
      setChatTipShow(false)
      current = 0
    }
    setSecondCount(current)
  }
  timerHandlerRef.current = timerHandler
  const documentClickHandlerRef = useRef<() => void>() 
  const documentClickHandle = () => {
    if (chatTipShow) {
      setChatTipShow(false)
    }
    setSecondCount(0)
  }
  documentClickHandlerRef.current = documentClickHandle
  useEffect(() => {
    if (noChat) return;
    const timer = setInterval(() => {
      timerHandlerRef.current && timerHandlerRef.current()
    }, 1000);
    timerRef.current = timer
    const fn = () => {
      documentClickHandlerRef.current && documentClickHandlerRef.current()
    } 
    document.addEventListener('click', fn)

    return () => {
      clearInterval(timer)
      document.removeEventListener('click', fn)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function createChatTip() {
    if (!chatIconRef.current) return
    const rect = chatIconRef.current.getBoundingClientRect();
    const container = getContainer();
    ReactDOM.render(<ChatTip anchor={rect}></ChatTip>, container) 
  }

  useEffect(() => {
    if (chatIconRef.current && chatTipShow && !isShown) {
      setIsShown(true)
      sessionStorage.setItem(`chatTip${window.location.pathname}`, 'true')
      createChatTip()
    } else {
      const container = getContainer();
      container.childNodes.forEach(item => {
        const dom = item as HTMLDivElement
        dom.style.opacity = '0';
        dom.addEventListener('transitionend', () => {
          ReactDOM.unmountComponentAtNode(container)
        })
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatIconRef, chatTipShow])

  const handleBack = () => {
    if (beforeGoBack) {
      beforeGoBack();
    } else {
      routeBack(currentPathName, currentSearch, templateId, (targetBackPathName?: string) => {
        targetBackPathName ? history.replace(targetBackPathName) : history.goBack();
      })
    }
  };

  const handleClose = () => {
    if (customClose) {
      customCloseFn && customCloseFn()
      return
    }
    setOpen(true)
  }

  const getCurrentStep = () => {
    let step = '';
    if (text === '衣柜基础信息') {
      step = '1/3';
    }
    if (text === '选择内配方案' || text === '替换内配布局') {
      step = '2/3';
    }
    if (text === '衣柜外观样式') {
      step = '3/3';
    }
    return step;
  }

  // 获取柜子的相关信息
  const templateTool = new TemplateTool(recommendTemplate?.wardrobeWithProduct)
  const cscData = {
    designCode,
    doorType: state.doorType,
    width: templateTool.wardrobeWidth || BASE_SIZE.WIDTH,
    depth: templateTool.wardrobeDepth || BASE_SIZE.DEPTH,
    height: templateTool.wardrobeHeight || BASE_SIZE.HEIGHT,
    frameColor: templateTool.wardrobeFrameColor || BASE_SIZE.FRAME_COLOR,
    dislikeList: state.dislikeList,
    needList: state.needList
  };
  const { isWorkTime, jumpNewCSC } = useCSC(cscData, cscCustomerText);
  const [showErrorTel, setShowErrorTel] = useState(false);
  const [openCSC, setOpenCSC] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const closeCSC = (closeDesignModal = false) => {
    setOpenCSC(false);
  };
  
  function handleContactDesigner() {
    if (!isWorkTime) {
      setOpenCSC(true);
      return;
    }
    GIO('track','clickData', {
      eventType_var: "click",
      module_var: GioModuleVar.topNav,
      objectName_var: "联系客服"
    })
    jumpNewCSC(); // jumpNewCSC();
  }

  const goToHome = () => {
    const ikeaHomeUrl =
      process.env.REACT_APP_HOST_TYPE === "test"
        ? "//www-qa.web.ikea.cn/cn/zh/"
        : "//www.ikea.cn/cn/zh/";
    window.location.href = ikeaHomeUrl;
  };

  return (platform === PLATFORM.SMP || platform === PLATFORM.STORE_COMPANION) ? null : (
    <div className={clsx(styles.NavBar, rootClass)}>
      {[PLATFORM.APP, PLATFORM.H5].includes(platform) && showBackIcon ? (
        <div className={styles.IconBtn} onClick={handleBack}>
          <img src={BackIcon} className={styles.Icon} alt="logo" />
        </div>
      ): <div className={styles.IconBtn}></div>}
      {[PLATFORM.IKEA].includes(platform) && (
        <div className={styles.LogoBtn} onClick={goToHome}>
          <img src={LogoIcon} alt="logo" />
        </div>
      )}
      <div
        className={clsx(styles.content, {
          flex: true,
          "justify-center": true,
          "items-center": true,
          [styles.title]: true,
          [styles.noClose]: noClose,
        })}
      >
        {/* {img && !isShowStepGuide && <img src={img} className={styles.StepIcon} alt="step"/>} */}
        {/* {isShowStepGuide && <div className={styles.StepGuide}>{getCurrentStep()}</div>} */}
        <div className={styles.StepGuide}>{getCurrentStep()}</div>
        {' '}
        {text || "打造您的PAX衣柜"}
      </div>
      
      <div className={styles.icons}>
        {isChatVisible && (
          <div
            className={styles.chatBtn}
            onClick={handleContactDesigner}
            ref={chatIconRef}
          >
            <img src={chat} className={styles.Icon} alt="logo" />
          </div>
        )}
        {isCloseIconVisible && (
          <div
            className={styles.IconBtn}
            onClick={handleClose}
          >
            <img src={CloseIcon} className={styles.Icon} alt="logo" />
          </div>
        )}
      </div>
      <CSCNoWorkTime
        infoType={InfoType.NON_WORKING}
        openCSC={openCSC}
        templateId={vTemplateId}
        hide={closeCSC}
        showSuccessAlert={() => {
          setShowSuccess(true);
        }}
        showErrorTelAlert={() => {
          setShowErrorTel(true);
        }}
      />
      <RegularModal
        open={showSuccess}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowSuccess(false);
        }}
        disableBackdropClick={true}
      >
        {"我们的线上专属客服会在近期联系您，请您耐心等候。"}
      </RegularModal>
      <RegularModal
        open={showErrorTel}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowErrorTel(false);
        }}
        disableBackdropClick={true}
      >
        {"您输入的号码格式错误，请输入正确的 11 位数电话号码。"}
      </RegularModal>
      <Drawer anchor={"bottom"} ModalProps={{
        keepMounted: true,
      }} open={open}>
        <QuitDesign
          title={"不想设计了？"}
          tip={"设计中遇到困难或者想要咨询问题，我们有线上专业设计师免费为你服务。"}
          templateId={vTemplateId}
          showSurvey={true}
          designCode={designCode}
          showExit={true}
          open={open}
          saveDesign={saveDesign}
          cscCustomerText={cscCustomerText}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Drawer>
    </div>
  );
}

export default NavBar;
