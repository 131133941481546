import React, { ReactNode, useMemo } from 'react'
import classNames from 'clsx'
import Mask from '../Mask'
import assign from 'lodash/assign'
import assignWith from 'lodash/assignWith'
import isUndefined from 'lodash/isUndefined'
import styles from './toast.module.scss'
import Loading from 'assets/images/loading.gif'
import { PLATFORM, STORAGE_KEYS } from '@/common/CommonType'

export interface ToastProps {
  /** Toast 完全关闭后的回调 */
  afterClose?: () => void
  /** Toast 遮罩样式 */
  maskStyle?: React.CSSProperties
  /** Toast 遮罩类名 */
  maskClassName?: string
  /** 是否允许背景点击 */
  maskClickable?: boolean
  /** toast 文本内容 */
  content?: ReactNode
  /** toast 图标 */
  icon?: 'success' | 'fail' | 'loading' | React.ReactNode
  /** 提示持续时间，若为 0 则不会自动关闭 */
  duration?: number
  /** 垂直方向显示位置，默认为 center */
  position?: 'top' | 'bottom' | 'center'
  /** 是否显示 */
  visible?: boolean
  /** 轻提示弹出时的的父容器 */
  getContainer?: HTMLDivElement | HTMLElement | null | undefined;
  /** 内容类名 **/
  contentClassName?: string
}

const defaultProps = {
  maskClickable: false,
}
export function mergeProps<P, D>(defaultProps: D, props: P): P & D {
  function customize(objValue: any, srcValue: any) {
    return isUndefined(srcValue) ? objValue : srcValue
  }
  return assignWith(assign({}, defaultProps), props, customize)
}

export const InternalToast: React.FC<ToastProps> = p => {
  const props = mergeProps(defaultProps, p)
  const { maskClickable, content, icon, position } = props
  const platform = (JSON.parse(localStorage.getItem(STORAGE_KEYS.PAX_GLOBAL_DATA) ?? "{}")).platform || PLATFORM.H5;
  console.log('platform', platform);
  // console.log('---platform === PLATFORM.SMP', platform === PLATFORM.SMP);
  const iconElement = useMemo(() => {
    if (icon === null || icon === undefined) return null
    switch (icon) {
      case 'success':
        return (<div>success</div>);
      case 'fail':
        return (<div>fail</div>);
      case 'loading':
        return (<img className={styles.loadingImage} src={Loading} alt="" />);
      default:
        return icon
    }
  }, [icon])

  const top = useMemo(() => {
    switch (position) {
      case 'top':
        return '42px'
      case 'bottom':
        return '80%'
      default:
        return '50%'
    }
  }, [position])


  return (
    <Mask
      visible={props.visible}
      destroyOnClose
      opacity={0}
      getContainer={props.getContainer}
      afterClose={props.afterClose}
      style={{
        pointerEvents: maskClickable ? 'none' : 'all',
        ...props.maskStyle,
        position: props.getContainer ? 'absolute' : 'fixed'
      }}
      className={classNames(styles.admToastMask, props.maskClassName, platform === PLATFORM.SMP ? styles.smpFix : null)}
    >
      <div
        style={{ top }}
        className={classNames(
          styles.admToastWrap,
          props.contentClassName,
          icon ? styles.admToastWrapIcon : styles.admToastWrapText,
          { [styles.loadingIcon]: icon === 'loading' }
        )}
      >
        {iconElement && (
          <div className={styles.admToastIcon}>{iconElement}</div>
        )}
        <span className={styles.text}>{content}</span>
      </div>
    </Mask>
  )
}