
import { PathObjectType } from 'ikea-jssdk';
class StoreCompanion {
  /**
   * @description 收藏商品路线图页面
   * @param prePageType prePageType
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  public static Collect (params: {prePageType?: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      sc: {
        path: '/pages/_collect/_collect?prePageType={{prePageType}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        prePageType: {
          type: 'optional',
          desc: 'prePageType'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description 扫码购流程的PIP页面，可加购
   * @param id the product's id
   * @param type type for the product
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  public static Pip (params: {id: string, type: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      sc: {
        path: '/pages/_pip/_pip?artNum={{id}}&artType={{type}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        id: {
          type: 'required',
          desc: 'the product\'s id'
        },
        type: {
          type: 'required',
          desc: 'type for the product'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description scan product qrcode/barcode or isell order
   * @param mode scan-isell || scan-go
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  public static Scan (params: {mode?: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      sc: {
        path: '/pages/scan/scan?mode={{mode}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        mode: {
          type: 'optional',
          desc: 'scan-isell || scan-go'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description 人工服务中间页
   * @param prePageType prePageType
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  public static Contact (params: {prePageType?: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      sc: {
        path: '/package-sub/pages/contact/contact?prePageType={{prePageType}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        prePageType: {
          type: 'optional',
          desc: 'prePageType'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description 购物清单页
   * @param prePageType prePageType
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  public static ShoppingList (params: {prePageType?: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      sc: {
        path: '/checkout/pages/shopping-list/shopping-list?prePageType={{prePageType}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        prePageType: {
          type: 'optional',
          desc: 'prePageType'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
}
export default StoreCompanion;
