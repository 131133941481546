import { ReactNode, useEffect } from "react";
import styles from "./CSCNoWorkTime.module.scss";
import classnames from 'clsx';
import Close from "../../assets/icons/close.svg";
import { Drawer } from "@material-ui/core";
import CircleTipIcon from '../../assets/icons/circle_tip_icon.svg';
import { InfoType, useUserInfo } from "@/hooks/userInfo";
import Tel from "../Tel/Tel";

interface IProps {
  openCSC: boolean;
  showTip?: boolean;
  tipContent?: ReactNode;
  content?: string;
  templateId?: number;
  uniqueCode?: string;
  infoType: InfoType;
  hide: (type?: boolean) => void;
  showSuccessAlert: () => void;
  showErrorTelAlert?: () => void;
}

const CSCNoWorkTime: React.FC<IProps> = (props) => {
  const { openCSC, showTip = true, tipContent, content, templateId, uniqueCode, infoType, hide, showSuccessAlert, showErrorTelAlert } = props;
  const {
    customerName,
    inputVal,
    showEdit,
    editTel,
    onInputChange,
    getUsrInfoData,
    setUsrInfo,
    showSuccess,
    setShowSuccess,
    showErrorTel, // 号码格式错误提示弹窗
    setShowErrorTel
  } = useUserInfo({ templateId, uniqueCode })
  useEffect(() => {
    // 号码发送成功后的弹窗
    if (showSuccess) {
      setShowSuccess(false)
      hide(true) // 参数为true时代表关闭（不想自己设计了吗？）的弹窗
      showSuccessAlert()
    }
    // 号码验证不符合要求的弹窗提示
    if (showErrorTel) {
      setShowErrorTel(false)
      showErrorTelAlert && showErrorTelAlert()
    }
  }, [setShowSuccess, hide, showSuccessAlert, showSuccess, showErrorTel, setShowErrorTel, showErrorTelAlert])

  return (
    <Drawer anchor={'bottom'} open={openCSC}>
      <div className={styles.stockWrap}>
        <div className={styles.title}>
          <img
            className={styles.close}
            src={Close}
            onClick={() => hide()}
            alt=''
          />
        </div>

        <div className={styles.contentWrap}>
          {showTip &&
            <div className={styles.tipWrap}>
            <div className={styles.tip}>
              <div className={styles.tipIcon}><img src={CircleTipIcon} alt="" /></div>
              <div className={styles.middle}>
                {tipContent &&
                  <div>{tipContent}</div>}
                {!tipContent &&
                  <div>
                  <p>抱歉，目前为非人工客服时间。</p>
                  <p>我们的客服时间为：<strong>9:00-22:00</strong></p>
                  </div>}
              </div>
            </div>
            </div>
          }
          <div className={styles.contact}>
            <div className={styles.contactTitle} >
              {content || "我们建议您留下联系方式，我们的工作人员将会尽快与您联系。"}
            </div>
            <Tel
              templateId={templateId}
              uniqueCode={uniqueCode}
              customerName={customerName}
              inputVal={inputVal}
              showEdit={showEdit}
              editTel={editTel}
              onInputChange={onInputChange}
              getUsrInfoData={getUsrInfoData}
            />
          </div>
        </div>
        <div className={styles.btnWrap}>
          <span className={classnames(styles.btn, { [styles.disabled]: inputVal.length !== 11 })} onClick={() => setUsrInfo(infoType)}>号码正确，请联系我</span>
        </div>
      </div >
    </Drawer>
  )
}

export default CSCNoWorkTime;

