import { useEffect } from "react";
import styles from "./Tel.module.scss";

interface IProps {
  templateId?: number;
  uniqueCode?: string;
  customerName: string;
  inputVal: string;
  showEdit: boolean;
  editTel: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getUsrInfoData: () => void;
}

const Tel: React.FC<IProps> = (props: IProps) => {
  const { customerName, inputVal, showEdit, editTel, onInputChange, getUsrInfoData } = props

  useEffect(() => {
    getUsrInfoData()
  }, [getUsrInfoData])

  return (
    <div className={styles.contactWrapper}>
      {!showEdit && <div className={styles.contactContent}>
        <div className={styles.custom}>{customerName}</div>
        <div className={styles.telWrap}>
          <span className={styles.name}>您的宜家会员电话号码：</span>
          <span className={styles.num}>{inputVal}</span>
          <span className={styles.change} onClick={() => editTel()}>修改</span>
        </div>
      </div>}
      {showEdit && <div className={styles.contactContentEdit}>
        <input
          placeholder={"请填写 11 位电话号码"}
          autoComplete={'off'}
          maxLength={11}
          type={"number"}
          value={inputVal}
          onChange={(e) => {
            onInputChange(e)
          }}
        />
      </div>}
    </div>
  )
}

export default Tel;

