import styles from "./RegularModal.module.scss";
import BaseModal from "./BaseModal";

interface Props {
  noCloseIcon?: boolean;
  titleIcon?: string;
  title?: string;
  open: boolean;
  children?: React.ReactElement | string;
  sureText?: string;
  cancelText?: string;
  disableBackdropClick?: boolean; //点击蒙层是否关闭
  sureFn?: () => void;
  closeFn?: () => void;
  cancelFn?: () => void;
}

function RegularModal({ noCloseIcon, titleIcon, title, open, children, sureText, cancelText, disableBackdropClick, closeFn, sureFn, cancelFn }: Props) {

  const closeHandler = (event?: { stopPropagation: () => void; }, reason?: string) => {
    event?.stopPropagation();
    if (reason === 'backdropClick' && disableBackdropClick) return
    closeFn && closeFn()
  }

  const sureHandler = (event?: { stopPropagation: () => void; }) => {
    event?.stopPropagation();
    if (sureFn) sureFn()
  }

  const cancelHandler = (event?: { stopPropagation: () => void; }) => {
    event?.stopPropagation();
    if (cancelFn) cancelFn()
  }

  return (
    <BaseModal open={open} onClose={closeHandler} noCloseIcon={noCloseIcon}>
      <div className={styles.Modal}>
        <div className={styles.ModalTitle}>
          {titleIcon && <img className={styles.titleIcon} src={titleIcon} alt="" />}
          {title && <span>{title}</span>}
        </div>
        <div className={styles.ModalContent}>
          {children}
        </div>
        <div className={styles.ModalBtnWrap}>
        {cancelText && <div className={styles.ModalExitBtn} onClick={(event) => cancelHandler(event)}>{cancelText}</div>}
        {sureText && <div className={styles.ModalConfirmBtn} onClick={(event) => sureHandler(event)}>{sureText}</div>}
        </div>
      </div>
    </BaseModal>)
}

export default RegularModal;
