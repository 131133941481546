import { useEffect } from 'react';
import steplistCss from './Privacy.module.scss';
import CloseIcon from '@/assets/icons/close.svg';
import Button from '@ingka/button';
import '@ingka/button/dist/style.css';

interface Props {
  materialMaskPdf: boolean;
  closeMaskPdf: () => void;
}

function Privacy(props: Props) {
  const { materialMaskPdf, closeMaskPdf } = props;

  useEffect(() => {}, []);

  return (
    <div
      className={steplistCss['mask-size']}
      style={{ display: materialMaskPdf ? '' : 'none' }}
      role='none'
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div
        className={steplistCss['mask-content']}
        role='none'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={steplistCss['mask-content-title']}>
          隐私政策
          <div
            className={steplistCss['mask-content-close']}
            role='none'
            onClick={closeMaskPdf}
          >
            <img src={CloseIcon} alt='' />
          </div>
        </div>

        <iframe
          title='隐私政策'
          style={{
            width: '100%',
            height: '100%',
            paddingBottom: '9rem',
            boxSizing: 'border-box',
          }}
          src='https://res.app.ikea.cn/page/privacy_policy.html'
        />
        <div className={steplistCss['btn-bottom']}>
          <Button fluid type='primary' text='我知道了' onClick={closeMaskPdf} />
        </div>
      </div>
    </div>
  );
}

export default Privacy;
