import { InfoType } from "@/hooks/userInfo";
import { post, get } from "./request";
import { getCity } from "@/utils/access-tool";

// TODO: templateId是否必传需要咨询Aaron,客服没有templateId
export interface IGetUserInfo {
  mobileNumber: string;
  templateId: number;
  infoType: InfoType;
}
// 获取用户信息
export const getUserInfo = async () => {
  return get("/user/info", {}, { isSilence: true });
};
// update用户信息
export const setUserInfo = async <T>(
  params: Partial<IGetUserInfo>
): Promise<T> => {
  return post("/user/info", params);
};

export const getQuestionnaire = async <T>(params: {
  featureName: string;
  pageName: string;
}): Promise<T> => {
  return get("/questionnaire/subject", params);
};

export const saveQuestionnaire = async <T>(params: {
  deviceId: string;
  questionnaireSubjectId: number;
  remark: string;
  selected: string[];
  zipcode: string;
}): Promise<T> => {
  return post("/questionnaire/answer", params);
};

// 获取商场信息
export const getAllStores = async () => {
  const cityInfo = (await getCity()) || { zipcode: null };
  console.log(!!cityInfo?.zipcode);
  return get(
    `/leads/allStores/${
      cityInfo?.zipcode ? `${cityInfo.zipcode}` : "Nozipcode"
    }`
  );
};

// 保存预约信息
export const saveLeads = async (params: {
  storeId: string;
  userName: string;
  mobileNumber: string;
  pageEnumId: number;
  sourceStoreId?: string;
}) => {
  return post("/leads/save", params);
};
