import { useEffect, useRef, useState } from "react";
import { getGioPlatform } from "@/utils/ikea-sdk";
import { STORAGE_KEYS } from "@/common/CommonType";
import { IRecommend } from "@/common/WardrobeTypes";
import { getPageInfo } from "@/utils/common-tool";
import aidTrack from "@/utils/aid-track";
import AidTrack from "@/utils/aid-track";
import { trimWardrobe } from "@/utils/aid-track-tool";

/**
 * gio曝光埋点hook
 */
export function useImpression(props: {
  root?: HTMLElement;
  rootMargin?: string;
  threshold?: number;
  once?: boolean;
  el: HTMLElement | null;
  gioData: unknown;
}) {
  const {
    root = null,
    rootMargin = "",
    threshold = 0,
    el,
    once = true,
    gioData = {},
  } = props;
  const execRef = useRef(false);
  const insRef = useRef(
    new IntersectionObserver(
      async (entries) => {
        const platform = await getGioPlatform();
        if (entries[0] && entries[0].isIntersecting) {
          if (once && execRef.current) return;
          const data = Object.assign(
            {},
            {
              eventType_var: "impression",
              platform_var: platform,
            },
            gioData
          );
          window.gio("track", "impressionData", data);

          if (!execRef.current) {
            execRef.current = true;
          }
        }
      },
      { root, rootMargin, threshold }
    ) as any
  );

  useEffect(() => {
    if (el) {
      insRef.current?.observe(el);
    }

    return () => {
      if (el) {
        insRef.current?.unobserve(el);
        insRef.current = null;
      }
    };
  }, [el]);

  return insRef.current;
}

/**
 * gio时长停留hook
 */
export function useDuration(props: { gioData: unknown }) {
  const { gioData } = props;
  const [start] = useState(new Date());
  useEffect(() => {
    let platform = "";

    async function init() {
      platform = await getGioPlatform();
    }

    init();

    return () => {
      const end = new Date();
      const duration = Math.floor((+end - +start) / 1000);
      const data = Object.assign(
        {},
        {
          eventType_var: "page_duration",
          platform_var: platform,
          period_var: duration,
        },
        gioData
      );
      window.gio("track", "pageView", data);
    };
  }, []);
}

const getLocationInfo = (location?: string) => {
  if (!location) {
    return {
      pageName: "-",
      pageType: "-",
    };
  }
  const routeList = [
    {
      info: {
        pageName: "2/3选择内配方案",
        pageType: "pax_requirement",
      },
      page: "/interior_design",
    },
    {
      info: {
        pageName: "2/3选择内配方案",
        pageType: "pax_recommend",
      },
      page: "/wardrobe_list",
    },
    {
      info: {
        pageName: "2/3选择内配方案",
        pageType: "pax_template_overview",
      },
      page: "/template_overview",
    },
    {
      info: {
        pageName: "3/3衣柜外观样式",
        pageType: "pax_adjustment",
      },
      page: "/selectExteriorStyle",
    },
    {
      info: {
        pageName: "衣柜详情页",
        pageType: "pax_pip",
      },
      page: "/detail",
    },
    {
      info: {
        pageName: "衣柜详情页",
        pageType: "pax_pip",
      },
      page: "/share",
    },
    {
      info: {
        pageName: "衣柜详情页",
        pageType: "pax_pip",
      },
      page: "/scan",
    },
    {
      info: {
        pageName: "2/3替换内配布局",
        pageType: "pax_editor",
      },
      page: "/editor",
    },
    {
      info: {
        pageName: "你的pax衣柜",
        pageType: "pax_poster",
      },
      page: "/poster",
    },
    {
      info: {
        pageName: "寻找外观灵感",
        pageType: "pax_inspiration",
      },
      page: "/inspire",
    },
  ];
  if (location.indexOf("/editor/design_model") > -1) {
    return {
      pageName: "配件调整",
      pageType: "pax_editor_design",
    };
  }
  for (let i = 0; i < routeList.length; i++) {
    const item = routeList[i];
    if (location.indexOf(item.page) > -1) {
      return item.info;
    }
  }
  return {
    pageName: "1/3衣柜基础信息",
    pageType: "pax_requirement",
  };
};

export async function pageViewGio(
  previousLocation?: string,
  trackId?: string,
  code?: string,
  params = {},
) {
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUP_ID);
  const nowLocation = window.location.href;
  const nowLocationInfo = getPageInfo(nowLocation);
  const previousLocationInfo = getPageInfo(previousLocation);
  const platform = await getGioPlatform();
  const p = Object.assign({}, {
    eventType_var: "page_view",
    platform_var: platform,
    pageType_var: nowLocationInfo.pageType,
    pageName_var: nowLocationInfo.pageName,
    pageId_var: nowLocation,
    objectId_var: code,
    prePageType_var: previousLocationInfo.pageType,
    prePageName_var: previousLocationInfo.pageName,
    prePageId_var: window.location.origin + (previousLocation ?? ''),
    groupId_var: groupId,
    requestId_var: trackId ? trackId : "-",
  }, params)
  window.gio("track", "pageView", p);
}

export async function chooseTemplateGio(
  item?: IRecommend,
  position?: number,
  objectType?: string
) {
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUP_ID);
  const nowLocation = window.location.href;
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();

  const wardrobe = item?.wardrobeWithProduct
  const isHot = item?.templateId === "top_sales_template";
  let objectId = item ? item.templateId : "-";
  if (isHot) {
    objectId = wardrobe?.id || "-";
  }

  const price = item?.templatePriceList?.[0]?.price || 0;
  const color = wardrobe?.color || "-";
  const combination = wardrobe?.combination.replaceAll(",", "_").replaceAll(";", "_") || "-";
  const size = wardrobe?.totalLength + "_" + wardrobe?.height + "_" + wardrobe?.depth

  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageName_var: pageInfo.pageName,
    pageId_var: nowLocation,
    groupId_var: groupId,
    module_var: "template_choose",
    objectId_var: objectId,
    objectName_var: "模板",
    objectType_var: objectType,
    objectPosition_var: position,
    requestId_var: item && item.trackId ? item.trackId : "-",
    price_var: price,
    payAmountFloat_var: price,
    payAmount_var: price + "",
    attributeName1_var: "是否热卖",
    attributeValue1_var: isHot ? "yes" : "no",
    elementSize_var: combination,
    size_var: size,
    color_var: color,
  });
  if (wardrobe) {
    AidTrack.send({
      eventType: "click",
      eventName: "点击推荐",
      pageName: pageInfo.pageName,
      extra: {
        price: price,
        size: combination,
        color: color,
        template: trimWardrobe(wardrobe)
      }
    })
  }
}
