import { IPageTypeParams } from "@/components/BuserBottomBar/BuserBottomBar";
import { getGioPlatform } from "./ikea-sdk";
import { getPageInfo } from "./common-tool";

function getStepGuide(showOrHide?: boolean) {
  let stepGuideShow = "-";
  if (showOrHide !== undefined && showOrHide === true) {
    stepGuideShow = "show_fold";
  }
  if (showOrHide !== undefined && showOrHide === false) {
    stepGuideShow = "show_unfold";
  }
  return stepGuideShow;
}

export async function next_step_size_filter_gio(showOrHide?: boolean) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: "pax_requirement",
    pageId_var: window.location.href,
    pageName_var: "1/3衣柜基础信息",
    module_var: "footer_tab",
    objectName_var: "下一步",
    attributeName1_var: "stepGuide_var",
    attributeValue1_var: getStepGuide(showOrHide),
  });
}

export async function next_step_interior_design_gio(showOrHide?: boolean) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: "pax_requirement",
    pageId_var: window.location.href,
    pageName_var: "2/3选择内配方案",
    module_var: "footer_tab",
    objectName_var: "下一步",
    attributeName1_var: "stepGuide_var",
    attributeValue1_var: getStepGuide(showOrHide),
  });
}

export async function next_step_template_overview_gio(showOrHide?: boolean) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: "pax_template_overview",
    pageId_var: window.location.href,
    pageName_var: "2/3选择内配方案",
    module_var: "footer_tab",
    objectName_var: "下一步",
    attributeName1_var: "stepGuide_var",
    attributeValue1_var: getStepGuide(showOrHide),
  });
}

// 展开引导步骤
export async function show_guide_step_gio(nowPageParams: IPageTypeParams) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: nowPageParams.pageType_var,
    pageId_var: window.location.href,
    pageName_var: nowPageParams.pageName_var,
    module_var: "footer_tab",
    objectName_var: "展开引导步骤",
  });
}

// 收起引导步骤
export async function hide_guide_step_gio(nowPageParams: IPageTypeParams) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: nowPageParams.pageType_var,
    pageId_var: window.location.href,
    pageName_var: nowPageParams.pageName_var,
    module_var: "footer_tab",
    objectName_var: "收起引导步骤",
  });
}

// 配件调整
export async function fitting_adjustment_gio(showOrHide?: boolean) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: "pax_template_overview",
    pageId_var: window.location.href,
    pageName_var: "2/3选择内配方案",
    module_var: "footer_tab",
    objectName_var: "配件调整",
    attributeName1_var: "stepGuide_var",
    attributeValue1_var: getStepGuide(showOrHide),
  });
}

// // 点击2.5D渲染去配件调整页面 TODO：lzy
// export async function fitting_render_adjustment_gio() {
//     const platform = await getGioPlatform();
//     window.gio('track', 'clickData', {
//         'eventType_var': 'click',
//         'platform_var': platform,
//         'pageType_var': 'pax_template_overview',
//         'pageId_var': window.location.href,
//         'pageName_var': "2/3选择内配方案",
//         // 'module_var': 'footer_tab',
//         'objectName_var': "点击2.5D render区域",
//     })
// }
// 外观灵感
export async function appearance_inspiration_gio(showOrHide?: boolean) {
  const platform = await getGioPlatform();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: "pax_adjustment",
    pageId_var: window.location.href,
    pageName_var: "3/3衣柜外观样式",
    module_var: "footer_tab",
    objectName_var: "外观灵感",
    attributeName1_var: "stepGuide_var",
    attributeValue1_var: getStepGuide(showOrHide),
  });
}

// 点击“完成调整”时触发（有两个地方）
export async function complete_adjust_gio(opts: any) {
  const {
    previousPath = "",
    templateId,
    isChanged,
    isNewPaxFlow = false,
  } = opts;
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  const previousPageInfo = getPageInfo(previousPath);
  const basicParams = {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    prePageType_var: previousPageInfo.pageType,
    prePageId_var: window.location.origin + previousPath,
    prePageName_var: previousPageInfo.pageName,
    module_var: "footer_tab",
    objectId_var: templateId,
    objectName_var: pageInfo.pageType === "pax_editor" ? "完成调整" : "完成",
    attributeName1_var: "是否调整",
    attributeValue1_var: isChanged ? "yes" : "no",
  };
  let finalParams = basicParams;
  if (isNewPaxFlow) {
    finalParams = Object.assign({}, basicParams, {
      pageName_var: "2/3 替换内配布局",
      objectName_var: "下一步",
    });
  }
  window.gio("track", "clickData", finalParams);
}

// 点击2/3选择内配方案-储物需求页面“下一步”时触发
export async function interior_storage_next_gio(selectedOptions: string) {
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    module_var: "footer_tab",
    objectName_var: "下一步",
    attributeName1_var: "内配需求组合",
    attributeValue1_var: selectedOptions,
  });
}

// 点击“自己定制”按钮及图片触发
export async function customize_gio() {
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  window.gio("track", "clickData", {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    module_var: "template_choose",
    objectName_var: "自己定制",
  });
}

// 内配方案TAB按钮滑动时触发(左右滑动的大TAB）
export async function interior_scheme_tab_gio(objectName: string) {
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  window.gio("track", "impressionData", {
    eventType_var: "impression",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    module_var: "recommend_option",
    objectName_var: objectName,
  });
}

// leads页面点击“立即预约”按钮触发
export async function customize_leads_gio(
  previousPath = "",
  type = "clickData",
  params = {},
) {
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  const previousPageInfo = getPageInfo(previousPath);
  const p = Object.assign({}, {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    prePageType_var: previousPageInfo.pageType,
    prePageId_var: window.location.origin + previousPath,
    prePageName_var: previousPageInfo.pageName,
    module_var: "footer_tab",
    objectName_var: '立即预约',
  }, params);

  window.gio("track", type, p);
}

export async function customize_quotation_gio(
  previousPath = "",
  type = "clickData",
  params = {},
) {
  const platform = await getGioPlatform();
  const pageInfo = getPageInfo();
  const previousPageInfo = getPageInfo(previousPath);
  const p = Object.assign({}, {
    eventType_var: "click",
    platform_var: platform,
    pageType_var: pageInfo.pageType,
    pageId_var: window.location.href,
    pageName_var: pageInfo.pageName,
    prePageType_var: previousPageInfo.pageType,
    prePageId_var: window.location.origin + previousPath,
    prePageName_var: previousPageInfo.pageName,
    module_var: "footer_tab",
    objectName_var: '免费获取报价',
  }, params);

  window.gio("track", type, p);
}

// export enum GioType {
//     track = 'track',
//     visitorSet = 'visitor.set',
// }

type GioType = "track" | "visitor.set";

// export enum GioEvent {
//     clickData = "clickData",
//     pageView = "pageView",
//     impressionData = "impressionData",
//     contactCSC = "contactCSC",
//     addToBag = "addToBag",
//     scanData = "scanData",
// }

type GioEvent =
  | "clickData"
  | "pageView"
  | "impressionData"
  | "contactCSC"
  | "addToBag"
  | "scanData"
  | "enterPAX";

type GioEventTypeVar =
  | "click"
  | "page_view"
  | "page_duration"
  | "impression"
  | "add_to_cart_confirm"
  | "add_to_cart_start"
  | 'scan_success'

// type GioPageTypeVar = "pax_recommend" | "-"

/**
 * pax_design
 *
 * pax_product
 *
 * pax_requirement
 *
 * pax_template_overview
 *
 * pax_adjustment
 *
 * pax_recommend
 *
 * wardrobe_adjustment
 *
 * pax_pip
 *
 * pax_poster
 */
enum GioPageTypeVar {
  recommend = "pax_recommend",
  overview = "pax_template_overview",
  pip = "pax_pip",
  poster = "pax_poster",
  designList = "pax_design_list",
  scan = "pax_design_scan",
  index = "pax_requirement",
  selectExteriorStyle = "pax_adjustment",
  interiorDesign = "pax_requirement",
  editor = "pax_editor",
  inspire = "pax_inspiration",
  designModel = "pax_editor_design",
  dtcSelectExteriorStyle = "pax_design",
  empty = "-",
  custom_landingpage = "pax_custom_landingpage",
  wrd_landingpage = "pax_wrd_landingpage",
  leads = "pax_custom_leads",
  reserved = 'pax_custom_reserved',
  quotation = 'pax_custom_offer',
}

export const enum GioPageNameVar {
  recommend = "2/3选择内配方案",
  overview = "2/3选择内配方案",
  pip = "衣柜详情",
  poster = "你的PAX衣柜",
  designList = "衣柜详情",
  scan = "衣柜详情",
  index = "1/3衣柜基础信息",
  selectExteriorStyle = "3/3衣柜外观样式",
  interiorDesign = "2/3选择内配方案",
  editor = "配件调整",
  inspire = "寻找PAX外观灵感",
  dtcSelectExteriorStyle = "PAX衣柜外观个性设计",
  designModel = "配件调整",
  empty = "-",
  editorV2 = "2/3替换内配布局",
  custom_landingpage = "定制衣柜_落地页",
  wrd_landingpage = "全屋定制_落地页",
  leads = "定制衣柜_留资",
  reserved = '定制衣柜_预约成功',
  quotation = '定制衣柜_报价',
}

export const enum GioModuleVar {
  recommendOption = "recommend_option",
  material = "material_detail",
  overview = "wardrobe_show",
  footerTab = "footer_tab",
  detailConfirmPopup = "detail_conform_popup",
  storageRecommendPopup = "storage_recommend_popup",
  shapeChoose = "shape_choose",
  paxIntroduce = "pax_introduce",
  productDetail = "product_detail",
  filter = "filter",
  cabinetColor = "cabinet_color",
  handleStyle = "handle_style",
  doorStyle = "door_style",
  slidingColor = "sliding_color",
  wallFloorColorPopup = "wall_floor_color_popup",
  wardrobeShow = "wardrobe_show",
  interiorFeatures = "interior_features",
  elementList = "element_list",
  questionnairePopup = "questionnaire_popup",
  pipAssembly = "pip_assembly",
  pipService = "pip_service",
  templateChoose = "template_choose",
  doortypeChoose = "doortype_choose",
  QA = "QA",
  topNav = "top_nav",
  customizeGuide = "customize_guide",
  customCase = "custom_case",
  redesignPopup = "redesign_popup",
  wardrobeComplete = "wardrobe_complete",
}

interface GioParams {
  eventType_var: GioEventTypeVar;
  platform_var: string;
  pageType_var: GioPageTypeVar;
  pageId_var: string;
  pageName_var: GioPageNameVar;
  module_var: GioModuleVar;
  objectName_var: string;
  objectType_var: string;
  objectId_var: string;
  prePageType_var: string;
  prePageId_var: string;
  prePageName_var: string;
  objectPosition_var: string;
  attributeName1_var: string;
  attributeValue1_var: string;
  attributeName2_var: string;
  attributeValue2_var: string;
  period_var: string | number;
  payAmountFloat_var: number;
  payAmount_var: string;
  size_var: string;
  templateId_var: string | number;
  pageSource_var: string;
  designCode_var: string;
  shape_var: string;
  doorShape_var: string;
  elementColor_var: string;
  elementSize_var: string;
  groupId_var: string;
  modulePosition_var: string;
}

const getPageType = (
  currentPath = ""
): { pageTypeVar: GioPageTypeVar; pageNameVar: GioPageNameVar } => {
  if (!currentPath) {
    currentPath = window.location.pathname;
  }
  const routeList = [
    {
      pageTypeVar: GioPageTypeVar.reserved,
      pageNameVar: GioPageNameVar.reserved,
      url: "/reserved",
    },
    {
      pageTypeVar: GioPageTypeVar.custom_landingpage,
      pageNameVar: GioPageNameVar.custom_landingpage,
      url: "/custom_landingpage",
    },
    {
      pageTypeVar: GioPageTypeVar.wrd_landingpage,
      pageNameVar: GioPageNameVar.wrd_landingpage,
      url: "/wrd_landingpage",
    },
    {
      pageTypeVar: GioPageTypeVar.leads,
      pageNameVar: GioPageNameVar.leads,
      url: "/leads",
    },
    {
      pageTypeVar: GioPageTypeVar.interiorDesign,
      pageNameVar: GioPageNameVar.interiorDesign,
      url: "/interior_design",
    },
    {
      pageTypeVar: GioPageTypeVar.recommend,
      pageNameVar: GioPageNameVar.recommend,
      url: "/wardrobe_list",
    },
    {
      pageTypeVar: GioPageTypeVar.overview,
      pageNameVar: GioPageNameVar.overview,
      url: "/template_overview",
    },
    {
      pageTypeVar: GioPageTypeVar.selectExteriorStyle,
      pageNameVar: GioPageNameVar.selectExteriorStyle,
      url: "/selectExteriorStyle",
    },
    {
      pageTypeVar: GioPageTypeVar.pip,
      pageNameVar: GioPageNameVar.pip,
      url: "/detail",
    },
    {
      pageTypeVar: GioPageTypeVar.pip,
      pageNameVar: GioPageNameVar.pip,
      url: "/share",
    },
    {
      pageTypeVar: GioPageTypeVar.scan,
      pageNameVar: GioPageNameVar.scan,
      url: "/scan",
    },
    {
      pageTypeVar: GioPageTypeVar.empty,
      pageNameVar: GioPageNameVar.empty,
      url: "/productList",
    },
    {
      pageTypeVar: GioPageTypeVar.designList,
      pageNameVar: GioPageNameVar.designList,
      url: "/designList",
    },
    {
      pageTypeVar: GioPageTypeVar.poster,
      pageNameVar: GioPageNameVar.poster,
      url: "/poster",
    },
    {
      pageTypeVar: GioPageTypeVar.designModel,
      pageNameVar: GioPageNameVar.editorV2,
      url: "/design_model",
    },
    {
      pageTypeVar: GioPageTypeVar.editor,
      pageNameVar: GioPageNameVar.editorV2,
      url: "/editor",
    },
    {
      pageTypeVar: GioPageTypeVar.dtcSelectExteriorStyle,
      pageNameVar: GioPageNameVar.dtcSelectExteriorStyle,
      url: "/dtcSelectExteriorStyle",
    },
    {
      pageTypeVar: GioPageTypeVar.inspire,
      pageNameVar: GioPageNameVar.inspire,
      url: "/inspire",
    },
    {
      pageTypeVar: GioPageTypeVar.custom_landingpage,
      pageNameVar: GioPageNameVar.custom_landingpage,
      url: "/custom_landingpage",
    },
    {
      pageTypeVar: GioPageTypeVar.wrd_landingpage,
      pageNameVar: GioPageNameVar.wrd_landingpage,
      url: "/wrd_landingpage",
    },
    {
      pageTypeVar: GioPageTypeVar.quotation,
      pageNameVar: GioPageNameVar.quotation,
      url: "/quotation",
    },
    {
      pageTypeVar: GioPageTypeVar.index,
      pageNameVar: GioPageNameVar.index,
      url: "/",
    }
  ];
  for (let i = 0; i < routeList.length; i++) {
    const item = routeList[i];
    if (currentPath.indexOf(item.url) > -1) {
      return {
        pageTypeVar: item.pageTypeVar,
        pageNameVar: item.pageNameVar,
      };
    }
  }
  return {
    pageTypeVar: GioPageTypeVar.empty,
    pageNameVar: GioPageNameVar.empty,
  };
};
let platform = "";
export const GIO = async (
  t: GioType,
  e: GioEvent,
  params: Partial<GioParams>,
  previousPath = ""
) => {
  try {
    if (platform === "") {
      platform = await getGioPlatform();
    }
    const pageType = getPageType();
    const defaultParams: Partial<GioParams> = {
      platform_var: platform,
      pageId_var: window.location.href,
      pageType_var: pageType.pageTypeVar,
      pageName_var: pageType.pageNameVar,
    };
    if (previousPath) {
      const previousPageInfo = getPageInfo(previousPath);
      Object.assign(defaultParams, {
        prePageType_var: previousPageInfo.pageType,
        prePageId_var: window.location.origin + previousPath,
        prePageName_var: previousPageInfo.pageName,
      })
    }
    window.gio(t, e, Object.assign({}, defaultParams, params));
  } catch (e) {
    console.log("gio error", e);
  }
};

export const GIOImpression = (
  params: Partial<GioParams>,
  dom: HTMLElement,
  once = true
) => {
  let isExecuteOnce = false;
  const intersectionObserver = new IntersectionObserver((entries) => {
    if (once && isExecuteOnce) return;
    if (entries[0] && entries[0].intersectionRatio <= 0) return;
    GIO(
      "track",
      "impressionData",
      Object.assign(
        {
          eventType_var: "impression",
        },
        params
      )
    );
    isExecuteOnce = true;
  });
  intersectionObserver.observe(dom);
};

const KEY_GIO_TIME = "gio_load_time";
const KEY_GIO_PATH = "gio_load_pathname";

export const GIO_LOAD_PAGE = () => {
  sessionStorage.setItem(KEY_GIO_TIME, new Date().getTime().toString());
  sessionStorage.setItem(KEY_GIO_PATH, window.location.pathname);
};

export const GIO_Duration = () => {
  const loadTime = sessionStorage.getItem(KEY_GIO_TIME);
  const durationPath = sessionStorage.getItem(KEY_GIO_PATH);
  if (!loadTime || !durationPath) {
    return;
  }

  const pageType = getPageType(durationPath);
  // get load time as int
  const duration = new Date().getTime() - parseInt(loadTime);
  sessionStorage.removeItem(KEY_GIO_TIME);
  sessionStorage.removeItem(KEY_GIO_PATH);
  GIO("track", "pageView", {
    eventType_var: "page_duration",
    pageType_var: pageType.pageTypeVar,
    pageName_var: pageType.pageNameVar,
    pageId_var: `${window.location.origin}${durationPath}`,
    period_var: Math.ceil(duration / 1000),
  });
};
