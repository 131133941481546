import styles from "./Common.module.scss";
import { FrameDepth } from "../../common/CommonType";

interface IProps {
  depth: number;
  setDepth: (depth: number) => void;
}
function SelectDepth(props: IProps) {
  const { depth, setDepth } = props

  function handleClick(d: FrameDepth) {
    setDepth(d)
  }

  return (
    <div className={styles.Container}>
      <div className={styles.label}>
        <span>深度</span>
        <span className={styles.LabelInfo}>（不包含门板厚度）</span>
      </div>
      <div className={styles.optionWrap}>
        <div className={`${styles.Option} ${depth === 35 ? styles.optionActive : ""}`}
             onClick={() => handleClick(35)}>35 cm
        </div>
        <div className={`${styles.Option} ${depth === 58 ? styles.optionActive : ""}`}
             onClick={() => handleClick(58)}>58 cm
        </div>
      </div>
    </div>)
}

export default SelectDepth;



