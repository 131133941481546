import styles from "./InteriorFilter.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { BASIC_NEED_LIST, UNIQUE_NEED_LIST } from "./optionList";
import classnames from "clsx";
import { debounce, find, findIndex, sortBy } from "lodash";
import {
  SearchConfActionType,
  useConfig,
} from "../../reducers/searchConfReducer";
import Toast from "components/Toast";
import { AIDialog, AIPosition } from "@/components/AITip/AITip";

const MAX_NEED = 8;

function InteriorFilter() {
  const { state, dispatch } = useConfig();
  const bodyRef = useRef<HTMLDivElement>(null);
  // const [showMoreBtn, setShowMoreBtn] = useState(true);

  const [showAIDialog, setShowAIDialog] = useState(false);

  useEffect(() => {
    if (bodyRef.current) {
    }
  }, [bodyRef]);

  const handleScroll = debounce(() => {
    if (bodyRef.current !== null) {
      console.log(bodyRef.current.scrollTop);
      // if (bodyRef.current.scrollTop > 20) {
      //   setShowMoreBtn(false);
      // } else {
      //   setShowMoreBtn(true);
      // }
    }
  }, 200);
  // function slideScreen() {
  //   if (bodyRef.current !== null) {
  //     const boundingReact = bodyRef.current.getBoundingClientRect();
  //     bodyRef.current.scrollTo({
  //       top: bodyRef.current.scrollTop + boundingReact.height,
  //       behavior: "smooth",
  //     });
  //   }
  // }
  const checkNeed = (order: number): boolean => {
    return !!find(state.needList, { order });
  };

  const checkNeedImportant = (order: number): boolean => {
    const cur = find(state.needList, { order });
    return cur !== undefined && !!cur.important;
  };

  // const checkDislike = (id: string): boolean => {
  //   return state.dislikeList.indexOf(id) >= 0;
  // };

  // function handleClickDislike(id: string) {
  //   const index = state.dislikeList.indexOf(id);
  //   const newArr = [...state.dislikeList];
  //   if (index >= 0) {
  //     newArr.splice(index, 1);
  //   } else {
  //     if (state.dislikeList.length >= 3) {
  //       Toast.show({
  //         content: "最多只能选择3个哦",
  //         maskClickable: true,
  //       });
  //       return false;
  //     }
  //     newArr.push(id);
  //   }
  //   dispatch({
  //     type: SearchConfActionType.UpdateConfig,
  //     payload: { dislikeList: newArr },
  //   });
  // }

  function handleClickNeed(order: number, id: number) {
    const newArr = sortBy([...state.needList], (n) => {
      return n.order;
    });
    const index = findIndex(newArr, { order });
    if (index >= 0) {
      const cur = newArr.splice(index, 1);
      if (cur[0].important && newArr.length > 0) {
        newArr[0].important = true;
      }
    } else {
      if (state.needList.length >= MAX_NEED) {
        Toast.show({
          content: "最多只能选择8个哦",
          maskClickable: true,
        });
        return false;
      }
      newArr.push({
        order,
        id,
        important: newArr.length === 0,
      });
    }
    dispatch({
      type: SearchConfActionType.UpdateConfig,
      payload: { needList: newArr },
    });
  }

  function handleClickImportant(e: React.MouseEvent, order: number) {
    e.stopPropagation();
    const newArr = [...state.needList];
    const current = find(newArr, { order });
    if (current === undefined) {
      return;
    }
    if (!current.important) {
      for (const o of newArr) {
        o.important = false;
      }
      current.important = true;
      Toast.show({
        content: "你已变更最多需要(仅可设置一个)",
        maskClickable: true,
      });
    } else {
      // current.important = false;
      return;
    }
    dispatch({
      type: SearchConfActionType.UpdateConfig,
      payload: { needList: newArr },
    });
  }
  useEffect(() => {
    if (!localStorage.getItem(AIPosition.InteriorFilter)) {
      setShowAIDialog(true);
    }
    localStorage.setItem(AIPosition.InteriorFilter, "true");
  }, []);

  const selectedNeedList = [...BASIC_NEED_LIST, ...UNIQUE_NEED_LIST].filter(need => checkNeed(need.order));

  const selectedNeedCol1: any[] = [];
  const selectedNeedCol2: any[] = [];
  const selectedNeedCol3: any[] = [];
  selectedNeedList.forEach((item, index) => {
    const flag = index % 3;
    switch(flag) {
      case 0:
        selectedNeedCol1.push(item);
        break;
      case 1:
        selectedNeedCol2.push(item);
        break;
      case 2:
        selectedNeedCol3.push(item);
        break;
    }
  });

  return (
    <div className={styles.BodyScroll} onScroll={handleScroll} ref={bodyRef}>
      <div className={styles.SubTitle}>
        <span className={styles.bodyTitle}>1.基本衣柜储物</span>
        <span className={styles.desc}>（98%用户选择）</span>
        {/* <img
          className={styles.questionMarkIcon}
          src={showAIDialog ? QuestionMarkIcon : QuestionMarkIcon2}
          onClick={() => setShowAIDialog(true)}
          alt=""
        /> */}
      </div>
      <ul className={styles.List}>
        {BASIC_NEED_LIST.map((need, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                handleClickNeed(need.order, need.id);
              }}
              className={classnames(styles.Option, {
                [styles.Active]: checkNeed(need.order),
              })}
            >
              <div className={styles.Icon}>
                <img src={need.icon.default} alt={"icon"} />
                {/* <div
                  className={classnames(styles.CheckStar, {
                    [styles.Active]: checkNeedImportant(need.order),
                  })}
                  onClick={(e) => {
                    handleClickImportant(e, need.order);
                  }}
                >
                  设为最多
                </div> */}
                {/* <div className={styles.CheckMark}/> */}
              </div>
              <div className={styles.OptionName}>{need.name}</div>
            </li>
          );
        })}
      </ul>
      <div className={styles.SubTitle}>
        <span className={styles.bodyTitle}>2.特色衣柜储物</span>
      </div>
      <ul className={styles.List}>
        {UNIQUE_NEED_LIST.map((need, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                handleClickNeed(need.order, need.id);
              }}
              className={classnames(styles.Option, {
                [styles.Active]: checkNeed(need.order),
              })}
            >
              <div className={styles.Icon}>
                <img src={need.icon.default} alt={"icon"} />
                {/* <div
                  className={classnames(styles.CheckStar, {
                    [styles.Active]: checkNeedImportant(need.order),
                  })}
                  onClick={(e) => {
                    handleClickImportant(e, need.order);
                  }}
                >
                  设为最多
                </div> */}
                {/* <div className={styles.CheckMark}/> */}
              </div>
              <div className={styles.OptionName}>{need.name}</div>
            </li>
          );
        })}
        <li className={classnames(styles.OptionEmpaty)}></li>
      </ul>
      <div style={{ display: state.needList.length > 0 ? 'block' : 'none' }}>
        <div className={styles.SubTitle}>
          <span className={styles.bodyTitle}>选中的储物里哪一个需要最多衣柜空间？</span>
          <span className={styles.desc}>（单选）</span>
        </div>
        <div className={styles.List} style={{ justifyContent: selectedNeedList.length > 2 ? 'space-between' : 'flex-start' }}>
          <ul>
            {selectedNeedCol1.map((need, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    handleClickImportant(e, need.order);
                  }}
                  className={classnames(styles.SelectedOption, {
                    [styles.Active]: checkNeedImportant(need.order),
                  })}
                >
                  { need.name }
                </li>
              );
            })}
          </ul>
          <ul className={classnames({ [styles.hackFlexStart]: selectedNeedList.length === 2 })}>
            {selectedNeedCol2.map((need, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    handleClickImportant(e, need.order);
                  }}
                  className={classnames(styles.SelectedOption, {
                    [styles.Active]: checkNeedImportant(need.order),
                  })}
                >
                  { need.name }
                </li>
              );
            })}
          </ul>
          <ul>
            {selectedNeedCol3.map((need, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    handleClickImportant(e, need.order);
                  }}
                  className={classnames(styles.SelectedOption, {
                    [styles.Active]: checkNeedImportant(need.order),
                  })}
                >
                  { need.name }
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <AIDialog
        open={showAIDialog}
        onClose={() => setShowAIDialog(false)}
      ></AIDialog>
    </div>
  );
}

export default InteriorFilter;
