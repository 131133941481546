export interface IOption {
  order: number,
  id: number,
  name: string,
  icon: string,
  check: boolean,
  important: boolean
} 
export const NEED_LIST = [
  {
    order: 0,
    id: 5,
    name: "挂置短衣",
    icon: require("../../assets/images/interior/short.png"),
    check: true,
    important: true
  },
  {
    order: 1,
    id: 10,
    name: "挂置长衣",
    icon: require("../../assets/images/interior/long.png"),
    check: true,
    important: false
  },
  {
    order: 2,
    id: 4,
    name: "叠放衣物",
    icon: require("../../assets/images/interior/fold.png"),
    check: false,
    important: false
  },
  {
    order: 3,
    id: 1,
    name: "放置内衣物",
    icon: require("../../assets/images/interior/underwear.png"),
    check: false,
    important: false
  },
  {
    order: 4,
    id: 13,
    name: "收纳首饰配件",
    icon: require("../../assets/images/interior/jewelry.png"),
    check: false,
    important: false
  },
  {
    order: 5,
    id: 8,
    name: "挂置裤裙",
    icon: require("../../assets/images/interior/pants.png"),
    check: false,
    important: false
  },
  {
    order: 6,
    id: 11,
    name: "放置鞋子",
    icon: require("../../assets/images/interior/shoe.png"),
    check: false,
    important: false
  },
  {
    order: 7,
    id: 6,
    name: "收纳过季衣物",
    icon: require("../../assets/images/interior/season.png"),
    check: false,
    important: false
  },
  {
    order: 8,
    id: 6,
    name: "收纳被褥",
    icon: require("../../assets/images/interior/bedding.png"),
    check: false,
    important: false
  },
  {
    order: 9,
    id: 14,
    name: "放置行李箱",
    icon: require("../../assets/images/interior/luggage.png"),
    check: false,
    important: false
  },
  {
    order: 10,
    id: 2,
    name: "放置包包",
    icon: require("../../assets/images/interior/bag.png"),
    check: false,
    important: false
  },
  // {
  //   order: 11,
  //   id: 9,
  //   name: "存放贵重物品",
  //   icon: require("../../assets/images/interior/drawer.png"),
  //   check: false,
  //   important: false
  // },
]

export const BASIC_NEED_LIST = [
  {
    order: 0,
    id: 5,
    name: "挂置短衣",
    icon: require("../../assets/images/interior/short.png"),
    check: true,
    important: true
  },
  {
    order: 1,
    id: 10,
    name: "挂置长衣",
    icon: require("../../assets/images/interior/long.png"),
    check: true,
    important: false
  },
  {
    order: 2,
    id: 4,
    name: "叠放衣物",
    icon: require("../../assets/images/interior/fold.png"),
    check: true,
    important: false
  },
  {
    order: 3,
    id: 1,
    name: "放置内衣物",
    icon: require("../../assets/images/interior/underwear.png"),
    check: true,
    important: false
  },
  {
    order: 8,
    id: 6,
    name: "收纳被褥",
    icon: require("../../assets/images/interior/bedding.png"),
    check: true,
    important: false
  },
  {
    order: 7,
    id: 6,
    name: "收纳过季衣物",
    icon: require("../../assets/images/interior/season.png"),
    check: true,
    important: false
  }
]

export const UNIQUE_NEED_LIST = [
  {
    order: 5,
    id: 8,
    name: "挂置裤裙",
    icon: require("../../assets/images/interior/pants.png"),
    check: false,
    important: false
  },
  {
    order: 10,
    id: 2,
    name: "放置包包",
    icon: require("../../assets/images/interior/bag.png"),
    check: false,
    important: false
  },
  {
    order: 9,
    id: 14,
    name: "放置行李箱",
    icon: require("../../assets/images/interior/luggage.png"),
    check: false,
    important: false
  },
  {
    order: 4,
    id: 13,
    name: "收纳首饰配件",
    icon: require("../../assets/images/interior/jewelry.png"),
    check: false,
    important: false
  },
  {
    order: 6,
    id: 11,
    name: "放置鞋子",
    icon: require("../../assets/images/interior/shoe.png"),
    check: false,
    important: false
  },
  // {
  //   order: 11,
  //   id: 9,
  //   name: "存放贵重物品",
  //   icon: require("../../assets/images/interior/drawer1.png"),
  //   check: false,
  //   important: false
  // },
]

