import { DoorType, DOOR_TYPE } from '@/common/CommonType';
import { IStitchingFrameColor, IWardrobeDoorOrKnobCategory } from '@/common/SelectExteriorStyleTypes';
import { IWardrobeDoorOrKnob, IWardrobeWithProduct } from '@/common/WardrobeTypes';
import { filterDoorCombList } from '@/components/Interior/FilterConfig';
import data  from '@/mock/wardrobe_3d.json';
import { ITemplate, IFrame } from "@/types/pax3d";
import { cloneDeep, find, isEmpty } from 'lodash';
import { v4 as uuids4 } from "uuid";
import { getMirrorDoor } from './exterior-tool';
import { clearDoor, getHingeDoor, getSlidingDoor } from './wardrobe-tool';

interface IParams {
  width: number;
  height: number;
  depth: number;
  frameColor: string;
  doorType: DoorType;
  combination?: number[];
}

export function createTemplate(params: IParams) {
  const { width, height, depth, frameColor, doorType, combination } = params;
  let combinationArr: number[] = [];
  const baseData = (cloneDeep(data) as unknown as ITemplate);
  const baseFrame = baseData.frames[0];
  const frames: Array<IFrame> = [];

  if (combination) {
    combinationArr = combination
  } else {
    // 如果不指定组合的话，需要选中默认组合，FilterConfig文件中有配置
    const doorList = filterDoorCombList(width / 10, doorType)
    const curDoor = doorType === DoorType.SLIDING ? doorList.find(d => d.isSlidingDoor && d.defaultComb) :
      (doorList.find(d => !d.isSlidingDoor && d.defaultComb) || doorList.find(d => d.isSlidingDoor && d.defaultComb))
    if (curDoor) combinationArr = curDoor.value.replace(/\//g, '').split('+').map(item => Number(item) * 10)
  }

  combinationArr.forEach((c: number, index: number) => {
    const _curFrame = window.ALL_FRAMES && find(window.ALL_FRAMES, { width: c, height, depth: depth, frameColor })
    const frame = cloneDeep(baseFrame);
    if (_curFrame) {
      frame.id = uuids4();
      frame.frameId = _curFrame.frameId;
      frame.frameProductId = _curFrame.frameId;
      frame.frameProducts = _curFrame.itemNoLocal
      frame.sortNo = index + 1;
      frame.color = _curFrame.frameColor;
      frame.depth = _curFrame.depth;
      frame.height = _curFrame.height;
      frame.width = _curFrame.width;
      frame.width = _curFrame.width;
      frame.imageUrl = _curFrame.imageUrl;
      frame.modelUrl = _curFrame.modelUrl;
      frames.push(frame);
    } else {
      frame.id = uuids4();
      frame.sortNo = index + 1;
      frame.depth = depth;
      frame.height = height;
      frame.width = c;
      frame.modelUrl = `${frameColor.toLowerCase()}-${c}-${height}-${depth}.glb`;
      frames.push(frame);
    }
  })
  baseData.frames = frames;
  baseData.combination = combinationArr.join(',');
  baseData.totalLength = width;
  baseData.color = frameColor;
  baseData.depth = depth;
  baseData.height = height;
  baseData.id = uuids4();
  return baseData;
}

export function structWardrobeWithProduct(wardrobe: IWardrobeWithProduct, wardrobeDoorOrKnobCategory?: Array<IWardrobeDoorOrKnobCategory> | null
  ,door?: IWardrobeDoorOrKnob, knob?: IWardrobeDoorOrKnob, stitchingFrame?: IStitchingFrameColor) {
  if (wardrobe) {
    const paxGlobalData = localStorage.getItem('PAX_GLOBAL_DATA')
    const paxGlobalDataObj = paxGlobalData ? JSON.parse(paxGlobalData) : null
    const activeExterior = paxGlobalDataObj?.activeExterior
    const _category = paxGlobalDataObj?.wardrobeDoorOrKnobCategory
    const _door = door || (activeExterior && activeExterior.DOOR) || null
    const _knob = knob || (activeExterior && activeExterior.KNOB) || null
    const _stitchingFrame = stitchingFrame || (activeExterior && activeExterior.STITCHING_FRAME)
    const _wardrobeDoorOrKnobCategory = wardrobeDoorOrKnobCategory || _category || null
    let newWardrobeWithProduct = wardrobe;
    if (_door) {
      switch (_door.categoryName) {
        case "合叶门":
        case "375doors":
        case "合叶门排行":
          if (_wardrobeDoorOrKnobCategory) {
            const mirrorDoor = getMirrorDoor(_wardrobeDoorOrKnobCategory);
            // 获取所有合页门门板
            const categoryDoor = find(_wardrobeDoorOrKnobCategory, { categoryName: _door.categoryName })
            const isVinkensDoor = _door.products?.[0]?.name?.indexOf('维肯斯') !== -1
              || _door.products?.[0]?.name?.indexOf('芜黑') !== -1
            newWardrobeWithProduct = getHingeDoor(wardrobe,
              _door, mirrorDoor, isVinkensDoor ? null : ((isEmpty(_knob) || !_knob) ? null : _knob), categoryDoor?.components);
          }
          break;
        case "整体滑门":
          newWardrobeWithProduct = getSlidingDoor(wardrobe, _door, DOOR_TYPE.INTEGRAL);
          break;
        case "拼接滑门":
          if (!isEmpty(_stitchingFrame)) {
            newWardrobeWithProduct = getSlidingDoor(wardrobe, _door, DOOR_TYPE.STITCHING, _stitchingFrame);
          }
          break;
        case "滑门排行":
          if (_door.componentName === '滑门框面板4件') {
            if (!isEmpty(_stitchingFrame)) {
              newWardrobeWithProduct = getSlidingDoor(wardrobe, _door, DOOR_TYPE.STITCHING, _stitchingFrame);
            }
          } else {
            newWardrobeWithProduct = getSlidingDoor(wardrobe, _door, DOOR_TYPE.INTEGRAL);
          }
          break;
        case "其他":
          newWardrobeWithProduct = clearDoor(wardrobe);
          break;
        default:
          break;
      }
    }
    return newWardrobeWithProduct;
  }
}
