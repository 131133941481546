export function setCookie(name: string, value: any, day?: number, path?: string, domain?: string) {
  day = day || 30;
  path = path || '/';
  let str = name + '=' + value + '; ';
  if(day) str += 'expires=' + new Date(Date.now() + day * 24 * 3600 * 1000).toUTCString() + '; ';
  if(path) str += 'path=' + path + '; ';
  if(domain) str += 'domain=' + domain;
  document.cookie = str;
}

export function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while(c.charAt(0) === ' ') c = c.substring(1, c.length);
    if(c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
