import styles from "./SelectWidth.module.scss";
import { Slider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useConfig } from "../../reducers/searchConfReducer";
import { DoorType } from "../../common/CommonType";
import DesignerModal from "../Modal/DesignerModal";
import QuestionMarkIcon from "../../assets/icons/question_mark3.svg";
import Tip from "../Tip/tip";

const SLIDING_MARKS = [150, 200, 300, 325];
const ALL_MARKS = [50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325];

interface IProps {
  width: number;
  setWidth: (width: number) => void;
}
function SelectWidth(props: IProps) {
  const { width, setWidth } = props
  const markDom = [];

  const { state, dispatch } = useConfig();

  const [marks, setMarks] = useState<{ value: number }[]>([]);
  const [tipShow, setTipShow] = useState(false);

  function onChange(e: React.ChangeEvent<{}>, value: number | number[] = 50) {
    e.preventDefault();
    if (Array.isArray(value)) {
      return;
    }
    setWidth(value)
  }

  function afterChange(
    e: React.ChangeEvent<{}>,
    value: number | number[] = 50
  ) {
    e.preventDefault();
    setWidth(value as number)
  }

  function handleModalClose() {
    setWidth(300)
  }

  useEffect(() => {
    let valueList = [];
    if (state.doorType === DoorType.SLIDING) {
      valueList = SLIDING_MARKS;
      if (valueList.indexOf(width) < 0) setWidth(200)
    } else {
      valueList = ALL_MARKS;
    }
    const newMarks: { value: number }[] = [];
    for (let i = 0; i < valueList.length; i++) {
      newMarks.push({ value: valueList[i] });
    }
    setMarks(newMarks);
  }, [state.doorType, width, dispatch, setWidth]);

  for (let i = 50; i <= 325; ) {
    if (state.doorType === DoorType.SLIDING && SLIDING_MARKS.indexOf(i) < 0) {
      markDom.push(
        <li
          className={`${width === i ? styles.LabelActive : ""} ${
            styles.LabelDisabled
          }`}
          key={i}
        >
          {i < 325 ? i : "300+"}
        </li>
      );
    } else {
      markDom.push(
        <li
          className={`${width === i ? styles.LabelActive : ""} ${(i % 50 !== 0 && i <= 300) ? styles.hideNumber : ""}`}
          key={i}
        >
          {i < 325 ? i : "300+"}
        </li>
      );
    }
    i += 25;
  }

  useEffect(() => {
    const fn = () => {
      setTipShow(false);
    };
    document.addEventListener("click", fn);
    return () => {
      document.removeEventListener("click", fn);
    };
  }, []);

  return (
    <div className={styles.Container}>
      <div className={styles.Label}>
        <span>宽度</span>
        <span className={styles.LabelInfo}>（cm）</span>
        <span
          className={styles.QuestionMark}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            onClick={() => {
              setTipShow(!tipShow);
            }}
            src={QuestionMarkIcon}
            alt="question_mark"
          />
          {tipShow && (
            <Tip
              desc={"pax衣柜最小宽度为 50cm，并以每25cm为单位递增。"}
            ></Tip>
          )}
        </span>
      </div>
      <ul className={styles.MarkList}>{markDom}</ul>
      <div className={styles.SliderContainer}>
        <Slider
          onChange={onChange}
          onChangeCommitted={afterChange}
          classes={{
            root: styles.Root,
            mark: styles.MarkActive,
            markActive: styles.MarkActive,
            track: styles.Track,
            rail: styles.Rail,
            thumb: styles.Thumb,
          }}
          aria-labelledby="discrete-slider"
          marks={marks}
          min={50}
          max={325}
          step={null}
          value={width}
        />
      </div>
      <DesignerModal
        title={"需要3米以上的衣柜？"}
        open={width > 300}
        onClose={handleModalClose}
      />
    </div>
  );
}

export default SelectWidth;
