import styles from "./PaxRender.module.scss"
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IWardrobeWithProduct } from "@/common/WardrobeTypes";
import { Events, HtmlRender, PaxAdapter, WardrobeModel, FrameModel } from "@aidesign/pax-render-2d";
import { PaxActionType, usePaxConfig } from '@/reducers/paxReducer';
import { useInViewport, usePrevious } from "ahooks";

interface Props {
  wardrobe: IWardrobeWithProduct,
  showFa?: boolean,
  showRuler?: boolean,
  showClickArea?: boolean,
  scale?: number,
  showDoor?: boolean,
  showPropIn?: boolean,
  showAnchors?: boolean,
  lazyLoad?: boolean,
  randomPropIn?: boolean,
  showFrameSelect?: boolean,
  defaultSelectSort?: number,
  onRender?: (domRect: DOMRect | undefined) => void
}


function PaxRender(props: Props) {
  const { wardrobe, showFa, showRuler, showDoor, showAnchors,scale, showPropIn, showFrameSelect, defaultSelectSort,onRender } = props
  const container = useRef<HTMLDivElement>(null)
  const { state, dispatch } = usePaxConfig()

  const inViewPort = useInViewport(container);
  const [loaded, setLoaded] = useState(false);
  const previous = usePrevious(loaded);

  const updateTemplate = useCallback(async (dom) => {
    const render = new HtmlRender(dom)
    const wardrobeModel: WardrobeModel = PaxAdapter.createWardrobe(wardrobe)
     /**** 临时处理拼接花们问题， 以后移动到paxrennder ***/
     wardrobeModel.slidingDoors.forEach(item => item.occupiedHeight = wardrobe.height)
     /*******/
    render.getRender().loadWardrobe(wardrobeModel)
    // 超过2.5米的柜子左边让出来一些空间给尺子
    const marginLeft = showRuler && wardrobeModel.middleTotalWidth > 2500 ? 100 : 0
    render.getRender().resizeWardrobe(marginLeft)
    await render.getRender().renderWardrobe()
    if (showPropIn) {
      const random = props.randomPropIn === undefined ? true : props.randomPropIn
      render.getRender().setPropInScale(1.1, 1.2)
      render.getRender().showPropIn(random)
    }
    if (showFa) {
      render.showFunctionArea()
    }

    if (showRuler) {
      render.getRender().showRuler()
    }
    if (showAnchors) {
      render.getRender().showAnchors()
    }
    if (showFrameSelect) {
      render.getRender().showFrameSelector(state.clickFrameSortNo || defaultSelectSort)
      // 默认选中哪个frame
      if (state.clickFrameSortNo <= 0 && defaultSelectSort) {
        dispatch({
          type: PaxActionType.Update,
          payload: {
            clickFrameUuId: wardrobe.frames[defaultSelectSort - 1].id,
            clickFrameSortNo: defaultSelectSort,
            reloadClickArea: true,
          }
        })
      }
      const event = render.getRender().getEventEmitter()
      event.on(Events.SELECT_FRAME, (frame: FrameModel | null) => {
        if (!frame) return
        dispatch({
          type: PaxActionType.Update,
          payload: {
            clickFrameUuId: frame.id,
            clickFrameSortNo: frame.sortNo,
            reloadClickArea: true,
            showSelectTip: false
          }
        })
      })
    }
    if (showDoor) {
      render.getRender().renderDoors()
    }
  }, [wardrobe, showFa, showRuler, showFrameSelect, showDoor])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!container.current || !wardrobe || !state.propInsLoaded) {
      return
    }
    if (props.lazyLoad) {
      if (previous || !inViewPort) {
        return
      }
    }
    console.log("render PAX!!!")
    updateTemplate(container.current)
    const boundingRect = container.current.querySelector(".pax-container")?.getBoundingClientRect()
    onRender && onRender(boundingRect)
    setLoaded(true)
  }, [wardrobe, updateTemplate, state.propInsLoaded, inViewPort])//eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div
        ref={container}
        className={styles.PaxRender}
        style={{ transform: `scale(${scale || 1})` }}
      />
    </React.Fragment>
  )
}

export default PaxRender
