import ikea from 'ikea-jssdk';
import { PLATFORM } from '@/common/CommonType';
import DefaultModule from '../ikea-routers/defaultModule';
import StoreCompanion from '../ikea-routers/storeCompanion';
import Miniprogram from '../ikea-routers/miniprogram';
import { getCookie } from "../utils/cookie-tool";
import Account from '@/ikea-routers/account';
import { isProdMode } from './common-tool';
import aidTrack from "@/utils/aid-track";

/**
 * ikea sdk初始化
 * @param {初始化参数} opts
 */
export function init(opts?: any) {
  ikea.init(opts || {
    refer: {
      referId: 'pax-online-new',
    },
    env: (window.location.host === 'paxonline.aidesign.ingka-internal.cn' || window.location.host === 'pax.web.ikea.cn' || window.location.host === 'web-metrics.aidesign.ingka-internal.cn') ? 'prod' : 'qa'
  });
}

export function setGioUser() {
  ikea.getUserInfo().then(res => {
    if (res.value) {
      window.gio('setUserId', res.value.hashedPartyUid)
      aidTrack.setDeviceId(res.value.hashedPartyUid)
    }
  })
}

export async function whichPlatform() {
  let platformValue = PLATFORM.H5;
  try {
    const res = await ikea.currentPlatform();
    const {
      value: {
        platform
      } = {}
    } = res;
    switch(platform) {
      case 'iOS':
      case 'ANDROID':
        platformValue = PLATFORM.APP;
        break;
      case 'WechatMiniprogram':
        platformValue = PLATFORM.SMP; // 这个指宜家的小程序
        break;
      case 'PcWeb':
      case 'MobileWeb':
        platformValue = PLATFORM.IKEA;
        break;
      case 'StoreCompanion':
        platformValue = PLATFORM.STORE_COMPANION; // 这个指宜家门店助手的小程序
        break;
      case 'unknown':
      default:
        platformValue = PLATFORM.H5;
        break;
    }
  } catch(err) {
    console.log(err);
  }
  return platformValue;
}

export async function getGioPlatform() {
  let platformValue = 'h5';
  try {
    const res = await ikea.currentPlatform();
    const {
      value: {
        platform
      } = {}
    } = res;
    switch(platform) {
      case 'iOS':
        platformValue = 'iOS';
        break;
      case 'ANDROID':
        platformValue = 'Android';
        break;
      case 'WechatMiniprogram':
        platformValue = 'WechatMiniprogram';
        break;
      case 'PcWeb':
        platformValue = 'pc';
        break;
      case 'MobileWeb':
        platformValue = 'h5';
        break;
      case 'unknown':
      default:
        platformValue = 'h5';
        break;
    }
  } catch(err) {
    console.log(err);
  }
  return platformValue;
}
export async function getCscChannel() {
  let channel = "unknown";
  try {
    const res = await ikea.currentPlatform();
    const { value: { platform } = {} } = res;
    switch (platform) {
      case "iOS":
        channel = "iOS";
        break;
      case "ANDROID":
        channel = "Android";
        break;
      case "WechatMiniprogram":
        channel = "WechatMiniprogram";
        break;
      case "PcWeb":
        channel = "Web_pc";
        break;
      case "MobileWeb":
        channel = "Web_mobile";
        break;
      case "unknown":
      default:
        channel = "unknown";
        break;
    }
  } catch (err) {
    console.log(err);
  }
  return channel;
}
// 下面是老的，撤回的，现在貌似改成上面的了，先留着做观察
// export async function getCscChannel() {
//   let channel = 'H5';
//   try {
//     const res = await ikea.currentPlatform();
//     const {
//       value: {
//         platform
//       } = {}
//     } = res;
//     switch(platform) {
//       case 'iOS':
//         channel = 'IOS';
//         break;
//       case 'ANDROID':
//         channel = 'ANDROID';
//         break;
//       case 'WechatMiniprogram':
//         channel = 'SMP';
//         break;
//       case 'PcWeb':
//         channel = 'WEB';
//         break;
//       case 'MobileWeb':
//         channel = 'Mobile_Web';
//         break;
//       case 'unknown':
//       default:
//         channel = 'H5';
//         break;
//     }
//   } catch(err) {
//     console.log(err);
//   }
//   return channel;
// }
/**
 * 是否在mobile官网环境内
 * @returns ikea sdk promise
 */
export async function isInMobile() {
  try {
    const res = await ikea.currentPlatform();
    const {
      value: {
        platform
      } = {}
    } = res;
    return platform === 'PcWeb' || platform === 'MobileWeb'; // 'M2' | 'PcWeb' | 'MobileWeb' | 'iOS' | 'ANDROID' | 'WechatMiniprogram' | 'unknown',
  } catch (err) {
    console.log(err);
  }
}
/**
 * 是否在微信小程序环境内
 * @returns ikea sdk promise
 */
export async function isInWXMP() {
  try {
    const res = await ikea.currentPlatform();
    const {
      value: {
        platform
      } = {}
    } = res;
    return platform === 'WechatMiniprogram'; // unknown,WechatMiniprogram;
  } catch (err) {
    console.log(err);
  }
}
/**
 * 是否在APP环境内
 * @returns ikea sdk promise
 */
 export async function isAPP() {
  try {
     const res = await ikea.currentPlatform();
     const {
       value: {
         platform
       } = {}
     } = res;
     return platform === 'iOS' || platform === 'ANDROID'; //platform: 'M2' | 'PcWeb' | 'MobileWeb' | 'iOS' | 'ANDROID' | 'WechatMiniprogram' | 'unknown'
   } catch (err) {
     console.log(err);
   }
}
/**
 * 联系CSC客服
 * @returns ikea sdk promise
 */
export function getCustomerService(autoText: string) {
  return ikea.navigateTo(DefaultModule.CustomerService({
    channel: 'pax',
    autoText,
  }))
    .then(data => {
      if (data.type === 'success') {
        // 调用成功
        console.log('ikea sdk: 联系客服调用成功')
      }
      if (data.type === 'fail') {
        console.log('ikea sdk: 联系客服调用失败');
      }
    })
    .catch(err => {
      console.log(err)
    });
}
export function getCustomerServiceV2(opts: any) {
  const { channel, designCode, autoText } = opts;
  // 参数参考文档：https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=735360544&spaceEditingRestriction=true
  return ikea.navigateTo(DefaultModule.CustomerService({
    // channel,
    page: 'Pax',
    intent: 'Pax',
    autoText,
  }))
  .then(data => {
    if (data.type === 'success') {
      // 调用成功
      console.log('ikea sdk: 联系客服调用成功')
    }
    if (data.type === 'fail') {
      console.log('ikea sdk: 联系客服调用失败');
    }
  })
  .catch(err => {
    console.log(err)
  });
  // const cscUrl = isProdMode() ? 'https://kefu.csc.ikea.cn/webim/im.html' : 'https://cnos-ppe-im.ingka-dt.cn/webim/im.html';
  // const url = `${cscUrl}?channel=${channel}&page=Pax_Design&designCode=${designCode}&autoText=${autoText}`
  // return ikea.openH5(url)
  //   .then(data => {
  //     if (data.type === 'success') {
  //       // 调用成功
  //       console.log('ikea sdk: 联系客服调用成功')
  //     }
  //     if (data.type === 'fail') {
  //       console.log('ikea sdk: 联系客服调用失败');
  //     }
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   });
}
/**
 * 加入购物车
 * @param {*} ikea sdk 需要的参数
 */
export function addToShoppingCart(param: { products: string }) {
  return ikea.navigateTo(DefaultModule.AddProductsToShoppingCart(param))
    .then(data => {
      // todo
    })
    .catch(err => {
      console.log(err)
    });
}

export function goToCartPage() {
  return ikea.navigateTo(DefaultModule.ShoppingCart())
}

/**
 * 分享（其中就有保存）
 * @param {*} ikea sdk 需要的参数: link, poster, title
 */
export function share(param: { link?: string, path?: string, poster: string, title: string, trackSource?: string, imageUrl?:string }) {
  return ikea.navigateTo(DefaultModule.Share(param))
}
/**
 * 生成sdk转换过的链接
 * @param {*} param
 */
type GioConfigType = {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
}
type LinkOptionType = {
  h5Link?: string
  gioConfig?: GioConfigType
}
export function generateLink(param: LinkOptionType) {
  return ikea.generateLink(param)
    .catch(err => {
      console.log(err)
    });
}
/**
 * 返回小程序首页
 */
export function goHome() {
  return ikea.navigateTo(DefaultModule.Home({}))
}
/**
 * 跳转登录页
 */
export function goLogin(redirectUri = window.location.host) {
  return ikea.navigateTo(Account.Login({ redirectUri }))
}
/**
 * 是否登录
 */
export async function isLogin() {
  let result = false;
  try {
    const { type, value } = await ikea.isLogin();
    console.log('isLogin type, value', type, value)
    type === 'success' && (result = value as boolean);
  } catch(e:any) {
    console.log(e.message)
  }
  return result;
}
/*
 * 跳转页面
 */
export function openH5(url: string) {
  return ikea.openH5(url);
}
/**
 * 调用sdk的加购
 */
export function addCart(param: any) {
  return ikea.request({
    path: '/shopping/cart/add-products',
    method: 'POST',
    headers: {
      'X-Client-Platform': getCookie('platform') || 'MobileWeb',
    },
    data: {
      products: param,
    },
    // mockToken: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjBiNDlkNGQ1MTZmNDFiMmJkODViZTNkMzYxOWMwM2U4eks4Y2ZnV2RNd1YifQ.eyJzdWIiOiI3OTI0MDk1NTg4Mzg1MzAxMjMiLCJndHAiOiJwYXNzd29yZCIsInVuaW9uSWQiOiIiLCJjb29raWUiOiIiLCJ1c2VyX25hbWUiOiJSMjAyMTAzMTg3YTlkNzg0N2VjMzg5ZWY2YjM0YzI3ZGQ0OTNjYjBiNCIsImlzcyI6IkNJQU0iLCJwYXJ0eVVJZCI6IkNJQU1mZWYzLTgwMGMtNGM0Yy05NzRiLWU5ODNmZjBhODhhZSIsImNsaWVudF9pZCI6IjBiNDlkNGQ1MTZmNDFiMmJkODViZTNkMzYxOWMwM2U4eks4Y2ZnV2RNd1YiLCJmYW1pbHlJZCI6bnVsbCwiYXVkIjpbImFwcF9hcGlfcmVzb3VyY2UiLCJiZmZfYXBpX3Jlc291cmNlIl0sImdyYW50X3R5cGUiOiJwYXNzd29yZCIsInVzZXJfaWQiOiJiMmNmZTYxMTgyYzlmMmE5MDJlYTI0OTEzYWFjNWM2YlpxRTUzVzZNcEpmIiwicGhvbmUiOiIxODgyMTIwNzAxOSIsImF6cCI6IiIsInNjb3BlIjpbInJlYWQiXSwiZXhwIjoxNjI4MDczNTQ0LCJqdGkiOiJhNDcwMTZjNC0xY2YxLTRmMzEtOGQ4ZC0wYjcwOWI4MWQxNzgiLCJlbWFpbCI6InN0YWN5LmppYW9AaW5na2EuaWtlYS5jb20iLCJtZW1iZXJJZCI6bnVsbCwiaWF0IjoxNjI3NDY4NzQ0LCJuYmYiOjE2Mjc0Njg2ODR9.B-gIi-gYClZqn8NMO6V7LFEgc4Zlag1GuLY4eAs3SFKMZKdmZgYGqHqnk97idj7N7h8oZSjW33Z8aqKIZ0plOEAzjOxutrjo-RA5bg8x65x_BoPPOWmv1kM8IWAJjtSUk6cj-YA4JfOXqaS3OMOPF-SV7wRZeRTJYApvZvany6hdLPkhFMKBDbL7YP-lHuG_CeaSCvEoDpGlP-EHm45wgMS1m6mnz5c2Vfd0FR__TwrL3aJVY_gZ_cH1SqX5_L1qmZphilUPRSlKCXD1mKtEXiKL0O48566Cgrdt_eW5hllHBB756xGMudDrzU46iTM-rlMXkFaQAvnuZpgfLkTPuQ'
  }).catch(err => {
    console.log(err);
  })
}

export async function getToken () {
  const { value } = await ikea.getToken();
  // console.log('app token', value)
  return value;
}

export async function getDeviceId() {
  const { value } = await ikea.getDeviceId();
  // console.log('app token', value)
  return value;
}

export function goBack() {
  ikea.navigateBack();
}

export async function getAddressInfo() {
  try {
    const { type, value } = await ikea.getAddressInfo();
    // '{"zipCode":"201811","province":"上海市","city":"上海市","district":"","street":"","deliverable":true}'
    console.log('smp address v1.8.4 type value', type, value)
    if (type === 'success') {
      const { zipCode, province, city } = value;
      return {
        zipcode: zipCode,
        province,
        city,
      };
    }
    return null;
  } catch(e) {
    return null;
  }
}
// ABTest可以参考：https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=481952880
export function abTestGetVariation(params: { experimentId: string; defaultVariation: string }) {
  return ikea.abtest.getVariation(params)
}

export function getAbtestList(){
  return ikea.abtest.getAbtestList()
}

export async function addToCart(products: {
  productFullId: string,
  quantity: number
}[]) {
  const res = await ikea.request.addToCart({
    products
  });
  ikea.postMessage({ name: 'refreshShoppingCart' })
  return res
}

export function jumpStoreCompanionCS() {
  ikea.navigateTo(StoreCompanion.Contact({
    prePageType: 'pax',
  }));
}

export function goToCartPageInStoreCompanion() {
  ikea.navigateTo(Miniprogram.Smp({
    path: '/pages/shopping_cart/shopping_cart',
  }))
}

const ikeaSDK = {
  init,
  isInMobile,
  isInWXMP,
  isAPP,
  getCustomerService,
  addToShoppingCart,
  share,
  generateLink,
  goHome,
  openH5,
  isLogin,
  addCart,
  whichPlatform,
  getToken,
  getDeviceId,
  goBack,
  getAddressInfo,
  abTestGetVariation,
  goToCartPage,
  getAbtestList,
  jumpStoreCompanionCS,
  goToCartPageInStoreCompanion,
}

export default ikeaSDK;
