import { IWardrobeWithProduct } from '@/common/WardrobeTypes'
import { post, get } from './request'
export interface IGetWardrobesPageParams {
  userStorageNeeds: {
    order: number;
    id: number;
    important: boolean;
  }[];
  dimension: {
    height: number;
    mWidth: number;
    mMaxDepth: number;
  };
  lastId: number;
  combination: string;
  doorType: string;
  color: string,
  templateType: number,
  family: string,
  fulfillLevel: string,
  pageSize: number,
  noNeedComponents: string[],
}

interface IEditFavoriteParams{
  wardrobeId: string | undefined;
}
interface IEditDesignParams{
  recordId: string | undefined;
}
interface IFeedbackParams {
  position: number;
  remarks: string;
  score: number;
  templateId: string;
  requestId: string;
}

// 获取推荐列表
export const getWardrobesPage = <T>(params: Partial<IGetWardrobesPageParams>): Promise<T> => {
  const abtest = sessionStorage.getItem('abtest_pax_hot_decrease')
  const headers: Record<string, string> = {}
  if (abtest === 'HOT') {
    headers['pax-top-sales'] = 'ON'
  }
  if (abtest === 'DECREASE') {
    headers['pax-202402-10p-decrease'] = 'ON'
  }
  return post('/v2/recommend/wardrobes/page', { ...params }, undefined, {
    headers
  })
}
// 获取推荐列表
export const recommendFeedback = <T>(params: Partial<IFeedbackParams>): Promise<T> => {
  return post('https://paxonline-sit.aidesign.ingka-dt.cn/recommend-service/1.0/behavior/feedback', { ...params })
}
// 获取收藏id list
export const getFavoriteIdList = <T>(): Promise<T> => {
  return get<T>('/v1/userFavouriteWardrobesMeta', {}, { isSilence: true })
}
// 获取收藏id list
export const getDesignWardrobes = <T>(): Promise<T> => {
  return get<T>('/UserDesignWardrobe/v1/getFuncAreaAdjustments')
}
// 获取收藏柜子list
export const getFavoriteWardrobes = <T>(): Promise<T>=> {
  return get<T>('/v1/userFavouriteWardrobes')
}
// add收藏柜子
export const addFavorite = <T>(params: IEditFavoriteParams): Promise<T> => {
  return post('/v1/userLikeWardrobe', { ...params })
}
// cancel收藏柜子
export const cancelFavorite = <T>(params: IEditFavoriteParams): Promise<T> => {
  return post('/v1/userDislikeWardrobe', { ...params })
}
// delete删除设计过的柜子
export const deleteDesign = <T>(params: IEditDesignParams): Promise<T> => {
  return post('/UserDesignWardrobe/v1/removeFuncAreaAdjustment', { ...params })
}
// 完成衣柜内配调整
export const saveFuncAreaAdjustment = <T>(params: { wardrobeWithProduct: IWardrobeWithProduct | null }): Promise<T> => {
  return post('/UserDesignWardrobe/v1/saveFuncAreaAdjustment', { ...params })
}
