import clsx from "clsx";
import Styles from './ContCompForCombine.module.scss';
import { IList } from './FilterConfig';
import frame100Png from '@/assets/images/interior/frame-100.png';
import frame75Png from '@/assets/images/interior/frame-75.png';
import frame50Png from '@/assets/images/interior/frame-50.png';

export interface IContCompForCombineProps {
  list: IList[];
  onSelect: (item: IList) => () => void;
}

function ContCompForCombine(props: IContCompForCombineProps) {
  const { list, onSelect } = props;

  return (
    <section className={Styles.container}>
      <ul className={Styles.filterBox}>
      {
        list.map((item: IList) => {
          const { code, text, checked, value } = item;
          if (code === 0) {
            return (
              <li
                key={code}
                className={clsx(Styles.filterItem, Styles.all, { [Styles.active]: checked })}
                onClick={onSelect(item)}
              >
                { text }
              </li>
            )
          } else {
            let ans = value;
            if (ans.startsWith('/')) {
              ans = ans.slice(1);
            }
            if (ans.endsWith('/')) {
              ans = ans.slice(0, ans.length - 1);
            }
            const frameSizeList = ans.split('+');

            return (
              <li
                key={code}
                className={clsx(Styles.filterItem, { [Styles.active]: checked })}
                onClick={onSelect(item)}
              >
                <div className={Styles.thumbZone}>
                {
                  frameSizeList.map((size, index) => (
                    <img
                      key={index}
                      className={Styles.frameImg}
                      src={size === '50' ? frame50Png : (size === '75' ? frame75Png : frame100Png)}
                      alt={size}
                      />
                  ))
                }
                </div>
                <p className={Styles.title}>{ text }</p>
                { code === 1 && <p className={Styles.desc}>（较常用）</p> }
              </li>
            )
          }
        })
      }
      </ul>
    </section>
  )
}

export default ContCompForCombine;
