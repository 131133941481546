import React, { createContext, useContext, useReducer } from 'react'
import { IRecommend } from "../common/WardrobeTypes"

export enum ACTION_TYPES {
  // 重置数据
  UPDATE_RECOMMEND_LIST, // 重置推荐列表数据
  UPDATE_FAVORITE_IDS, // 重置所有收藏id数据
  ADD_FAVORITE, // 新增收藏
  CANCEL_FAVORITE, // 取消收藏
  UPDATE_FAVORITE_LIST, // 重置收藏列表数据
  UPDATE_DESIGN_LIST, // 重置设计过的衣柜数据
  CANCEL_DESIGN // 删除设计过的衣柜数据
};

interface InteriorActions {
  type: ACTION_TYPES;
  payload: Partial<IPayload>;
}

interface IPayload {
  recommendData: IRecommend[]; // 推荐的衣柜
  favoriteIds: IFavorite[]; // 收藏的衣柜数量
  userDesignWardrobeCount: number; //设计的衣柜数量
  favoriteWardrobeList: IRecommend[]; // 收藏的衣柜
  designWardrobeList: IRecommend[]; // 设计的衣柜
  addFavorite: IFavorite; // 添加收藏
  cancelFavorite: IFavorite; // 取消收藏
  cancelDesign: IEditDesign; // 取消设计
}

type InteriorState = {
  recommendData: IRecommend[]; // 推荐的衣柜
  favoriteIds: IFavorite[]; // 收藏的衣柜数量
  userDesignWardrobeCount: number; //设计的衣柜数量
  favoriteWardrobeList: IRecommend[]; // 收藏的衣柜
  designWardrobeList: IRecommend[]; // 设计的衣柜
}
export interface IFavoriteIds {
  userDesignWardrobeCount: number
  wardrobes: IFavorite[]
}
export interface IFavorite {
  wardrobeId: string;
}
export interface IEditDesign {
  recordId: string;
}
// 初始化值、初始化赋值为undefined的原因是页面初始化的时候不需要显示无数据的提示
export const initData = {
  recommendData: undefined,
  favoriteIds: [],
  userDesignWardrobeCount: 0,
  favoriteWardrobeList: [],
  designWardrobeList: []
}

export const interiorReducer = (state: Partial<InteriorState>, action: InteriorActions) => {
  const { recommendData, favoriteIds, favoriteWardrobeList, designWardrobeList, userDesignWardrobeCount } = state
  const newRecommendData = recommendData && [...recommendData]
  const newFavoriteIds = favoriteIds && [...favoriteIds] || []
  const newDesignWardrobeList = designWardrobeList && [...designWardrobeList]

  // 设置某个收藏衣柜的ID map到对应的衣柜
  const resetIdMapWardrobeList = (wardrobeList?: IRecommend[] | undefined, favoriteId?: string, isFavorite?: boolean) => {
    wardrobeList?.forEach((item: IRecommend) => {
      if (item.wardrobeWithProduct.id === favoriteId) item['favorite'] = isFavorite
    });
  }
  // 重新设置推荐列表的收藏及排序
  const resetAllDate = (wardrobeList?: IRecommend[], favoriteIds?: IFavorite[], isSort?: boolean) => {
    // 初始时清楚所有收藏状态
    wardrobeList?.forEach((item: IRecommend) => {
      item['favorite'] = false
    });
    // 遍历所有的收藏list,将对应的收藏柜子加上favorite: true
    favoriteIds?.forEach((ele: IFavorite) => {
      resetIdMapWardrobeList(wardrobeList, ele.wardrobeId, true)
    })
    // 初始化加载所有柜子的时候需要排序，当加减收藏柜子时不需要排序
    if (isSort) {
      wardrobeList?.sort((a: IRecommend, b: IRecommend) => {
        const _a = a.favorite ? 1 : 0
        const _b = b.favorite ? 1 : 0
        return _b - _a
      })
    }
  }

  switch (action.type) {
    case ACTION_TYPES.UPDATE_RECOMMEND_LIST:
      resetAllDate(action.payload.recommendData, favoriteIds, true)
      return {
        ...state,
        recommendData: action.payload.recommendData
      };
    case ACTION_TYPES.UPDATE_FAVORITE_IDS:
      // 设置推荐列表是否为收藏衣柜
      resetAllDate(newRecommendData, action.payload.favoriteIds)
      return {
        ...state,
        favoriteIds: action.payload.favoriteIds,
        userDesignWardrobeCount: action.payload.userDesignWardrobeCount,
        recommendData: newRecommendData
      };
    case ACTION_TYPES.ADD_FAVORITE:
      // 设置推荐列表是否为收藏衣柜
      resetIdMapWardrobeList(newRecommendData, action.payload.addFavorite?.wardrobeId, true)
      return {
        ...state,
        recommendData: newRecommendData,
        favoriteIds: newFavoriteIds
      };
    case ACTION_TYPES.CANCEL_FAVORITE:
      // 设置推荐列表是否为收藏衣柜
      resetIdMapWardrobeList(newRecommendData, action.payload.cancelFavorite?.wardrobeId, false)
      // 更新收藏id
      const favoriteIdsTemp = favoriteIds && favoriteIds.filter((item: IFavorite) => {
        return item.wardrobeId !== action.payload.cancelFavorite?.wardrobeId
      }) || []
      // 更新收藏列表
      const favoriteWardrobeListTemp = favoriteWardrobeList && favoriteWardrobeList.filter((item: IRecommend) => {
        return item.wardrobeWithProduct.id !== action.payload.cancelFavorite?.wardrobeId
      })
      return {
        ...state,
        recommendData: newRecommendData,
        favoriteIds: favoriteIdsTemp,
        favoriteWardrobeList: favoriteWardrobeListTemp
      };
    case ACTION_TYPES.UPDATE_FAVORITE_LIST:
      return {
        ...state,
        favoriteWardrobeList: action.payload.favoriteWardrobeList
      };
    case ACTION_TYPES.UPDATE_DESIGN_LIST:
      return {
        ...state,
        designWardrobeList: action.payload.designWardrobeList
      };
    case ACTION_TYPES.CANCEL_DESIGN:
      // 更新收藏列表
      const newDesignWardrobeListTemp = newDesignWardrobeList && newDesignWardrobeList.filter((item: IRecommend) => {
        return item.templateId !== action.payload.cancelDesign?.recordId
      })
      return {
        ...state,
        designWardrobeList: newDesignWardrobeListTemp,
        userDesignWardrobeCount: userDesignWardrobeCount && userDesignWardrobeCount - 1
      };
    default:
      return {
        ...state,
      }
  }
}

export type interiorContextProps = {
  state: Partial<InteriorState>;
  dispatch: (props: InteriorActions) => void;
};
const Context = createContext<interiorContextProps>({
  state: initData,
  dispatch: () => { },
})
export const useInterior = () => useContext(Context)

export const InteriorCtxProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(interiorReducer, initData)
  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  )
}