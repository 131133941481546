export function augmentHtmlElement() {
  HTMLElement.prototype.swipe = HTMLElement.prototype.swipe || function (this: HTMLElement, callBack) {
    let isTouchMove = false;
    let startTx= -1;
    let startTy = -1;
    this.addEventListener('touchstart', function (e: TouchEvent) {
      const touches = e.touches[0];
      startTx = touches.clientX;
      startTy = touches.clientY;
      isTouchMove = false;
    }, false);
  
    this.addEventListener('touchmove', function (e:TouchEvent) {
      isTouchMove = true;
      e.preventDefault();
    }, false);
  
    this.addEventListener('touchend', function (e:TouchEvent) {
      if (!isTouchMove) {
        return;
      }
  
      const touches = e.changedTouches[0];
      const endTx = touches.clientX;
      const endTy = touches.clientY;
      const distanceX = startTx - endTx;
      const distanceY = startTy - endTy;
      let isSwipe = false;
  
      if (Math.abs(distanceX) >= Math.abs(distanceY)) {
        if (distanceX > 20) {
          callBack('left')
          console.log('fire swipe left event');
          isSwipe = true;
        } else if (distanceX < -20) {
          callBack('right')
          console.log('fire swipe right event');
          isSwipe = true;
        }
      } else {
        if (distanceY > 20) {
          callBack('up')
          console.log('fire swipe up event');
          isSwipe = true;
        } else if (distanceY < -20) {
          callBack('down')
          console.log('fire swipe down event');
          isSwipe = true;
        }
      }
  
      if (isSwipe) {
        console.log('fire swipe event');
      }
    }, false);
  }
}

