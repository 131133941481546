import styles from "./Common.module.scss";
import styleWidths from "./SelectWidth.module.scss";
import { FrameHeight } from "../../common/CommonType";
import QuestionMarkIcon from "../../assets/icons/question_mark3.svg";
import Tip from "../Tip/tip";
import { useEffect, useState } from "react";

interface IProps {
  height: number;
  setHeight: (height: number) => void;
}
function SelectHeight(props: IProps) {
  const { height, setHeight } = props
  const [tipShow, setTipShow] = useState(false);
  function handleClick(h: FrameHeight) {
    setHeight(h)
  }

  useEffect(() => {
    const fn = () => {
      setTipShow(false);
    };
    document.addEventListener("click", fn);
    return () => {
      document.removeEventListener("click", fn);
    };
  }, []);

  return (
    <div className={styles.Container}>
      <div className={styles.label}>
        <span>高度</span>
        <span
          className={styleWidths.QuestionMarkHeight}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            onClick={() => {
              setTipShow(!tipShow);
            }}
            src={QuestionMarkIcon}
            alt="question_mark"
          />
          {tipShow && (
            <Tip
              desc={"宜家 pax 提供两种不同的高度，请选择与您家高度最相近的"}
            ></Tip>
          )}
        </span>
      </div>
      <div className={styles.optionWrap}>
        <div
          className={`${styles.Option} ${
            height === 201 ? styles.optionActive : ""
          }`}
          onClick={() => handleClick(201)}
        >
          201 cm
        </div>
        <div
          className={`${styles.Option} ${
            height === 236 ? styles.optionActive : ""
          }`}
          onClick={() => handleClick(236)}
        >
          236 cm
        </div>
      </div>
    </div>
  );
}

export default SelectHeight;
