import { FROM_FLOW, FROM_PAGE, STORAGE_KEYS } from "@/common/CommonType";
import ikeaSDK from "./ikea-sdk";

// 其他渠道进入我们的页面，目前包括 scan：offline扫码、share：online分享、productList：购物车、designList：我的设计方案 ； '/recommend_test'：给到门店测试推荐结果用的页面
export const otherEntranceRouters = ['/scan', '/share', '/productList', '/designList', '/dtcSelectExteriorStyle', '/recommend_test', '/leads', '/custom_landingpage', '/wrd_landingpage']
// otherEntranceRouters 对应的枚举
const otherEntranceMap: any = {
  '/scan': FROM_PAGE.SCAN, //offline扫码
  '/share': FROM_PAGE.SHARE, //online分享
  '/productList': FROM_PAGE.SHOPPING_CART, //购物车
  '/designList': FROM_PAGE.DESIGN_LIST, //我的设计方案
  '/dtcSelectExteriorStyle': FROM_PAGE.DTC_SELECT_EXTERIOR, // 从DTC的PIP页面跳转过来修改门板和把手
  '/leads': FROM_PAGE.LEADS, 
  "/custom_landingpage": FROM_PAGE.CUSTOM_LANDINGPAGE,
  '/wrd_landingpage': FROM_PAGE.WRD_LANDINGPAGE
}

// 不记录历史记录的列表,designList:从app的我的设计页面跳转到我们的detail页面的路由
export const igNoreHistoryPathList = [
  '/editor',
  '/template_overview',
  '/inspire',
  '/outofstockplan',
  '/buyer_show',
  '/leads',
  '/custom_landingpage',
  '/wrd_landingpage',
  '/reserved'
].concat(otherEntranceRouters)

/**
 * 自定义路由返回
 * @param currentPathName ：当前路由的path
 * @param currentSearch :当前路由的search参数
 * @param templateId wardrobe的templateId
 * @param backFn 自定义返回函数
 * @returns null
 */
export function routeBack(currentPathName: string, currentSearch: string, templateId: string | undefined, backFn: (targetBackPathName?: string) => void) {
  const fromFlow = localStorage.getItem(STORAGE_KEYS.FROM_FLOW);
  const fromPage = localStorage.getItem(STORAGE_KEYS.FROM_PAGE);
  const searchParams = new URLSearchParams(currentSearch)
  if (fromFlow === FROM_FLOW.MAIN_FLOW) {
    let targetBackPathName = ''
    if (currentPathName === '/') {
      // 首页返回，如果是重新设计然后返回pip页面时还重置次流程
      if (fromPage !== FROM_PAGE.MAIN_ENTRANCE) localStorage.setItem(STORAGE_KEYS.FROM_FLOW, FROM_FLOW.OTHER_FLOW)
      return fromPage !== FROM_PAGE.MAIN_ENTRANCE ? backFn() : ikeaSDK.goBack();
    } else if (currentPathName?.startsWith("/interior_design")) {
      targetBackPathName = '/'
    } else if (currentPathName?.startsWith("/wardrobe_list")) {
      targetBackPathName = '/interior_design'
    } else if (currentPathName?.startsWith("/template_overview_design")) {
      targetBackPathName = '/editor/design_model'
    } else if (currentPathName?.startsWith("/template_overview")) {
      if (searchParams.get("modifyInterior")) { // 从detail页面点击修改内配跳转到此页面
        return backFn()
      } else {
        targetBackPathName = '/wardrobe_list'
      }
    } else if (currentPathName?.startsWith("/editor/design_model")) {
      targetBackPathName = '/wardrobe_list'
    } else if (currentPathName?.startsWith("/editor") && currentSearch === 'fromRecommendList') {
      targetBackPathName = '/wardrobe_list'
    } else if (currentPathName?.startsWith("/editor")) {
      targetBackPathName = `/template_overview/${templateId}`
    } else if (currentPathName?.startsWith("/selectExteriorStyle")) {
      targetBackPathName = `/template_overview/${templateId}`
    } else if (currentPathName?.startsWith("/inspire")) {
      targetBackPathName = `/selectExteriorStyle/${templateId}?${currentSearch}`
    } else if (currentPathName?.startsWith("/poster")) {
      targetBackPathName = `/selectExteriorStyle/${templateId}`
    } else if (currentPathName?.startsWith("/detail")) {
      targetBackPathName = `/poster/${templateId}` 
    } else {
      return backFn()
    }
    return backFn(targetBackPathName)
  } else {
    return backFn()
  }
}

/**
 * 判断是否主流程：正常设计、重新设计（从banner进、重新设计按钮进）
 * @param pathname 路由的path
 * @returns 返回FROM_FLOW
 */
export const getFromFlow = (pathname: string) => {
  // const mainFlow = '/'
  const page = otherEntranceRouters.find(item => pathname?.startsWith(item))
  // if (pathname === mainFlow) { // 只要进入首页则认为是主流程
  //   return FROM_FLOW.MAIN_FLOW
  // } else if (page) {
  //   return FROM_FLOW.OTHER_FLOW
  // } else {
  //   return FROM_FLOW.MAIN_FLOW
  // }
  if (page) {
    return FROM_FLOW.OTHER_FLOW
  }
  return FROM_FLOW.MAIN_FLOW
}

/**
 * 判断来源页面：:扫码、分享、购物车跳转、我的设计跳转 
 * @param pathname 路由的path
 * @returns FROM_PAGE
 */
export const getFromPage = (pathname: string) => {
  const mainFlow = '/'
  const page = otherEntranceRouters.find(item => pathname?.startsWith(item))
  if (pathname === mainFlow) {
    return FROM_PAGE.MAIN_ENTRANCE
  } else if (page) {
    return otherEntranceMap[page]
  } else {
    return ''
  }
}
// fromFlow存入localStorage
export const saveFromFlowToSession = (pathname: string) => {
  const fromFlow = getFromFlow(pathname)
  if (fromFlow) localStorage.setItem(STORAGE_KEYS.FROM_FLOW, fromFlow)
}

// fromPage存入localStorage
export const saveFromPageToSession = (pathname: string) => {
  const fromPage = getFromPage(pathname)
  if (fromPage) localStorage.setItem(STORAGE_KEYS.FROM_PAGE, fromPage)
}

export const isLeadsPage = (url: string) => {
  const leadUrls = ['/leads',
    '/custom_landingpage',
    '/wrd_landingpage',
    '/reserved'
  ]
  return leadUrls.some(item => url.includes(item))
}