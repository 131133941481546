import styles from "./NoData.module.scss";
import noData from "../../assets/icons/no_data.svg"

interface IProps {
  text?: string;
}

const NoData: React.FC<IProps> = (props) => {
  const { text } = props
  return (
    <div className={styles.noData}>
      <img className={styles.pic} src={noData} alt={'noData'} />
      {text && <div className={styles.text}>{text}</div>}
    </div>)
}

export default NoData;
