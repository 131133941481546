import './Mask.scss'
import classNames from 'clsx'
import React, { CSSProperties, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

interface ElementProps<S extends string = never> {
    className?: string
    style?: CSSProperties & Partial<Record<S, string>>
}

export type MaskProps = {
    visible?: boolean
    onMaskClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    destroyOnClose?: boolean
    opacity?: 'default' | 'dark' | number
    getContainer?: HTMLDivElement | HTMLElement | null | undefined
    afterClose?: () => void
} & ElementProps

function useInitialized(check?: boolean) {
    const initializedRef = useRef(check)
    if (check) {
        initializedRef.current = true
    }
    return !!initializedRef.current
}

export const Mask: React.FC<MaskProps> = props => {

    const cls = classNames('admMask', props.className, {
        'admMaskHidden': !props.visible,
    })
    const initialized = useInitialized(props.visible)

    function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (e.currentTarget === e.target) {
            props.onMaskClick?.(e)
        }
    }

    const opacity =
        props.opacity === 'default'
            ? 0.55
            : props.opacity === 'dark'
                ? 0.75
                : props.opacity

    const node = (
        <CSSTransition
            in={props.visible}
            timeout={200}
            classNames={'admMask'}
            onExited={props.afterClose}
            unmountOnExit={props.destroyOnClose}
        >
            <div
                className={cls}
                onClick={handleClick}
                style={{
                    ...props.style,
                    backgroundColor: `rgba(0, 0, 0, ${opacity})`,
                }}
            >
                {initialized && props.children}
            </div>
        </CSSTransition>
    )

    if (props.getContainer) {
        const container = props.getContainer
        return createPortal(node, container)
    }
    return node
}

Mask.defaultProps = {
    visible: true,
    destroyOnClose: false,
    opacity: 'default'
}
