import { useState, useMemo, useEffect } from "react";
// import { getStoreList, getofferDetailList } from '@/apis/offer';
import { getAllStores } from "@/api/commonRequest";

// 获取商场列表
export const useStore = () => {
  const [storeList, setStoreList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getAllStores()
      .then((res: any) => {
        if (res) {
          const classificatedData: any = [];
          const cityList: any = {};
          res.forEach((item: any) => {
            if (cityList[item.city] === undefined) {
              cityList[item.city] = classificatedData.length;
              classificatedData.push([item]);
            } else {
              classificatedData[cityList[item.city]].push(item);
            }
          });
          setStoreList(classificatedData);
        } else {
          return Promise.reject(new Error("Error"));
        }
      })
      .catch((err: any) => {
        setError(err);
      });
  }, []);

  return { storeList, error };
};
