import clsx from 'clsx';
import Styles from './PositionTip.module.scss';
import aiPng from "@/assets/icons/ai.png";

interface IPositionTipProps {
  className?: string;
  children?: JSX.Element;
}

function PositionTip(props: IPositionTipProps) {
  const { className = '', children } = props;

  return (
    <article className={clsx(Styles.container, { [className]: !!className })}>
      <div className={Styles.content}>
        { children }
      </div>
      <img className={Styles.avatar} src={aiPng} alt="avatar" />
    </article>
  )
}

export default PositionTip;
