
import NavBar from "@/components/Navbar/NavBar";
import React, { useCallback, useState } from "react";
import StepIcon2 from "../../assets/icons/step_2_3.svg";
import commonStyles from "@/common/Common.module.scss";
import styles from "./index.module.scss";
import clsx from 'clsx';
import NoData from "@/components/NoData/NoData";
import { IRecommend } from "@/common/WardrobeTypes";
import { structWardrobeWithProduct } from "@/utils/tool-3d";
import { PaxActionType, usePaxConfig } from "@/reducers/paxReducer";
import { useHistory } from "react-router-dom";
import WardrobeCard from "@/components/Interior/WardrobeCard";
import { IGetWardrobesPageParams, recommendFeedback } from "@/api/interiorRequest";
import { getWardrobesPage } from "@/api/interiorRequest";
import { SearchConfActionType, useConfig } from "@/reducers/searchConfReducer";
import { getCity } from "@/utils/access-tool";
import { BASE_SIZE, DoorType } from "@/common/CommonType";
import { Drawer, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import SelectWidth from "@/components/BasicInfo/SelectWidth";
import SelectHeight from "@/components/BasicInfo/SelectHeight";
import SelectDepth from "@/components/BasicInfo/SelectDepth";
import SelectColor from "@/components/BasicInfo/SelectColor";
import InteriorFilter from "@/components/InteriorFilter/InteriorFilter";
import Filter from "@/components/Interior/Filter";
import DownArrowIcon from "../../assets/icons/arrow_down.svg";
import UpArrowIcon from "../../assets/icons/arrow_up.svg";
import CloseIcon from '../../assets/icons/close.svg';
import { cloneDeep } from "lodash";

const RecommendTest: React.FC = () => {
  const { state, dispatch } = useConfig();
  const { dispatch: paxDispatcher } = usePaxConfig();
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [recommendData, setRecommendData] = useState<IRecommend[]>([])
  const { doorType } = state;
  const [width, setWidth] = useState<number>(BASE_SIZE.WIDTH)
  const [height, setHeight] = useState<number>(BASE_SIZE.HEIGHT)
  const [depth, setDepth] = useState<number>(BASE_SIZE.DEPTH)
  const [frameColor, setFrameColor] = useState<any>(BASE_SIZE.FRAME_COLOR)
  const [showSearchParams, setShowSearchParams] = useState(true)
  const [showAssess, setShowAssess] = useState(false)
  const [isAgree, setIsAgree] = useState(true)
  const [textValue, setTextValue] = useState('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [testMode, setTestMode] = useState('Y')
  const [position, setPosition] = useState<number>(0)
  const [templateId, setTemplateId] = useState('')
  const [trackId, setTrackId] = useState('')
  const [curWardrobe, setCurWardrobe] = useState<IRecommend | null>(null)
  const [evaluatedAllAgree, setEvaluatedAllAgree] = useState<boolean>(false)
  const [evaluatedAllNotAgree, setEvaluatedAllNotAgree] = useState<boolean>(false)
  // 修改门型
  const handleTestMode = (event: any) => {
    setTestMode(event.target.value)
    console.log(testMode)
  };
  // 修改测试模式
  const handleDoorType = (event: any) => {
    dispatch({
      type: SearchConfActionType.UpdateConfig,
      payload: Object.assign(state, { doorType: event.target.value })
    })
  };
  // 请求获取推荐结果
  const getWardrobeListData = useCallback(
    async (params?: Partial<IGetWardrobesPageParams>) => {
      const userStorageNeeds = state.needList.map((item) => {
        return {
          id: item.id,
          amount: "COMMON",
          important: !!item.important ? "HIGH" : "LOW",
        };
      });
      const cityInfo = (await getCity()) || {};

      const defaultParams = {
        userStorageNeeds: userStorageNeeds,
        dimension: {
          height: height * 10 || BASE_SIZE.HEIGHT * 10,
          mWidth: width * 10 || BASE_SIZE.WIDTH * 10,
          mMaxDepth: depth * 10 || BASE_SIZE.DEPTH * 10,
        },
        lastId: 0,
        combination: state.combination,// 组合需要存取store中，不能读取wardrobe里的
        doorType: state.doorType, // 这一步wardrobe中还没有门，只能用store中的doorType
        color: frameColor || BASE_SIZE.FRAME_COLOR,
        templateType: 1,
        family: state.peopleType,
        fulfillLevel: state.fulfillLevel,
        pageSize: 100,
        noNeedComponents: state.dislikeList,
        zipcode: cityInfo.zipcode,
        ranking: state.ranking,
        headers: {
          searchDebug: testMode === 'Y'
        }
      };
      setLoaded(false)
      setRecommendData([])
      const wardrobeList: IRecommend[] = await getWardrobesPage(
        Object.assign({}, defaultParams, params)
      );
      const outOfStockNum = wardrobeList.filter(
        (res) => res.stockStatus.isOutOfStock
      ).length;
      const outOfStockRate = outOfStockNum / wardrobeList.length;
      window.gio("track", "pageView", {
        page_var: "recommend_test",
        out_of_stock_rate_var: outOfStockRate,
      });
      setLoaded(true)
      wardrobeList.forEach(item => {
        item.agree = false
        item.notAgree = false
      })
      setRecommendData(wardrobeList)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, width, depth, height, frameColor, testMode]
  );
  // 点击卡片
  function handleClick(item?: IRecommend) {
    return;
    // if (item) {
    //   const newWardrobe = structWardrobeWithProduct(item?.wardrobeWithProduct)
    //   const newTemp = (newWardrobe ? Object.assign(item, { wardrobeWithProduct: newWardrobe }) : item)
    //   paxDispatcher({
    //     type: PaxActionType.Update,
    //     payload: {
    //       recommendTemplate: newTemp,
    //       editTemplate: newTemp,
    //       templateHasChanged: false
    //     },
    //   });
    //   history.push("/template_overview/" + item?.templateId);
    // } else {
    //   history.push(`/editor/design_model`);
    // }
  }
  // 点击搜索柜子
  const handleSearch = () => {
    setShowSearchParams(!showSearchParams)
    getWardrobeListData();
  };
  // 点击点赞或者不赞同按钮
  const handleAgree = (e: any, index: number, item: IRecommend | null, agree: boolean, fullScreen: boolean) => {
    setCurWardrobe(item)
    e.stopPropagation()
    setShowAssess(true)
    setIsAgree(agree)
    setIsFullScreen(fullScreen)
    setPosition(index)
    setTemplateId(item?.templateId || '')
    setTrackId(item?.trackId || recommendData[0]?.trackId || '')
  }
  // 点击关闭评价弹窗
  const closeAssess = () => {
    setShowAssess(false)
    setTextValue('')
  }
  // 评价后跟新数据
  const afterEvaluated = () => {
    if (!curWardrobe) { // 评价所有
      isAgree ? setEvaluatedAllAgree(true) : setEvaluatedAllNotAgree(true)
    } else { // 单个柜子评价
      recommendData.forEach(item => {
        if (item.templateId === curWardrobe.templateId) {
          isAgree ? item.agree = true : item.notAgree = true
        }
      })
      setRecommendData(cloneDeep(recommendData))
    }
  }
  // 保存评价 TODO：需要调取接口
  const handleSaveAssess = async () => {
    if (textValue.length < 5) return
    closeAssess()
    try {
      await recommendFeedback({
        position,
        remarks: textValue,
        score: isAgree ? 1 : 0,
        templateId: templateId,
        requestId: trackId
      })
      afterEvaluated()
    } catch (e) {

    }
  }
  // 输入评价
  const onChangeTextValue = (e: any) => {
    setTextValue(e.target.value)
  }
  // 已评价过的柜子的数量
  const evaluatedNum = () => {
    const list = recommendData.filter(item => {
      return (item.agree || item.notAgree)
    })
    return list.length
  }


  const cardItem = (item: IRecommend, index: number) => {
    return (
      <div
        className={styles.item}
        onClick={() => handleClick(item)}
      >
        <WardrobeCard
          template={item}
        />
        <div className={styles.gesture}>
          <span className={clsx(styles.gestureDown, { [styles.active]: item.notAgree })} onClick={(e) => { handleAgree(e, index, item, false, false) }}>👎</span>
          <span className={clsx(styles.gestureUp, { [styles.active]: item.agree })} onClick={(e) => { handleAgree(e, index, item, true, false) }}>👍</span>
        </div>
      </div>
    )
  }
  return (
    <div className={commonStyles.Container} style={{ padding: "0" }}>
      <div className={commonStyles.Header}>
        <NavBar img={StepIcon2} text={"测试内配方案"} />
      </div>
      <div className={clsx(styles.filterWrap, { [styles.filterWrapShow]: showSearchParams })}>
        <div className={styles.filterContainer}>
          <div className={styles.filterBaseItem}>
            <FormControl>
              <FormLabel>测试模式:</FormLabel>
              <RadioGroup row value={testMode} onChange={handleTestMode}>
                <FormControlLabel value={'Y'} control={<Radio />} label="是" />
                <FormControlLabel value={'N'} control={<Radio />} label="否" />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>门型:</FormLabel>
              <RadioGroup row value={doorType} onChange={handleDoorType}>
                <FormControlLabel value={DoorType.HINGE} control={<Radio />} label="合页门" />
                <FormControlLabel value={DoorType.SLIDING} control={<Radio />} label="滑门" />
                <FormControlLabel value={DoorType.NONE} control={<Radio />} label="无门" />
              </RadioGroup>
            </FormControl>
            <SelectWidth width={width} setWidth={setWidth} />
            <SelectHeight height={height} setHeight={setHeight} />
            <SelectDepth depth={depth} setDepth={setDepth} />
            <SelectColor
              frameColor={frameColor}
              setFrameColor={setFrameColor}
              width={width}
              height={height}
              depth={depth}
            />
          </div>
          <div className={commonStyles.BodyOverflowHidden}>
            <InteriorFilter />
          </div>
          {width <= 300 && <Filter isTest={true} width={width} />}
        </div>
        {showSearchParams && <div className={styles.searchBtn} onClick={handleSearch}>搜索</div>}
        {showSearchParams && !!recommendData?.length &&
          <div className={styles.downUpBtn} onClick={() => { setShowSearchParams(!showSearchParams) }}>
            <img src={UpArrowIcon} alt="" />
          </div>}
        {!showSearchParams &&
          <div className={styles.downUpBtn} onClick={() => { setShowSearchParams(!showSearchParams) }}>
            <img src={DownArrowIcon} alt="" />
          </div>}
      </div>

      <div className={`${commonStyles.Body} ${styles.Body}`} id="WardrobeListWrap">
        {!!recommendData?.length && !showSearchParams &&
          <div className={styles.evaluated}>已评价<span className={styles.num}>{evaluatedNum()}</span>个柜子</div>
        }
        <div className={styles.wardrobeItem}>
          <div className={styles.line1}>
            {recommendData &&
              !!recommendData.filter((d, i) => i % 2 === 0).length &&
              recommendData.filter((d, i) => i % 2 === 0).map((item: IRecommend, index: number) => {
                return (
                  <div key={index}>
                    {cardItem(item, 2 * index)}
                  </div>
                );
              })}
          </div>
          <div className={styles.line2}>
            {recommendData &&
              !!recommendData.filter((d, i) => i % 2 === 1).length &&
              recommendData.filter((d, i) => i % 2 === 1).map((item: IRecommend, index: number) => {
                return (
                  <div key={index}>
                    {cardItem(item, 2 * index + 1)}
                  </div>
                );
              })}
          </div>
          {recommendData && !!recommendData.length && (
            <div className={styles.last}>我是有底线的</div>
          )}
          {recommendData && !recommendData.length && loaded && !showSearchParams && (
            <div>
              <NoData text={"没有相关商品"} />
            </div>
          )}
        </div>
      </div>
      <Drawer anchor={'bottom'} open={showAssess} onClose={() => closeAssess()}>
        <div className={styles.assessWarp}>
          <img src={CloseIcon} className={styles.closeIcon} alt="logo" onClick={closeAssess} />
          <div>{`请输入您${isAgree ? '认可' : '不认可'}${isFullScreen ? '整体推荐' : '此柜'}的评价：`}</div>
          <textarea
            className={styles.textInput}
            placeholder={'请输入您的评价(至少5个字以上哦)~~'}
            value={textValue}
            onChange={(e) => { onChangeTextValue(e) }}
          ></textarea>
          <div className={clsx(styles.searchBtn, styles.searchAssessBtn, { [styles.useful]: textValue.length >= 5 })} onClick={handleSaveAssess}>提交评价</div>
        </div>
      </Drawer >
      {!showSearchParams && <div className={styles.gestureWrap}>
        <div className={styles.gesture}>
          <span className={clsx(styles.gestureDown, { [styles.active]: evaluatedAllNotAgree })} onClick={(e) => { handleAgree(e, 0, null, false, true) }}>👎</span>
          <span className={clsx(styles.gestureUp, { [styles.active]: evaluatedAllAgree })} onClick={(e) => { handleAgree(e, 0, null, true, true) }}>👍</span>
          
        </div>
      </div>}
    </div>
  );
}

export default RecommendTest;
