import whitePng from '../assets/images/frame_white_new.png';
import darkPng from '../assets/images/frame_dark_brown_new.png';
import beigePng from '../assets/images/frame_beige_new.png';
import brownPng from '../assets/images/frame_brown_new.png';
import beigeYellow from "../assets/images/frame_beige_yellow.png"
import darkGrey from "../assets/images/frame_dark_grey.png"

import stitchingFrameWhiteImg from '../assets/images/stitching_frame_white.svg';
import stitchingFrameMetalImg from '../assets/images/stitching_frame_metal.svg';
import stitchingFrameBlackImg from '../assets/images/stitching_frame_black.svg';

export interface IFrameColor {
  id: number;
  colorName: string;
  displayName: string;
  colorPic: string;
}

export const FrameColorList: IFrameColor[] = [
  {
    id: 1,
    colorName: 'WHITE',
    displayName: '白色',
    colorPic: whitePng,
  },
  {
    id: 2,
    colorName: 'BEIGE',
    displayName: '仿橡木纹',
    colorPic: beigePng,
  },
  {
    id: 5,
    displayName: "米灰色",
    colorName: 'BEIGE_YELLOW',
    colorPic: beigeYellow
  },
  {
    id: 6,
    displayName: "深灰色",
    colorName: 'DARK_GREY',
    colorPic: darkGrey
  },
  {
    id: 3,
    colorName: 'BROWN',
    displayName: '褐色',
    colorPic: brownPng,
  },
  {
    id: 4,
    colorName: 'DARK_BROWN',
    displayName: '黑色',
    colorPic: darkPng,
  }
];

export const StitchingFrameColorList: IFrameColor[] = [
  {
    id: 1,
    colorName: 'METAL',
    displayName: '金属',
    colorPic: stitchingFrameMetalImg,
  },
  {
    id: 2,
    colorName: 'WHITE',
    displayName: '白色',
    colorPic: stitchingFrameWhiteImg,
  },
  {
    id: 3,
    colorName: 'BLACK',
    displayName: '黑色',
    colorPic: stitchingFrameBlackImg,
  }
];