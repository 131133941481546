import Toast  from "./index"
import styles from "./ConfirmToast.module.scss"
import clsx from "clsx"


interface IConfirmToastProps {
  onConfirm?: () => void
  onCancel?: () => void
}

function showConfirmToast(props: IConfirmToastProps) {
  const { onConfirm, onCancel } = props
  Toast.show({
    contentClassName: styles.toast,
    duration: 0,
    content: <div className={styles.confirmBox}>
      <div className={styles.content}>
        网络请求错误失败，是否要重试
      </div>
      <div className={styles.btnBox}>
        <div className={styles.btn} onClick={() => {
          onCancel && onCancel()
        }}>取消</div>
        <div className={clsx(styles.btn, styles.confirm)} onClick={() => {
          onConfirm && onConfirm()
        }}>确定</div>
      </div>
    </div>
  })
}

export default showConfirmToast
