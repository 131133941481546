import { DoorType, STORAGE_KEYS } from "@/common/CommonType";
import { IWardrobeWithProduct } from "@/common/WardrobeTypes";


class TemplateTool {

  template: IWardrobeWithProduct | undefined;

  /**
   * 
   * @param template 非主流程跳转我们的页面需要传入template，主流程直接从localStorage中获取
   */
  constructor(template?: IWardrobeWithProduct) {
    this.updateTemplate(template)
  }

  /**
   * 
   * @param template 非主流程跳转我们的页面需要传入template，主流程直接从localStorage中获取
   */
  updateTemplate(template?: IWardrobeWithProduct) {
    if (template) {
      this.template = template
    } else {
      const jsonString = localStorage.getItem(STORAGE_KEYS.PAX_GLOBAL_DATA)
      if (jsonString) {
        const stateJson = JSON.parse(jsonString)
        this.template = stateJson?.recommendTemplate?.wardrobeWithProduct
      }
    }
  }
  // 获取柜型
  get wardrobeType() {
    return this.template?.wardrobeType || ''
  }
  // 获取柜子宽度
  get wardrobeWidth() {
    return this.template?.totalLength ? this.template?.totalLength / 10 : 0
  }
  // 获取柜子高度
  get wardrobeHeight() {
    return this.template?.height ? this.template?.height / 10 : 0
  }
  // 获取柜子深度
  get wardrobeDepth() {
    return this.template?.depth ? this.template?.depth / 10 : 0
  }
  // 获取柜体颜色
  get wardrobeFrameColor() {
    return this.template?.color || ''
  }
  // 获取柜体组合
  get wardrobeCombination() {
    return this.template?.combination || ''
  }
  // 获取门类型
  get wardrobeDoorType() {
    if (!!this.template?.hingeDoors?.length) {
      return DoorType.HINGE
    } else if (this.template?.slidingDoors?.length) {
      return DoorType.SLIDING
    } else {
      return DoorType.NONE
    }
  }

  // get wardrobeDoor() {

  // }

  // get wardrobeKnob() {

  // }

}

export default TemplateTool;
