import { IDetailData, IDetailInfo, IProducts, IPurchaseList } from 'common/Detail';
import { IWardrobeWithProduct } from "common/WardrobeTypes";
import { ITemplateImgs } from "common/Detail";
import { getVps } from "@/api/detailRequest"


export function getDetailInfo(purchaseList: IPurchaseList): IDetailInfo {
  const size = purchaseList && purchaseList.templateSize.middle;
  const { width = 0, height = 0, depth = 0 } = size || {};

  const { pickupProducts = [], deliveryProducts = [] } = purchaseList || {}
  const productList = pickupProducts.concat(deliveryProducts)


  const detailInfo: IDetailInfo = {
    color: purchaseList?.templateColor,
    sizeDescription: '宽 ' + width / 10 + ' x 深 ' + depth / 10 + ' x 高 ' + height / 10 + ' 厘米',
    priceObj: {
      price: purchaseList?.totalDeliveryProductsPrice + purchaseList?.totalPickupProductsPrice,
      promoPrice: purchaseList?.saleDeliveryPromoPrice + purchaseList?.salePickPromoPrice,
      promoPriceDiff:purchaseList?.salePromoPriceDiff + purchaseList?.salePromoPickPriceDiff,
    },
    designCode: purchaseList?.designCode,
    productList: productList,
    productNum: getAllNum(productList),
    outOfStockProducts: getAllOutOfStock(productList),
    outOfStockProductsNum: getAllOutOfStockNum(productList),
    size: {
      width: width / 10,
      depth: depth / 10,
      height: height / 10
    },
  }
  return detailInfo;
}

export const getAllOutOfStock = (productList: IProducts[]) => {
  return productList.reduce((products: IProducts[], curr: IProducts) => {
    if (curr.outOfStock && curr.outOfStock > 0 && curr.outOfStock !== 4) {
      products.push(curr)
    }
    return products
  }, [] as IProducts[])
}

export const getAllOutOfStockNum = (productList: IProducts[]) => {
  return productList.reduce((acc, curr: IProducts) => {
    if (curr.outOfStock && curr.outOfStock > 0 && curr.outOfStock !== 4) {
      return acc + curr.amount
    }
    return acc
  }, 0)
}

export const getAllNum = (productList: IProducts[]) => {
  return productList.reduce((acc, curr) => {
    return acc + curr.amount
  }, 0)
}

export const getCombination = (comb: string) => {
  const combArr = comb.split(",");
  return combArr
    .map((item) => {
      return Number(item) / 10;
    })
    .join("+");
};
/**
 * 是否高低柜
 */
export const isDiffHeightWardrobe = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  if (!wardrobeWithProduct) return false;
  const { frames = [] } = wardrobeWithProduct;
  if (frames.length === 0) return false;
  const h = frames[0].height;
  for (let i = 0; i < frames.length; i++) {
    if (frames[i].height === h) {
      continue;
    } else {
      return true;
    }
  }
  return false;
}
/**
 * 是否深浅柜
 */
export const isDiffDepthWardrobe = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  if (!wardrobeWithProduct) return false;
  const { frames = [] } = wardrobeWithProduct;
  if (frames.length === 0) return false;
  const d = frames[0].depth;
  for (let i = 0; i < frames.length; i++) {
    if (frames[i].depth === d) {
      continue;
    } else {
      return true;
    }
  }
  return false;
}
/**
 * 是否3M以上的柜子
 */
export const isWidth3000Plus = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  if (!wardrobeWithProduct) return false;
  const { totalLength } = wardrobeWithProduct;
  return totalLength > 3000;
}
/**
 * 是否混合门衣柜
 */
export const isMixDoorWardrobe = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  if (!wardrobeWithProduct) return false;
  const { hingeDoors = [], slidingDoors = [] } = wardrobeWithProduct;
  return hingeDoors.length !== 0 && slidingDoors.length !== 0;
}
/**
 * 是否超过3米宽的滑门衣柜
 */
export const isWidth3000PlusWardrobe = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  if (!wardrobeWithProduct) return false;
  const { hingeDoors = [], slidingDoors = [], totalLength } = wardrobeWithProduct;
  return hingeDoors.length === 0 && slidingDoors.length > 0 && totalLength > 3000;
}
/**
 * 汇总特殊情况
 */
export const isSpecialSense = (wardrobeWithProduct: IWardrobeWithProduct): boolean => {
  return isDiffHeightWardrobe(wardrobeWithProduct) || isDiffDepthWardrobe(wardrobeWithProduct) || isMixDoorWardrobe(wardrobeWithProduct) || isWidth3000PlusWardrobe(wardrobeWithProduct);
}
 // 轮播图数据组合
export const getImageList = (detailData?: IDetailData) => {
  const purchaseList = detailData && detailData.purchaseList;
  if (purchaseList) {
    const list =
      (purchaseList.templateImgs &&
        purchaseList.templateImgs.map((item: ITemplateImgs) => {
          return {
            id: item.id,
            url: item.assetUrl,
            imageType: item.imageType,
          };
        })) ||
      [];
    const imageTypeObjects = [{
      type: 'DOOR_CLOSED',
      sort: 1
    }, {
      type: 'POSITIVE_DOOR_CLOSED',
      sort: 2
    }, {
      type: 'HAVE_CLOTHES',
      sort: 3
    }, {
      type: 'POSITIVE_HAVE_CLOTHES',
      sort: 4
    }, {
      type: 'POSITIVE_HAVE_NOT_CLOTHES',
      sort: 5
    }]
    const imageTypes = imageTypeObjects.map(obj => obj.type)
    const filterList = list.filter((imgItem) =>
      imageTypes.includes(imgItem.imageType)
    );
    const newFilterList = filterList.sort((a, b) => {
      const firstSort = imageTypeObjects.find(t => t.type === a.imageType)?.sort || imageTypeObjects.length + 1;
      const secSort = imageTypeObjects.find(t => t.type === b.imageType)?.sort || imageTypeObjects.length + 1;
      return firstSort - secSort
    })
    return newFilterList
  }
  return [];
};

export const shouldShow3D = (detailData: IDetailData) => {
  // 无门
  if (!detailData.wardrobeWithProduct?.hingeDoors?.length && !detailData.wardrobeWithProduct?.slidingDoors?.length) {
    return false;
  }
  // 合页门是玻璃门,就不显示3D
  try {
    if (detailData.wardrobeWithProduct.hingeDoors && detailData.wardrobeWithProduct.hingeDoors.length !== 0) {
      // online的特殊性,门都是一致的
      const hd = detailData.wardrobeWithProduct?.hingeDoors[0].doors[0];
      // 以下componentId是和星平一起筛查的有玻璃的门板,暂时没有判断依据,持续更新
      return hd?.componentId && ![109, 1139, 442, 530, 901, 629, 646, 71].includes(hd?.componentId);
    }
    if (detailData.wardrobeWithProduct.slidingDoors && detailData.wardrobeWithProduct.slidingDoors.length !== 0) {
      const stitchingPanelArr = detailData.wardrobeWithProduct.slidingDoors[0].components.filter(item => item.componentType === 'STITCHING_PANEL');
      return !stitchingPanelArr.some(panel => {
        return panel.componentId && [230, 234, 189, 235, 231, 238, 236, 232, 190, 237, 239, 233].includes(panel.componentId);
      });
    }
  } catch (e) {
    console.log(e);
    return false;
  }
  return true;
}

export const hasDoor = (wardrobeWithProduct:IWardrobeWithProduct) => {
  if (!wardrobeWithProduct?.hingeDoors?.length && !wardrobeWithProduct?.slidingDoors?.length) {
    return false;
  }
  return true
}

// 判断柜子每个frame都有门
export const everyFrameWithDoor = (wardrobeWithProduct: IWardrobeWithProduct) => {
  let noDoor = false;
  // ending frame是不能装门的，排除在外
  const canAddDoorFrames = wardrobeWithProduct.frames || [];
  for (let i = 0; i < canAddDoorFrames.length; i++) {
    const curFrame = canAddDoorFrames[i]
    const hingeDoor = wardrobeWithProduct.hingeDoors?.find(door => door.frameId === curFrame.id)
    const slidingDoor = wardrobeWithProduct.slidingDoors?.find(door => door.frameIds.indexOf(curFrame.id) > -1)
    if (!hingeDoor && !slidingDoor) {
      noDoor = true
      break;
    }
    // 如果有合页门,但是总的宽度和柜体宽度不一样,比如有1000的柜体只装了500的,也认为没有安装门
    const hingeDoorTotalWidth = hingeDoor?.doors.reduce((sum, d) => sum + d.occupiedWidth, 0) || 0;
    if (!slidingDoor && hingeDoor && Math.abs(curFrame.width - hingeDoorTotalWidth) > 30) {
      noDoor = true
      break;
    }
  }
  return !noDoor;
}


interface BeforLoginOrder {
  orderTime: number,
  templateId: number,
  uniqueCode: string,
  params: {
    productFullId: string,
    quantity: number
  }[]
}
const beforLoginOrder = 'beforeLoginOrder'

export const setCurrentOrder = async (templateId: number, uniqueCode: string, products: IProducts[]) => {
  const result: { vpsId: string } = await getVps({
    templateId,
    uniqueCode,
  });
  if (result && result.vpsId) {
    const value: BeforLoginOrder = {
      orderTime: new Date().getTime(),
      templateId,
      uniqueCode,
      params: [{
        productFullId: `v${result.vpsId}`,
        quantity: 1
      }, ...products.map(item => {
        return {
          productFullId: item.productNo,
          quantity: item.amount
        }
      })]
    }
    sessionStorage.setItem(beforLoginOrder, JSON.stringify(value))
  }
}

export const checkBeforeLoginOrder = async (templateId: number, uniqueCode: string, cb: (order: BeforLoginOrder) => void ) => {
  const params: BeforLoginOrder | null = JSON.parse(sessionStorage.getItem(beforLoginOrder) || '');
  if (params) {
    if (new Date().getTime() - params.orderTime > 1000 * 60 * 3) return
    if (Number(templateId) !== Number(params.templateId)) return
    if (uniqueCode !== params.uniqueCode) return
    cb(params)
  }
}