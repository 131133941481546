import PAXGlobal from "@/utils/modelCache";
import { SlimFrame } from "@/common/CommonType";

export function getPersistKey(frame: SlimFrame) {
    const { frameColor, width, height, depth } = frame;
    return `${frameColor.toLowerCase()}-${width}-${height}-${depth}.glb`;
}

/**
 * 从url中获取base path作为indexDB的key
 * @param url
 * @returns
 */
export function getPureUrl(key: string) {
    const [frameColor, width, height, depth] = key.replace('.glb', '').split('-');
    console.log('------obj', { frameColor, width, height, depth });
    console.log('-----PAXGlobal.frames', PAXGlobal.frames);

    const target = PAXGlobal.frames.concat(PAXGlobal.presetFrames).find(frame => {
        return frame.frameColor.toLowerCase() === frameColor.toLowerCase()
            && frame.width === +width
            && frame.height === +height
            && frame.depth === + depth
    })
    console.log('----target', target);
    return target?.modelUrl ?? '';
}

/**
 * 从url中获取本地Url
 * @param url
 * @returns
 */
export function getLocalModelUrl(key:string) {
    const pureUrl = getPureUrl(key) || key;
    console.log('-----pureUrl', pureUrl);
    if (PAXGlobal.modelCacheStatus.get(key)) {
        return `file:${key}`
    }
    return pureUrl;
}
