import Price, { PriceType } from "../Price/Price";
import styles from "./WardrobeCard.module.scss";
import {
  useInterior,
  ACTION_TYPES
} from "../../reducers/interiorReducer";
import { IRecommend } from "../../common/WardrobeTypes";
import {
  deleteDesign,
} from "../../api/interiorRequest";
import PaxRender from "../PaxRender/PaxRender";
import { useState } from "react";
import RegularModal from "../Modal/RegularModal";
import { getCombination } from "../../utils/detail-tool";
import EditIcon from "@/assets/icons/edit_icon.svg"
import { useHistory } from "react-router-dom";
import qs from 'query-string';
import { PaxActionType, usePaxConfig } from "@/reducers/paxReducer";
import { cloneDeep } from "lodash";
import { structWardrobeWithProduct } from "@/utils/tool-3d";
import { getWardrobeExtraInfo } from "@/api/autoFillApis";
import { chooseTemplateGio } from "@/hooks/gio";


interface IProps {
  template: IRecommend;
  index?: number;
  editable?: boolean;
  justDesign?: boolean;
  getFavoriteIdListData?: () => void;
  UIType?: 0 | 1;
}

const WardrobeCard: React.FC<IProps> = (props) => {
  const {
    template,
    justDesign,
    index,
    editable,
  } = props;
  const { dispatch } = useInterior();
  const [show, setShow] = useState(false);
  const history = useHistory()
  const { dispatch: paxDispatch } = usePaxConfig();
  const [height, setHeight ] = useState(206); 

  const deleteDesignFn = async (template: IRecommend) => {
    const deleteWardrobeId = {
      recordId: template.templateId,
    };
    await deleteDesign(deleteWardrobeId);
    dispatch({
      type: ACTION_TYPES.CANCEL_DESIGN,
      payload: { cancelDesign: deleteWardrobeId || {} },
    });
    closeFn();
  };

  const closeFn = () => {
    setShow(false);
  };

  const handleClick = async (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    const search = qs.stringify({
      fromRecommendList: true
    })
    const _tem = cloneDeep(template)
    const newWardrobe = structWardrobeWithProduct(_tem?.wardrobeWithProduct)
    const newTemp = (newWardrobe ? Object.assign(_tem, { wardrobeWithProduct: newWardrobe }) : _tem)
    getWardrobeExtraInfoData(newTemp)
    await chooseTemplateGio(template, index, 'editor');
    history.push({ pathname: `/editor/${newTemp.wardrobeWithProduct.id}`, search });
  }

  const getWardrobeExtraInfoData = async (editTemplate: IRecommend) => {
    if (editTemplate) {
      try {
        const res = await getWardrobeExtraInfo(editTemplate.wardrobeWithProduct)
        paxDispatch({
          type: PaxActionType.Update,
          payload: {
            editTemplate: Object.assign(editTemplate, res),
            recommendTemplate: Object.assign(editTemplate, res)
          }
        })
      } finally {

      }
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.wardrobe} style={{
        height: height
      }}>
        <PaxRender
          wardrobe={template.wardrobeWithProduct}
          showPropIn={true}
          lazyLoad={true}
          onRender={(domRect) => {
            domRect && setHeight(domRect.height)
          }}
        />
        {template.stockStatus && template.stockStatus.isOutOfStock && (
          <div className={styles.stock}>
            部分配件缺货
            {template.stockStatus.estimateDate && (
              <span>
                <br />
                {template.stockStatus.estimateDate}
              </span>
            )}
          </div>
        )}
        {justDesign && (
          <div className={styles.designTip}>您最近设计过的内配布局</div>
        )}
      </div>
      <div className={styles.bottom}>
        {!!template.templatePriceList[0].pricePromoDiff && (
          <div className={styles.promTag}>优惠价</div>
        )}
        {template.details && (
          <div className={styles.Info}>
            <p className={styles.text}>{template.details.join(" | ")}</p>
          </div>
        )}
        <div className={styles.combine}>
          {getCombination(template.wardrobeWithProduct.combination)} cm
        </div>
        <div className={styles.price}>
          <div className={styles.left}>
            <Price priceData={template.templatePriceList[0].salePromoPrice} />
            {!!template.templatePriceList[0].pricePromoDiff && (
              <div className={styles.originPrice}>
                <Price
                  type={PriceType.Invalid}
                  priceData={template.templatePriceList[0].price}
                />
              </div>
            )}
          </div>
          {
            !editable && (
              <div className={styles.right}>
                <img className={styles.editImg} src={EditIcon} alt='' onClick={(event) => { handleClick(event) }} />
              </div>
            )
          }
        </div>
      </div>
      <RegularModal
        open={show}
        title={"确定要删除您设计的衣柜内配布局吗？"}
        sureText={"我再想想"}
        cancelText={"确认删除"}
        closeFn={closeFn}
        sureFn={closeFn}
        disableBackdropClick={true}
        cancelFn={() => {
          deleteDesignFn(template);
        }}
      >
        {"这是您自己设计过的衣柜内配布局，删除后不可恢复"}
      </RegularModal>
    </div>
  );
};

export default WardrobeCard;
