import styles from "./QuitDesign.module.scss";
import { useState, useRef, useEffect } from "react";
import DesignerImage from "../../assets/images/modal_designer.png";
import  CustomImage from "../../assets/images/custom.png";
import { useConfig } from "@/reducers/searchConfReducer";
import { usePaxConfig } from "@/reducers/paxReducer";
import { useCSC } from "@/hooks";
import { BASE_SIZE, PLATFORM } from "@/common/CommonType";
import ikeaSDK from "@/utils/ikea-sdk";
import { useHistory } from "react-router-dom";
import CSCNoWorkTime from "../CSCNoWorkTime/CSCNoWorkTime";
import { InfoType } from "@/hooks/userInfo";
import ikea from "ikea-jssdk";
import RegularModal from "../Modal/RegularModal";
import CloseIcon from "../../assets/icons/close.svg";
import clsx from 'clsx';
import { getCurrentStep, supportSaveDesign } from "@/utils/common-tool";
import TemplateTool from "@/utils/template-tool";
import light from "@/assets/icons/light.svg"
import { getPageInfo } from "@/utils/common-tool"
// import { STORAGE_KEYS, FROM_PAGE,FROM_FLOW } from "@/common/CommonType";
import { GIO, GioModuleVar } from "@/utils/gioUtils";

import arrow from "@/assets/icons/arrow.svg"
//  import { Questionnaire  } from "@aid/business"
// import { AidQuestionnaire } from "@aidesign/store"
 import { getGioPlatform } from '@/utils/ikea-sdk'
import { GIO_Duration } from "@/utils/gioUtils";
// import { KDivider, KSelector } from "design-06k4"
import touchImg from '@/assets/images/poster/physical-touch.png';
import screenImg from '@/assets/images/poster/big-screen.png';
import helpImg from '@/assets/images/poster/coworker-help.png';

interface Props {
  title: string;
  tip?: string;
  cscCustomerText?: string;
  showExit?: boolean;
  showSurvey?: boolean;
  designCode?: string;
  open: boolean;
  templateId?: number;
  onClose: () => void;
  saveDesign?: (showToast: boolean) => Promise<"" | { status: number; data?: any; }>;
}


const SurveyParamsConfig = {
  interior_design: 'interior_design',
  wardrobe_list: 'wardrobe_list',
  template_overview: 'template_overview',
  selectExteriorStyle: 'selectExteriorStyle',
  poster: 'poster',
  detail: 'detail',
  index: 'customSize',
  editor: 'editor',
} as {
  [key: string] : string
}

function QuitDesign({
  title,
  tip,
  cscCustomerText,
  open,
  templateId,
  onClose,
  saveDesign,
  showExit,
  showSurvey,
  designCode,
}: Props) {
  const { state } = useConfig();
  const { state: paxState } = usePaxConfig();
  const surveyRef = useRef<{confirm: (cb: (questionnaire: string[], selected: string[], remark: string) => void) => void}>(null)
  // 获取柜子的相关信息
  const templateTool = new TemplateTool(paxState.recommendTemplate?.wardrobeWithProduct)
  const cscData = {
    designCode,
    doorType: state.doorType,
    width: templateTool.wardrobeWidth || BASE_SIZE.WIDTH,
    depth: templateTool.wardrobeDepth || BASE_SIZE.DEPTH,
    height: templateTool.wardrobeHeight || BASE_SIZE.HEIGHT,
    frameColor: templateTool.wardrobeFrameColor || BASE_SIZE.FRAME_COLOR,
    dislikeList: state.dislikeList,
    needList: state.needList
  };
  const { isWorkTime, jumpNewCSC } = useCSC(cscData, cscCustomerText);
  const [openCSC, setOpenCSC] = useState(false);
  const {
    state: { platform },
  } = usePaxConfig();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorTel, setShowErrorTel] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const href = window.location.href
  const [showTip] = useState(href.includes("selectExteriorStyle") || href.includes('template_overview'))
  const [tipStep] = useState(href.includes('selectExteriorStyle') ? 1 : 2)
  const [exitText, setExitText] = useState('直接退出')

  // const fromPage = localStorage.getItem(STORAGE_KEYS.FROM_PAGE)
  // const fromFlow = localStorage.getItem(STORAGE_KEYS.FROM_FLOW)

  const closeCSC = (closeDesignModal = false) => {
    setOpenCSC(false);
    if (closeDesignModal) onClose();
  };

  useEffect(() => {
    ikea.isLogin().then(res => {
      if (res.value) {
        setIsLogin(true)
      }
    })
  }, [])

  function handleContactDesigner() {
    if (!isWorkTime) {
      setOpenCSC(true);
      return;
    }
    GIO('track','clickData', {
      eventType_var: "click",
      module_var: GioModuleVar.questionnairePopup,
      objectType_var: "csc",
      objectName_var: "联系线上设计师"
    })
    jumpNewCSC(); // jumpNewCSC();
    onClose();
  }

  const handleExit = async (isSaveBtn = false) => {
    onClose();
    await surveyRef.current?.confirm(async (questionnaire: string[], selected: string[], remark: string) => {
      const gioPlatform = await getGioPlatform()
      const href = window.location.href
      const pageInfo = getPageInfo()
      const hasDesignCode = href.includes('detail') || href.includes('designList') || href.includes('post')
      window.gio('track', 'clickData', {
        'eventType_var': "click",
        'platform_var': gioPlatform,
        'pageType_var': pageInfo.pageType,
        'pageId_var': href,
        'pageName_var': pageInfo.pageName,
        'module_var': 'questionnaire_popup',
        'modulePosition_var': '-',
        'objectId_var': hasDesignCode ? window.sessionStorage.getItem("uniqueCode") || '-' : '-',
        'objectType_var': 'exit',
        'objectName_var':  isSaveBtn ? '退出并保存设计方案' : exitText,
        'attributeName1_var': 'question_var',
        'attributeValue1_var': questionnaire.join('-') || '-',
        'attributeName2_var': 'answer_var',
        'attributeValue2_var': (selected.join('-') + (remark ? '-' + remark : '')) || '-',
      })
    })
    // 主流程，并且页面来源是主入口进来的
    if ([PLATFORM.APP, PLATFORM.SMP].includes(platform)) {
      GIO_Duration()
      ikeaSDK.goBack();
    } else {
      history.replace("/");
    }
  };
  const exitAndSaveDesign = async () => {
    saveDesign && await saveDesign(false)
    handleExit(true)
  }

  // const getSurveyParams = () => {
  //   const currentKey = Object.keys(SurveyParamsConfig).find(item => window.location.href.includes(item)) || 'index'
  //   return SurveyParamsConfig[currentKey]
  // }

  return (
    <>
      <div className={styles.QuitDesignContainer}>
        <div className={styles.quitTitle}>
          <span>{title}</span>
          <img src={CloseIcon} className={styles.Icon} alt="logo" onClick={onClose} />
        </div>
        { showTip && <div className={styles.tip}><img src={light} alt="" /> <span>仅剩{tipStep}步</span>就能完成梦想的衣柜设计。</div> }
        <div className={styles.contactCsc}>
          <div className={styles.cscLeft}>
            <div className={styles.quitSubTitle}>
              {tip ? tip : "我们有线上专业设计师免费为您服务"}
            </div>
            <div
              className={clsx(styles.confirmBtn, styles.quitConfirmBtn)}
              onClick={handleContactDesigner} >
              联系线上设计师
              <img src={arrow} alt="" />
            </div>
          </div>
          <div className={styles.cscRight}>
            <img className={styles.quitDesigner} src={DesignerImage} alt={"designer"} />
          </div>
        </div>
        <div className={styles.custom}>
          <div className={styles.customLeft}>
            <div className={styles.desc}>
              选不到满意尺寸和布局？<br />定制衣柜服务满足更多个性需求。。
            </div>
            <div className={clsx(styles.confirmBtn, styles.customConfirmBtn)} onClick={() => {
              GIO('track', 'clickData', {
                module_var: GioModuleVar.questionnairePopup,
                objectName_var: '了解衣柜定制详情',
                objectType_var: 'exit',
              })
              history.push('/custom_landingpage?channel=PAX_AI&source=wardrobe_quit')
            }}>
              了解定制衣柜详情
              <img src={arrow} alt="" />
            </div>
            <div className={styles.tag}>
              全新宜家服务
            </div>
          </div>
          <div className={styles.customRight}>
            <img className={styles.customImage} src={CustomImage} alt={"custom"} />
          </div>
        </div>
        {/* {showSurvey && (
          <AidQuestionnaire onChange={(options, selected) => {
            if (selected.length > 0) {
              setExitText('提交并退出')
            } else {
              setExitText('直接退出')
            }
          }} title="请告诉我们退出工具的原因（可多选）" pageName={getSurveyParams()} ref={surveyRef}></AidQuestionnaire>
        )} */}
        <p className={styles.guideTitle}>或者你也可以去门店</p>
        <div className={styles.guideBox}>
          <img src={touchImg} alt="touchImg" />
          <img src={screenImg} alt="screenImg" />
          <img src={helpImg} alt="helpImg" />
        </div>
        <div className={styles.btnWrap}>
          {showExit && (
            <div className={styles.quitExitBtn} onClick={() => {handleExit()}}>
              {exitText}
            </div>
          )}
          {getCurrentStep() === 'step_detail' && supportSaveDesign(platform) && sessionStorage.getItem('hasOffline') !== 'true' && isLogin && <div className={styles.quitExitBtn} onClick={exitAndSaveDesign}>
            退出并保存设计方案
          </div>}
        </div>
      </div>
      <CSCNoWorkTime
        infoType={InfoType.NON_WORKING}
        openCSC={openCSC}
        templateId={templateId}
        hide={closeCSC}
        showSuccessAlert={() => {
          setShowSuccess(true);
        }}
        showErrorTelAlert={() => {
          setShowErrorTel(true);
        }}
      />
      <RegularModal
        open={showSuccess}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowSuccess(false);
        }}
        disableBackdropClick={true}
      >
        {"我们的线上专属客服会在近期联系您，请您耐心等候。"}
      </RegularModal>
      <RegularModal
        open={showErrorTel}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowErrorTel(false);
        }}
        disableBackdropClick={true}
      >
        {"您输入的号码格式错误，请输入正确的 11 位数电话号码。"}
      </RegularModal>
    </>
  );
}

export default QuitDesign;
