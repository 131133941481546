import { post } from './request'
import { IWardrobeWithProduct } from "../common/WardrobeTypes";
import { getCity } from '@/utils/access-tool'

export const getAutoFillFuncArea = async <T>(likeComponents: any, query: any) => {
  return await post<T>('/v2/autofillFuncAreas', { likeComponents, query }, { loadingText: '正在生成可替换的内配' })
}

export const getWardrobeExtraInfo = async <T>(wardrobeWithProduct: IWardrobeWithProduct, isSilence = true) => {
  return await post<T>('/v1/getWardrobeExtraInfo', { wardrobeWithProduct }, { isSilence })
}

export const getRecommendFrames = async <T>(params: any) => {
  const cityInfo = await getCity() || {};
  params.zipcode = cityInfo.zipcode;
  console.log('rf:params', params);
  return await post<T>('/v1/recommendFrames', { ...params }, { loadingText: '正在生成可替换的内配' })
}
