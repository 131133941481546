import { useRef, useEffect, MutableRefObject } from 'react';
import { useLocation } from 'react-router-dom';
import { usePaxConfig } from '@/reducers/paxReducer'

// token加载完毕
export const useTokenReady = (cb: () => void, dependecies?: Array<any>) =>{
  const { state: { tokenLoaded } } = usePaxConfig();
  useEffect(function () {
    if (tokenLoaded) {
      cb();
    }
  }, [tokenLoaded].concat(dependecies ?? [])); // eslint-disable-line
}

export const usePrevious = (value:any, cb: (prev: any, current: any) => void) => {
  const ref: MutableRefObject<any> = useRef('');
  useEffect(function(){
    cb&& cb(ref.current, value)
    ref.current = value;
  });
  return ref.current;
};

export function useQuery() {
  const searchStr = useLocation().search;
  const query = (new URLSearchParams(searchStr)) as any;
  return query;
}