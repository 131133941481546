import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grow from '@material-ui/core/Grow';
import styles from "./BaseModal.module.scss"
import CloseIcon from '../../assets/icons/close.svg';

interface Props {
  open: boolean,
  noCloseIcon?: boolean;
  onClose?: (event?: any, reason?: string) => boolean | void,
  children?: React.ReactNode;
}

const clickFn = (event: React.ChangeEvent<{}>) => {
  event.stopPropagation()
}

function BaseModal({ open, onClose, noCloseIcon, children }: Props) {
  return (
      <Modal
        className={styles.Modal}
        open={open}
      onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
        <div className={styles.Body} onClick={($event) => clickFn($event)}>
          {!noCloseIcon &&
            <div className={styles.IconBtn} onClick={(event) => onClose && onClose(event)}>
              <img src={CloseIcon} className={styles.Icon} alt="logo" />
            </div>}
            {children}
          </div>
        </Grow>
      </Modal>
  )
}

export default BaseModal