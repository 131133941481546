
import { clear, show } from './methods'
import showConfirmToast from "./ConfirmToasts"

export type { ToastShowProps } from './methods'

const Toast = {
  showConfirmToast,
  show,
  clear,
}

export default Toast