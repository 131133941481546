import Localforage from "localforage";
import { forEach } from 'lodash';
import { getPersistKey } from '@/utils/modelUrlParser';
import { getAllFrames } from '@/api/configAPIs';
import { FrameColor, SlimFrame } from '@/common/CommonType';

class ModelCache {
  static CONFIG_KEY = 'PAXGLOBAL_CONFIG';
  modelCacheStatus: Map<string, boolean> = new Map<string, boolean>();
  frames: Array<SlimFrame> = new Array<SlimFrame>();
  persistedMap: Map<string, boolean> = new Map<string, boolean>();

  get allFrames() {
    return this.frames || localStorage.getItem('ALL_FRAMES') || []
  }

  get presetFrames() {
    return ['/frames/100/white_236_58.glb'].map(pathName =>  {
      return {
        frameColor: FrameColor.WHITE,
        modelUrl: `${window.location.origin}${pathName}`,
        depth: 580,
        height: 2360,
        width: 1000
      }
    }) as Array<SlimFrame>;
  }

  updateModelCacheStatus(key: string, value: boolean) {
    this.modelCacheStatus.set(key, value);
    const json = {} as any;
    this.modelCacheStatus.forEach((value, key) => {
      json[key] = value;
    });
  }


  constructor() {
    this.initModelCacheStatus();
  }

  async initModelCacheStatus() {
    const keyList = await Localforage.keys();
    forEach(keyList, (value: string) => {
      this.updateModelCacheStatus(value, true);
    })
  }

  async startPersist(execPersist = false) {
    try {
      const allFrames = await getAllFrames({ headers: { storeId: 20 } }) as Array<SlimFrame>;
      window.ALL_FRAMES = allFrames || localStorage.getItem('ALL_FRAMES');
      localStorage.setItem("ALL_FRAMES", JSON.stringify(allFrames));
      const presetFramePersistKeyList = this.presetFrames.map(frame => getPersistKey(frame));
      this.frames = allFrames.filter(frame => {
        const currentFrameKey = getPersistKey(frame);
        return !presetFramePersistKeyList.includes(currentFrameKey)
      });
      if (execPersist) {
        await this.persistModel(this.frames);
      }
      return true
    } catch (e) {
      return false
    } finally {
    }
  }

  async presetFramePersist() {
    try {
      await this.persistModel(this.presetFrames)
      return true
    } catch (e) {
    } finally {
    }
  }

  async persistModel(frameList: Array<SlimFrame>) {
    try {
      let cachedModelUrlList = await Localforage.keys();
      const newList = frameList.filter(frame => {
        const key = getPersistKey(frame);
        return !cachedModelUrlList.includes(key);
      });
      if (newList.length > 0) {
        await this.batchPersistModel(newList);
      } else {
        console.log('-----using cached');
      }
      cachedModelUrlList = await Localforage.keys();
      cachedModelUrlList.forEach(value => this.updateModelCacheStatus(value, true));
    } catch (e) {
    } finally {
    }
  }


  /**
   * 请求单个模型并持久化到indexDB
   * @param modelUrl
   * @returns
   */
  async fetchAndPersistModel(frame: SlimFrame) {
    const key = getPersistKey(frame);
    let fileBlob: Blob | null = null;
    try {
     const result = await fetch(frame.modelUrl);
     if ([200, 304].includes(result.status)) {
      fileBlob = await result.blob();
     }
    } catch(e) {
      console.log(`---------load module error ${key}:`, frame.modelUrl);
    } finally {
      if (fileBlob && key) {
        await Localforage.setItem(`${key}`, fileBlob);
        this.updateModelCacheStatus(`${key}`, true);
      }
    }
  }
  /**
   * 清楚指定key的缓存标志和indexDB中的缓存内容
   * @param key
   */
  async removeModelCache(key?: string) {
    if (key) {
      this.modelCacheStatus.delete(key);
    } else {
      this.modelCacheStatus.clear();
    }
    await this.removeModelCacheIndexDB(key)
  }

  /**
   * 清除指定key的IndexDB中的缓存内容
   * @param key
   */
  async removeModelCacheIndexDB(key?: string) {
    if (key) {
      await Localforage.removeItem(key)
    } else {
      await Localforage.clear();
    }
  }

  async batchPersistModel(frameList: Array<SlimFrame>) {
    const batchCount = 10;
    const len = frameList.length;
    const list = frameList.slice();
    let percentage = 0;
    while(list.length > 0) {
      const batchList = list.splice(-batchCount);
      const promiseList = batchList.map(frame => this.fetchAndPersistModel(frame));
      await Promise.all(promiseList);
      percentage = (len - list.length) * 1.0 / len;
      console.log('-----percentage', percentage);
    }
  }
}

const modelCache = new ModelCache();
window.modelCache = modelCache;
export default modelCache;
