import styles from "./Common.module.scss";
import { useCallback, useEffect, useState } from "react";
import FrameWhite from "../../assets/images/frame_white_new.png"
import FrameBeige from "../../assets/images/frame_beige_new.png"
import FrameBrown from "../../assets/images/frame_brown_new.png"
import FrameDarkBrown from "../../assets/images/frame_dark_brown_new.png"
import beigeYellow from "../../assets/images/frame_beige_yellow.png"
import darkGrey from "../../assets/images/frame_dark_grey.png"
import { FrameColor } from "../../common/CommonType";
import { find, filter } from "lodash";
import { usePaxConfig } from "../../reducers/paxReducer";
import classnames from 'clsx';

const initColorList = [
  {
    id: FrameColor.WHITE,
    image: FrameWhite,
    text: "白色",
    enable: true
  },
  {
    id: FrameColor.BEIGE,
    image: FrameBeige,
    text: "仿橡木纹",
    enable: true
  },
  {
    id: FrameColor.BEIGE_YELLOW,
    image: beigeYellow,
    text: "米灰色",
    enable: true
  },
  {
    id: FrameColor.DARK_GREY,
    image: darkGrey,
    text: "深灰色",
    enable: true
  },
  {
    id: FrameColor.BROWN,
    image: FrameBrown,
    text: "褐色",
    enable: true
  },
  {
    id: FrameColor.DARK_BROWN,
    image: FrameDarkBrown,
    text: "黑色",
    enable: true
  },
 
]


interface IColorOption {
  id: FrameColor,
  image: string,
  text: string,
  enable: boolean
}
interface IProps {
  frameColor: FrameColor;
  setFrameColor: (color: FrameColor) => void;
  width: number;
  height: number;
  depth: number;
}

function SelectColor(props: IProps) {
  const { frameColor, setFrameColor, width, height, depth } = props
  const { state: paxState } = usePaxConfig()
  const [colorList, setColorList] = useState<IColorOption[]>(initColorList)

  const checkColor = useCallback(() => {
    const colorMap = find(paxState.availableFrameColors, {
      "height": "HEIGHT_" + height,
      "depth": "DEPTH_" + depth,
    })
    const colors = colorMap ? colorMap.colors : ["WHITE", "BEIGE", "beige_yellow" ,"dark_grey"]
    const newArr = [...initColorList]
    for (const c of newArr) {
      c.enable = colors.indexOf(c.id) >= 0
    }
    const currentOption = find(newArr, { id: frameColor })
    if (currentOption && !currentOption.enable) {
      const enableList = filter(newArr, { enable: true })
      if (enableList && enableList.length > 0) {
        setFrameColor(enableList[0].id)
      }
    }
    setColorList(newArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paxState.availableFrameColors, height, depth])

  useEffect(() => {
    if (paxState.availableFrameColors.length <= 0) {
      return
    }
    checkColor()
  }, [paxState.availableFrameColors, checkColor])

  function isOutOfStock(color: FrameColor): boolean {
    if (paxState.outOfStockFrames.length <= 0) {
      return false
    }
    const colorMap = find(paxState.outOfStockFrames, {
      "color": color,
      "height": "HEIGHT_" + height,
      "depth": "DEPTH_" + depth,
    })
    if (colorMap === undefined) {
      return false
    }
    if (colorMap.widths.indexOf(500) >= 0 && (width === 50 || width >= 50 + 50)) {
      return true
    }
    if (colorMap.widths.indexOf(750) >= 0 && (width === 75 || width >= 75 + 50)) {
      return true
    }
    if (colorMap.widths.indexOf(1000) >= 0 && (width === 1000 || width >= 1000 + 50)) {
      return true
    }
    return false;
  }

  function handleClick(c: FrameColor) {
    setFrameColor(c)
  }

  return (
    <div className={classnames(styles.Container, styles.onePagePlan)}>
      <div className={styles.label}>
        <span>柜体颜色</span>
      </div>
      <div className={styles.optionWrap}>
        {colorList.map(option => {
          if (option.enable) {
            return (
              <div
                key={option.id}
                style={{ height: 'auto' }}
                className={`${styles.ColorOption} ${frameColor === option.id ? styles.optionActive : ""}`}
                onClick={() => handleClick(option.id)}
              >
                  <img src={option.image} className={styles.ColorImage} alt="color"/>
                {isOutOfStock(option.id) && <div className={styles.OutOfStock}>可能缺货</div>}
                {/* <span className={styles.ColorText}>{option.text}</span> */}
                { (option.id === FrameColor.DARK_GREY || option.id === FrameColor.BEIGE_YELLOW) && <span className={styles.newProductTip}>新品</span>}
                <div style={{ fontSize: '12px' }}>{ option.text }</div>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

export default SelectColor;



