import styles from "./Designer.module.scss";
import { useState } from "react";
import BaseModal from "./BaseModal";
import DesignerImage from "../../assets/images/modal_designer.png";
import { useConfig } from "@/reducers/searchConfReducer";
import { usePaxConfig } from "@/reducers/paxReducer";
import { useCSC } from "@/hooks";
import { BASE_SIZE, PLATFORM } from "@/common/CommonType";
import ikeaSDK from "@/utils/ikea-sdk";
import { useHistory } from "react-router-dom";
import CSCNoWorkTime from "../CSCNoWorkTime/CSCNoWorkTime";
import RegularModal from "./RegularModal";
import { InfoType } from "@/hooks/userInfo";
import researchBanner from "@/assets/images/research_banner1.png";
import ikea from "ikea-jssdk";
import TemplateTool from "@/utils/template-tool";
import { GIO_Duration } from "@/utils/gioUtils";

interface Props {
  title: string;
  tip?: string;
  cscCustomerText?: string;
  showExit?: boolean;
  showSurvey?: boolean;
  open: boolean;
  templateId?: number;
  onClose: () => void;
}

function DesignerModal({
  title,
  tip,
  cscCustomerText,
  open,
  templateId,
  onClose,
  showExit,
  showSurvey,
}: Props) {
  const { state } = useConfig();
  const { state: paxState } = usePaxConfig();
  // 获取柜子的相关信息
  const templateTool = new TemplateTool(paxState.recommendTemplate?.wardrobeWithProduct)
  const cscData = {
    doorType: state.doorType,
    width: templateTool.wardrobeWidth || BASE_SIZE.WIDTH,
    depth: templateTool.wardrobeDepth || BASE_SIZE.DEPTH,
    height: templateTool.wardrobeHeight || BASE_SIZE.HEIGHT,
    frameColor: templateTool.wardrobeFrameColor || BASE_SIZE.FRAME_COLOR,
    dislikeList: state.dislikeList,
    needList: state.needList,
  };
  const { isWorkTime, jumpNewCSC } = useCSC(cscData, cscCustomerText);
  const [openCSC, setOpenCSC] = useState(false);
  const {
    state: { platform },
  } = usePaxConfig();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorTel, setShowErrorTel] = useState(false);

  const closeCSC = (closeDesignModal = false) => {
    setOpenCSC(false);
    if (closeDesignModal) onClose();
  };

  function handleContactDesigner() {
    if (!isWorkTime) {
      setOpenCSC(true);
      return;
    }
    jumpNewCSC(); // jumpNewCSC();
    onClose();
  }

  const handleExit = () => {
    onClose();
    if ([PLATFORM.APP, PLATFORM.SMP].includes(platform)) {
      GIO_Duration()
      ikeaSDK.goHome();
    } else {
      history.replace("/");
    }
  };
  const toQS = () => {
    ikea.openH5("https://wjx.survey.ikea.cn/vj/e9wzR6v.aspx");
  };
  return (
    <>
      <BaseModal open={open} onClose={onClose}>
        <div className={styles.Modal}>
          <div className={styles.ModalTitle}>{title}</div>
          {showSurvey && (
            <div className={styles.research}>
              < img src={researchBanner} onClick={toQS} alt="" />
            </div>
          )}
          <div className={styles.ModalSubTitle}>
            {tip ? tip : "我们有线上专业设计师免费为您服务"}
          </div>
          <div className={styles.ModalDesigner}>
            <img src={DesignerImage} alt={"designer"} />
          </div>
          <div
            className={styles.ModalConfirmBtn}
            onClick={handleContactDesigner}
          >
            联系线上设计师
          </div>
          {showExit && (
            <div className={styles.ModalExitBtn} onClick={handleExit}>
              直接退出
            </div>
          )}
        </div>
      </BaseModal>
      <CSCNoWorkTime
        infoType={InfoType.NON_WORKING}
        openCSC={openCSC}
        templateId={templateId}
        hide={closeCSC}
        showSuccessAlert={() => {
          setShowSuccess(true);
        }}
        showErrorTelAlert={() => {
          setShowErrorTel(true);
        }}
      />
      <RegularModal
        open={showSuccess}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowSuccess(false);
        }}
        disableBackdropClick={true}
      >
        {"我们的线上专属客服会在近期联系您，请您耐心等候。"}
      </RegularModal>
      <RegularModal
        open={showErrorTel}
        sureText={"我知道了"}
        noCloseIcon={true}
        sureFn={() => {
          setShowErrorTel(false);
        }}
        disableBackdropClick={true}
      >
        {"您输入的号码格式错误，请输入正确的 11 位数电话号码。"}
      </RegularModal>
    </>
  );
}

export default DesignerModal;
