import classnames from 'clsx';
import styles from "./Drawer.module.scss";
import PositionTip from '@/components/AITip/PositionTip';
import { useEffect, useState } from 'react';

interface IProps {
  tabData: ITabItem[];
  tabComp: (props: any) => JSX.Element;
  contComp: () => JSX.Element;
  toggleItem: (props?: any) => void;
  currentItem: ITabItem;
  contShow: boolean;
  setContShow: (props: boolean) => void
}

export interface ITabItem {
  type: string;
}

const Drawer: React.FC<IProps> = (props) => {
  const { tabData, tabComp, contComp, toggleItem, currentItem, contShow, setContShow } = props
  const [showTip, setShowTip] = useState(true);
  useEffect(() => {
    setTimeout(() => setShowTip(false), 10 * 1000)
  }, []);

  return (
    <div className={styles.Container}>
      <ul className={styles.ulWrapper}>
        {tabData.map((item, index) => {
          return (
            <li
              key={index}
              className={classnames(styles.liItem, { [styles.active]: currentItem.type === item.type })}
              onClick={() => {
                setShowTip(false)
                toggleItem(item)
              }}
            >
              {tabComp(item)}
            </li>
          )
        })}
      </ul>
      {
        showTip && (
          <PositionTip className={styles.tip}>
            <p>你可以使用<b>「配件」和「不同柜体组合」</b>的筛选功能选出最适合你的内配方案。</p>
          </PositionTip>
        )
      }
      <div className={classnames(styles.content, { [styles.show]: contShow })}>
        {contComp()}
      </div>
      <div
        className={classnames(styles.model, { [styles.modelShow]: contShow })}
        onClick={() => {
          setShowTip(false)
          setContShow(false)
        }}
      />
    </div>)
}

export default Drawer;
