export const DISLIKE_LIST = [
  {
    order: 0,
    id: "GLASS_SHELF",
    name: "玻璃隔板",
    icon: require("../../assets/images/no_need/glass_shelf.png"),
    check: true,
  },
  {
    order: 1,
    id: "NORMAL_DRAWER",
    name: "普通抽屉",
    icon: require("../../assets/images/no_need/drawer.png"),
    check: true,
  },
  {
    order: 2,
    id: "GLASS_DRAWER",
    name: "玻璃抽屉",
    icon: require("../../assets/images/no_need/glass_drawer.png"),
    check: false,
  },
  {
    order: 3,
    id: "LOCKABLE_DRAWER",
    name: "带锁抽屉",
    icon: require("../../assets/images/no_need/locker_drawer.png"),
    check: false,
  },
  {
    order: 4,
    id: "CLOTHES_RAIL",
    name: "挂衣杆",
    icon: require("../../assets/images/no_need/cloth_rail.png"),
    check: false,
  },
  {
    order: 5,
    id: "MESH_BASKET",
    name: "网篮",
    icon: require("../../assets/images/no_need/mesh_basket.png"),
    check: false,
  },
  {
    order: 6,
    id: "TRAY",
    name: "托盘",
    icon: require("../../assets/images/no_need/tray.png"),
    check: false,
  },
  {
    order: 7,
    id: "SHOE_SHELF",
    name: "鞋架",
    icon: require("../../assets/images/no_need/shoe_shelf.png"),
    check: false,
  },
  {
    order: 8,
    id: "TROUSER_HANGER",
    name: "裤架",
    icon: require("../../assets/images/no_need/trousers_hanger.png"),
    check: false,
  },
]
