/**
 * 请求对应的忽略规则
 */
const rules = new Map([
  [
    '/v2/recommend/wardrobes/page',
    (): boolean => {
      return true;
    }
  ]
]);
/**
 * 请求是否需要忽略全局的loading显示
 */
function ignoreGlobalLoading(url: string): boolean {
  const fn = rules.get(url) ?? (() => false);
  return fn();
}

export default ignoreGlobalLoading;
