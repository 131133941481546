import { PLATFORM, STORAGE_KEYS } from "@/common/CommonType";
import Toast from "@/components/Toast";
import {
  getToken as getPlatformToken,
  whichPlatform,
  getDeviceId,
} from "@/utils/ikea-sdk";
import axios, { Method, AxiosRequestConfig } from "axios";
import { syncRequestConfig } from "@aidesign/store";
import ignoreGlobalLoading from "@/api/ignoreGlobalLoading";

export interface PendingType {
  url: string | undefined;
  method: Method | undefined;
  params?: object;
  data?: object;
  cancel: Function;
}

interface IOptions {
  isRedirect?: boolean;
  isSilence?: boolean;
  isErrorSilence?: boolean;
  loadingText?: string;
  getContainer?: HTMLDivElement | null | undefined;
  ignoreParamsWhenRemoveDuplicated?: boolean; // 移除重复请求时是否判断参数是否一致
  zipcode?: string | number;
}

// 后端只识别这三个值
enum IPLATFORM {
  APP = "APP",
  SMP = "SMP",
  MOBILE_WEB = "MOBILE_WEB",
}

const configCN = {
  token: "",
  lastUpdateTokenTime: 0,
  defaultStoreId: 20,
  languageId: "1",
  languageCode: "zh-CN",
};

axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.REACT_APP_MODE === "sdk"
    ? "https://paxonline-qa.aidesign.ingka-dt.cn/api"
    : process.env.REACT_APP_BASE_URL;

const MAX_RETRY_COUNT = 5;
let currentRetryCount = 0;
let isRefreshing: boolean = false;
const pendingRequestQueue: Function[] = [];
let loadingRequestUrlArr: string[] = [];
let isLoaded: boolean = false;
const pending: Array<PendingType> = [];
const CancelToken = axios.CancelToken;

interface PaxAxiosRequestConfig extends AxiosRequestConfig {
  paxOptions?: any;
}

// 移除重复请求
const removePending = (config: PaxAxiosRequestConfig) => {
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    // console.log('------config.data?.options?.ignoreParamsWhenRemoveDuplicated', config.data?.options?.ignoreParamsWhenRemoveDuplicated);
    // 移除重复请求时是否判断参数是否一致
    const isIgnoreParams = config.data?.options
      ?.ignoreParamsWhenRemoveDuplicated
      ? true
      : JSON.stringify(list.params) === JSON.stringify(config.params) &&
        JSON.stringify(list.data) === JSON.stringify(config.data);
    // 当前请求在数组中存在时执行函数体
    if (
      list.url === config.url &&
      list.method === config.method &&
      isIgnoreParams
    ) {
      // 执行取消操作
      list.cancel("Operation is too frequent, please try again later.");
      // 从数组中移除记录
      pending.splice(item, 1);
    }
  }
};

axios.interceptors.request.use(
  async (axiosConfig) => {
    const config = axiosConfig as PaxAxiosRequestConfig;
    if (config.url !== "/token" && configCN.token) {
      config.headers.Authorization = `Bearer ${configCN.token}`;
    } else if (config.url !== "/token" && !configCN.token) {
      configCN.token = await getToken()
      config.headers.Authorization = `Bearer ${configCN.token}`;
    }
    const token = await getPlatformToken();
    const platformResult = await whichPlatform();
    const deviceId = await getDeviceId();
    // 这里要注意因为接入门店助手小程序（store companion），所以要在那个平台的时候转为SMP，不然接口会报400
    const platform = platformResult === PLATFORM.IKEA
      ? IPLATFORM.MOBILE_WEB
      : platformResult === PLATFORM.STORE_COMPANION
        ? IPLATFORM.SMP
        : platformResult;
    config.headers.token = token;
    config.headers.platform = platform;
    config.headers.groupId = localStorage.getItem(STORAGE_KEYS.GROUP_ID);
    config.headers.deviceId = deviceId;

    // post请求为data,get请求为params
    let { data, params } = config;
    if (typeof data === "string") {
      data = JSON.parse(data);
      config.data = data;
    }
    if (typeof params === "string") {
      params = JSON.parse(params);
      config.params = params;
    }
    // post请求
    if (data) {
      data.languageId = data.languageId || configCN.languageId;
      data.storeId = data.storeId || configCN.defaultStoreId;
      // post请求自定义headers
      if (data.headers) {
        Object.assign(config.headers, data.headers);
        delete data.headers;
      }
      // 出loading图标（silence为false才会出loading）
      if (config.url && !data.options?.isSilence)
        loadingRequestUrlArr.push(config.url);
    }
    // get请求
    if (params) {
      params.languageId = params.languageId || configCN.languageId;
      params.storeId = params.storeId || configCN.defaultStoreId;
      Object.assign(config.headers, { languageId: params.languageId });
      // get请求自定义headers
      if (params.headers) {
        Object.assign(config.headers, params.headers, {
          languageId: params.languageId,
        });
        delete params.headers;
      }
      // 出loading图标（silence为false才会出loading）
      if (config.url && !params.options?.isSilence)
        loadingRequestUrlArr.push(config.url);
    }

    // 有第一个请求的时候出loading
    if (loadingRequestUrlArr.length === 1) isLoaded = false;
    const ignoreLoading = ignoreGlobalLoading(config.url ?? "");
    if (loadingRequestUrlArr.length && !isLoaded && !ignoreLoading) {
      console.log('showtoast')
      isLoaded = true;
      Toast.show({
        icon: "loading",
        content: data?.options?.loadingText || params?.options?.loadingText,
      });
    }
    if (!config.cancelToken) {
      removePending(config);
      config.cancelToken = new CancelToken((c) => {
        pending.push({
          url: config.url,
          method: config.method,
          params: config?.params,
          data: config?.data,
          cancel: c,
        });
      });
    }
    config["paxOptions"] = data?.options;
    delete data?.options;
    delete params?.options;
    return config;
  },
  (error) => {
    if (error && error.config && error.config.url) {
      clearToast(error && error.config && error.config.url);
    } else {
      clearToastFn();
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    removePending(response.config);
    clearToast((response && response.config && response.config.url) || "");
    return response;
  },
  async (err) => {
    if (!axios.isCancel(err)) {
      if (err && err.response) {
        clearToast(
          (err &&
            err.response &&
            err.response.config &&
            err.response.config.url) ||
            ""
        );
        switch (err.response.status) {
          case 400:
            console.log("错误请求");
            break;
          case 401:
            currentRetryCount++;
            if (currentRetryCount > MAX_RETRY_COUNT) {
              break;
            }
            if (!isRefreshing) {
              isRefreshing = true;
              await getToken().finally(() => (isRefreshing = false));
              let req = pendingRequestQueue.shift();
              while (req) {
                req();
                req = pendingRequestQueue.shift();
              }
              return new Promise((resolve) => {
                axios.request(err.config).then((res) => resolve(res));
              });
            } else {
              return new Promise((resolve) => {
                pendingRequestQueue.push(() => {
                  axios.request(err.config).then((res) => resolve(res));
                });
              });
            }
          default:
            console.log(`连接错误${err.response.status}`);
        }
      } else {
        console.log("连接到服务器失败", err.request);
        clearToastFn();
        if (
          err &&
          err.config &&
          err.config.paxOptions &&
          err.config.paxOptions.isErrorSilence
        ) {
          return Promise.reject(err.response);
        }
        Toast.show({
          content: "连接到服务器失败",
        });
      }
      return Promise.reject(err.response);
    } else {
      clearToastFn();
      return Promise.reject(err);
    }
  }
);
// 清除Loading
function clearToast(url: string) {
  const urlIndex = url && loadingRequestUrlArr.indexOf(url);
  if (urlIndex === 0 || (urlIndex && urlIndex > -1)) {
    loadingRequestUrlArr.splice(urlIndex, 1);
  }
  if (!loadingRequestUrlArr.length && isLoaded) {
    isLoaded = false;
    clearToastFn();
  }
}

function clearToastFn() {
  console.log('cleartoast')
  Toast.clear();
  loadingRequestUrlArr = [];
  isLoaded = false;
}

//todo add ikea sdk
export function get<T>(
  url: string,
  params: any = {},
  options?: IOptions
): Promise<T> {
  const {
    isRedirect: isRedirectValue = true,
    isSilence: isSilenceValue = false,
    isErrorSilence: isErrorSilenceValue = false,
    loadingText: loadingTextValue = "",
    getContainer: getContainerValue,
    ignoreParamsWhenRemoveDuplicated = false,
  } = options ?? {
    isRedirect: true,
    isSilence: false,
    loadingText: "",
    ignoreParamsWhenRemoveDuplicated: false,
    isErrorSilence: false,
  };
  // 请求静默处理，默认不静默
  params.options = {};
  if (isSilenceValue) params.options.isSilence = isSilenceValue;
  if (isErrorSilenceValue) params.options.isErrorSilence = isErrorSilenceValue;
  if (loadingTextValue) params.options.loadingText = loadingTextValue;
  if (getContainerValue) params.options.getContainer = getContainerValue;
  if (ignoreParamsWhenRemoveDuplicated)
    params.options.ignoreParamsWhenRemoveDuplicated =
      ignoreParamsWhenRemoveDuplicated;

  return new Promise((resolve, reject) => {
    console.log("url", url);
    axios
      .get<T>(url, {
        params: params,
      })
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(async (err) => {
        reject(err);
        if (isRedirectValue) {
          // const isWXMP = await ikeaSDK.isInWXMP();
          // !isWXMP && router.app.$router.replace({
          //   path: '/error'
          // })
        }
      });
  });
}

//todo add ikea sdk
export function post<T>(
  url: string,
  data: any = {},
  options?: IOptions,
  config?: AxiosRequestConfig
): Promise<T> {
  const {
    isRedirect: isRedirectValue = true,
    isSilence: isSilenceValue = false,
    loadingText: loadingTextValue = "",
    getContainer: getContainerValue,
    ignoreParamsWhenRemoveDuplicated = false,
  } = options ?? {
    isRedirect: true,
    isSilence: false,
    loadingText: "",
    ignoreParamsWhenRemoveDuplicated: false,
  };
  // 请求静默处理，默认不静默
  data.options = {};
  if (isSilenceValue) data.options.isSilence = isSilenceValue;
  if (loadingTextValue) data.options.loadingText = loadingTextValue;
  if (getContainerValue) data.options.getContainer = getContainerValue;
  if (ignoreParamsWhenRemoveDuplicated)
    data.options.ignoreParamsWhenRemoveDuplicated =
      ignoreParamsWhenRemoveDuplicated;

  return new Promise((resolve, reject) => {
    axios.post<T>(url, data, config).then(
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      async (err) => {
        reject(err);
        if (isRedirectValue) {
          // const isWXMP = await ikeaSDK.isInWXMP();
          // !isWXMP && router.app.$router.push({
          //   path: '/error'
          // })
        }
      }
    );
  });
}

const TOKEN_EXPIRED = 60 * 15 * 1000; // 15 minutes

export const getToken = async (force: boolean = false): Promise<string> => {
  const currentTimestamp = new Date().getTime();
  if (
    !force &&
    currentTimestamp - configCN.lastUpdateTokenTime < TOKEN_EXPIRED
  ) {
    return configCN.token || "";
  }
  const res = await post<{ token: string; storeId: number }>(
    "/token",
    { key: "PAX@ikea.com" },
    { isSilence: true }
  );
  currentRetryCount = 0;
  configCN.token = res.token || configCN.token;
  configCN.defaultStoreId = res.storeId || res.storeId;
  configCN.lastUpdateTokenTime = new Date().getTime();
  syncRequestConfig({
    token: configCN.token,
  });
  return configCN.token;
};
