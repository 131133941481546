export enum FrameColor {
  WHITE = "WHITE",
  BEIGE = "BEIGE",
  BROWN = "BROWN",
  DARK_BROWN = "DARK_BROWN",
  BEIGE_YELLOW = "BEIGE_YELLOW",
  DARK_GREY = "DARK_GREY"
}

export type FrameHeight = 236 | 201

export type FrameDepth = 35 | 58

export enum DoorType {
  HINGE = "HINGE_ONLY",
  NONE = "NONE",
  SLIDING = "SLIDING_ONLY",
}

export enum ShapeType {
  I_SHAPE = "I_SHAPE",
  L_SHAPE = "L_SHAPE",
  U_SHAPE = "U_SHAPE"
}

// 暂时保留，后续看看能不能去掉只用上面的
export enum DoorTypeNumber {
  HINGE = 1,
  SLIDING = 2,
}

export enum PeopleType {
  MALE = "MALE",
  FEMALE = "FEMALE",
  COUPLE = "COUPLE",
  THREE = "THREE",
  FIVE_WITH_ONE_CHILD = "FIVE_WITH_ONE_CHILD",
  ELDER_COUPLE = "ELDER_COUPLE",
}

export enum STORAGE_KEYS {
  VERSION = "VERSION",
  NO_DOOR_TIPS_TIMEOUT = "NO_DOOR_TIP_TIMEOUT",
  SEARCH_CONFIG = "SEARCH_CONFIG",
  PAX_GLOBAL_DATA = "PAX_GLOBAL_DATA",
  FROM_PAGE = "FROM_PAGE",  // 判断是从哪来跳转我们的程序的，目前主要用来判断回退
  FROM_FLOW = "FROM_FLOW",  // 判断是否主流程（从banner进、重新设计按钮进），主流程需要缓存template,下次从banner点进来时需要记住上次退出的地方
  GROUP_ID = "GROUP_ID" // 每次初始化都会生产新的groupId,此字段是给推荐分析用的
}

export enum FulfillLevel {
  ECONOMICS = '极简型',
  DELICACY = '舒适型',
  LUXURY = '奢华型',
}

export enum EXTERIOR_TAB_NAMES {
  DOOR = "DOOR",
  STITCHING_FRAME = "STITCHING_FRAME",
  FRAME = "FRAME",
  KNOB = "KNOB",
  ROOM = "ROOM"
}

export enum PRODUCT_TYPE  {
  ART='ART',
  SPR='SPR'
}

export enum PRODUCT_TAG_COLOR  {
  BLUE='BLUE',
  GREEN='GREEN',
  ORANGE='ORANGE',
  PURPLE='PURPLE',
  RED='RED',
  UNKONWN='UNKONWN',
  YELLOW='YELLOW',
}

export enum PRODUCT_TAG_LEVEL  {
  COUNTRY='COUNTRY',
  STORE='STORE',
  UNKONWN='UNKONWN',
}

export enum PRODUCT_TAG_TYPE  {
  PRODUCT='PRODUCT',
  PROP_IN='PROP_IN',
  TEMPLATE='TEMPLATE',
  UNKONWN='UNKONWN'
}

export enum EXTERIOR_IMAGE_TYPE  {
  MAIN_PRODUCT_IMAGE='MAIN_PRODUCT_IMAGE',
  QUALITY_PRODUCT_IMAGE='QUALITY_PRODUCT_IMAGE',
}

export enum DOOR_TYPE {
  INTEGRAL= 'INTEGRAL', //整体滑门
  STITCHING = 'STITCHING',//拼接滑门
  STITCHING_PANEL = 'STITCHING_PANEL',//拼接滑门面板
  STITCHING_FRAME='STITCHING_FRAME'//拼接滑门框架
}

export enum DOOR_TYPE_CN {
  HINGE_ONLY = '合页门',
  NONE = '无门',
  SLIDING_ONLY = '滑门',
}

export enum PEOPLE_TYPE_CN {
  MALE = '独居男性',
  FEMALE = '独居女性',
  COUPLE = '二人世界',
  THREE = '三口之家',
  FIVE_WITH_ONE_CHILD = '五口之家',
  ELDER_COUPLE = '年长夫妇',
}

export enum PLATFORM {
  APP = 'APP',
  SMP = 'SMP',
  IKEA = 'IKEA',
  H5 = "H5",
  STORE_COMPANION = 'STORE_COMPANION',
}

export enum SOURCE_PAGE{
  SHARE='share',
  DETAIL='detail',
  SCAN='scan',
  PRODUCTLIST='productlist'
}

export enum FROM_PAGE {
  MAIN_ENTRANCE = 'main_entrance',
  SCAN = 'scan',
  SHARE = 'share',
  SHOPPING_CART = 'shopping_cart',
  DESIGN_LIST = 'design_list',
  DTC_SELECT_EXTERIOR = 'dtc_select_exterior',
  LEADS= "LEADS",
  CUSTOM_LANDINGPAGE = "CUSTOM_LANDINGPAGE",
  WRD_LANDINGPAGE = "WRD_LANDINGPAGE"
}

export enum FROM_FLOW {
  MAIN_FLOW = 'main_flow',
  OTHER_FLOW = 'other_flow'
}

export enum BASE_SIZE {
  WIDTH = 200,
  HEIGHT = 236,
  DEPTH = 58,
  FRAME_COLOR = 'WHITE'
}

export enum IIsShowSaleTip {
  HASTIP =  'hasTip',
  NOTIP = 'noTip'
}

export enum IIsShowStepGuide {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}

export interface SlimFrame {
  depth: number,
  frameColor: FrameColor
  frameId: number,
  height: number,
  modelUrl: string,
  width: number,
  imageUrl: string,
  itemNoLocal: string,
  productId: number,
}
