import { getCity } from '@/utils/access-tool'
import { post, get } from './request'

export const getConfig = async <T>() => {
  const cityInfo = await getCity() || {}
  return await post<T>('/config', { zipcode: cityInfo.zipcode }, { isSilence: true })
}

export const getAllPropIn = async <T>() => {
  return get<T>('/prop-in/list',{}, { isSilence: true })
}

export const getAllFrames = async <T>(params: any) => {
  return get<T>('/all/frameInfo', params , { isSilence: true })
}


export const slidingDoorOutOfStock = async <T>(params: any) => {
  const cityInfo = await getCity() || {}
  return get<T>('/pair/of/sliding/door/outOfStock', {
    ...params,
    zipcode: cityInfo.zipcode
  } , { isSilence: true })
}