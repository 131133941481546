import "./index.scss";
import "animate.css";
import "@aidesign/store/dist/index.css";
import { pick } from "lodash";
import "@aidesign/store/dist/index.vw.css";
import React, { Suspense, useCallback, useEffect } from "react";
import { PLATFORM, STORAGE_KEYS } from "@/common/CommonType";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import ikea from "ikea-jssdk";
import VConsole from "vconsole";
import Toast from "components/Toast";
import { usePrevious } from "hooks/index";
import { InteriorCtxProvider } from "../reducers/interiorReducer";
import { PaxCtxProvider } from "../reducers/paxReducer";
import { SearchConfigCtxProvider } from "../reducers/searchConfReducer";
import { fetchCurrentPlatform } from "@/utils/access-tool";
import { PaxActionType, usePaxConfig } from "reducers/paxReducer";
import { init, setGioUser } from "@/utils/ikea-sdk";
import { legacyRedirectAdapter } from "@/utils/common-tool";
import { augmentHtmlElement } from "@/utils/swipe";
import getAbTestPromise from "@/utils/abTestPromise";
import packageInfo from "../../package.json";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getCookie } from "@/utils/cookie-tool";
import {
  igNoreHistoryPathList,
  otherEntranceRouters,
  saveFromFlowToSession,
  saveFromPageToSession,
  // isLeadsPage
} from "@/utils/route-tool";
import RecommendTest from "@/routes/RecommendTest";
import { v4 as uuids4 } from "uuid";
import { GIO_Duration, GIO_LOAD_PAGE } from "@/utils/gioUtils";
import { getDeviceId } from "../utils/ikea-sdk";
import Reservation from "@/routes/Leads/Reservation";
import aidTrack from "@/utils/aid-track";

const CustomLandingPage = React.lazy(() => import(/* webpackChunkName:"CustomLandingPage" */'@/routes/Leads/CustomLandingPage'))
const WrdLandingPage = React.lazy(() => import(/* webpackChunkName:"WrdLandingPage" */'@/routes/Leads/WrdLandingPage'))
const DetailV2 = React.lazy(() => import(/* webpackChunkName:"DetailV2" */'../routes/Detail/DetailV2'))
const DetailStoreCompanion = React.lazy(() => import(/* webpackChunkName:"DetailV2" */'../routes/Detail/DetailStoreCompanion'))
const SelectExteriorStyle = React.lazy(() => import(/* webpackChunkName:"SelectExteriorStyle" */'../routes/SelectExteriorStyle'))
const TemplateOverview = React.lazy(() => import(/* webpackChunkName:"TemplateOverview" */'../routes/TemplateOverview'))
const OutOfStockPlan = React.lazy(() => import(/* webpackChunkName:"OutOfStockPlan" */'../routes/OutOfStockPlan'))
const SizeFilter = React.lazy(() => import(/* webpackChunkName:"SizeFilter" */'../routes/SizeFilter'))
const WardrobeList = React.lazy(() => import(/* webpackChunkName:"WardrobeList" */'../routes/WardrobeList'))
const FaEditor = React.lazy(() => import(/* webpackChunkName:"FaEditor" */'../routes/Editor'))
const Inspiration = React.lazy(() => import(/* webpackChunkName:"Inspiration" */'../routes/Inspiration'))
const InspirationDetail = React.lazy(() => import(/* webpackChunkName:"InspirationDetail" */'../routes/InspirationDetail'))
const InteriorFilter = React.lazy(() => import(/* webpackChunkName:"InteriorFilter" */'../routes/InteriorFilter'))
const BuyerShow = React.lazy(() => import(/* webpackChunkName:"BuyerShow" */'../routes/BuyerShow'))
const Poster = React.lazy(() => import(/* webpackChunkName:"Poster" */'../routes/Poster'))
const Quotation = React.lazy(() => import(/* webpackChunkName:"Quotation" */'../routes/Quotation/Quotation'));
const Reserved = React.lazy(() => import(/* webpackChunkName:"Reserved" */'../routes/Leads/ReservedV2'));
augmentHtmlElement();
const Router: React.ComponentClass =
  process.env.REACT_APP_MODE === "sdk" ? HashRouter : BrowserRouter;
interface IAppProps {
  platform: PLATFORM;
}
// 不记录历史记录的路由
const isShouldIgnoreHistoryRecord = (pathname: string) => {
  const ig = igNoreHistoryPathList.some((item) => pathname?.startsWith(item));
  return ig;
};

function GuardStoreCompanion(props: any) {
  const { platform, children } = props;
  const history = useHistory();
  const loc = useLocation();
  const { templateId } = useParams<{ templateId: string }>();

  useEffect(() => {
    if (platform === PLATFORM.STORE_COMPANION) {
      const url = `/detailStoreCompanion${templateId ? '/' + templateId : ''}${loc.search}`
      history.replace(url);
    }
  }, [])

  return platform === PLATFORM.STORE_COMPANION ? null : children
}

// 抽取innerApp， 监听historyChange
function App(props: IAppProps) {
  const { platform } = props;
  const history = useHistory();
  const { dispatch: paxDispatch, state: paxState } = usePaxConfig();
  const { location: currentLocation } = history;
  // 判断是否需要重定向路由
  const needRedirectRoute = () => {
    const currentPathName = currentLocation.pathname;
    const lastCachedPathName = paxState.currentLocation?.pathname;
    // 不需要跳转到历史路径的页面路由
    const igNoreHistoryPath = isShouldIgnoreHistoryRecord(
      currentPathName as string
    );
    // 登录后跳转回来，特殊处理smp中会跳转会第一步的场景、同时忽略其他smp情况下记录的非detail的路由
    const isRestoreInSMP =
      paxState.platform === PLATFORM.SMP && (
        lastCachedPathName?.startsWith("/detail/") ||
        lastCachedPathName?.startsWith("/poster/")
      );
    if (
      (isRestoreInSMP || paxState.platform === PLATFORM.APP) &&
      lastCachedPathName &&
      currentPathName !== lastCachedPathName &&
      !igNoreHistoryPath
    ) {
      return lastCachedPathName;
    } else {
      return "";
    }
  };
  // 此处判断路由是否需要重定向，存在localStorage中，在首页判断是否执行首页代码逻辑
  const redirectRoute = needRedirectRoute();
  localStorage.setItem("redirectRoute", redirectRoute ? "true" : "false");
  // 每次路由更新需要判断是否是主流程还是其他流程（主流程：正常设计、重新设计，非主流程的来源页面:扫码、分享、购物车跳转、我的设计跳转等）
  saveFromFlowToSession(currentLocation.pathname);

  // 设置上一次的访问路径
  usePrevious(currentLocation, function (previous, current) {
    if (previous?.pathname && previous.pathname !== current.pathname) {
      paxDispatch({
        type: PaxActionType.Update,
        payload: {
          previousLocation: pick(previous, ["pathname", "search"]),
        },
      });
    }
  });

  // 设置platform
  useEffect(() => {
    paxDispatch({
      type: PaxActionType.Update,
      payload: {
        platform: (localStorage.getItem("PAX_ENV") as PLATFORM) || platform,
      },
    });
  }, [platform, paxDispatch]);

  // 判断路由是否需要重定向
  useEffect(() => {
    const currentPathName = currentLocation.pathname;
    const redirectRoute = needRedirectRoute();

    if (redirectRoute) {
      paxState.platform === PLATFORM.APP &&
        Toast.show("这是您上一次进行到的步骤");
      history.replace(redirectRoute);
    } else {
      if (!isShouldIgnoreHistoryRecord(currentPathName)) {
        paxDispatch({
          type: PaxActionType.Update,
          payload: {
            currentLocation: {
              pathname: currentLocation.pathname,
              search: currentLocation.search,
            },
          },
        });
      }
    }
    listenLocationChange();
  }, []); // eslint-disable-line

  // 更新当前路径paxReducer
  const listenLocationChange = useCallback(() => {
    history.listen((location, action) => {
      const { pathname, search } = location;
      if (!isShouldIgnoreHistoryRecord(pathname as string)) {
        paxDispatch({
          type: PaxActionType.Update,
          payload: {
            currentLocation: {
              pathname,
              search,
            },
          },
        });
      }
    });
  }, [paxDispatch, history]);

  // const pipLayout = sessionStorage.getItem('abTestPipScroll');
  // const DetailComp = pipLayout === 'scrollLayout' ? DetailV2 : Detail;
  const location = useLocation();

  useEffect(() => {
    GIO_Duration();
    GIO_LOAD_PAGE();
  }, [location]);

  return (
    <Suspense fallback={<div/>}>
      <Switch>
        <Route path="/selectExteriorStyle/:templateId">
          <SelectExteriorStyle />
        </Route>
        <Route path="/dtcSelectExteriorStyle/:templateId">
          <SelectExteriorStyle />
        </Route>
        <Route path="/scan/:templateId?">
          <DetailV2 />
        </Route>
        <Route path="/share/:templateId?">
          <DetailV2 />
        </Route>
        <Route path="/detail/:templateId?">
          <GuardStoreCompanion platform={platform}>
            <DetailV2 />
          </GuardStoreCompanion>
        </Route>
        <Route path="/detailStoreCompanion/:templateId?">
          <DetailStoreCompanion />
        </Route>
        <Route path="/poster/:templateId?">
          <Poster></Poster>
        </Route>
        <Route path="/productList/:templateId?">
          <DetailV2 />
        </Route>
        <Route path="/designList/:templateId?">
          <DetailV2 />
        </Route>
        <Route path="/outofstockplan/:templateId/:uniqueCode?">
          <OutOfStockPlan></OutOfStockPlan>
        </Route>
        <Route path="/wardrobe_list">
          <InteriorCtxProvider>
            <WardrobeList />
          </InteriorCtxProvider>
        </Route>
        <Route path="/recommend_test">
          <InteriorCtxProvider>
            <RecommendTest />
          </InteriorCtxProvider>
        </Route>
        <Route path="/interior_design">
          <InteriorFilter />
        </Route>
        <Route path="/template_overview/:templateId">
          <TemplateOverview />
        </Route>
        <Route path="/template_overview_design/:templateId">
          <TemplateOverview />
        </Route>
        <Route path="/inspire">
          <Inspiration />
        </Route>
        <Route path="/inspire_detail">
          <InspirationDetail />
        </Route>
        <Route path="/editor/:templateId">
          <FaEditor />
        </Route>
        <Route path="/buyer_show/:templateId">
          <BuyerShow />
        </Route>
        <Route path="/leads">
          <Reservation />
        </Route>
        <Route path="/custom_landingpage">
          <CustomLandingPage />
        </Route>
        <Route path="/wrd_landingpage">
          <WrdLandingPage />
        </Route>
        <Route path="/reserved/:storeName">
          <Reserved />
        </Route>
        <Route path="/quotation">
          <Quotation />
        </Route>
        <Route path="/">
          <SizeFilter />
        </Route>
      </Switch>
    </Suspense>

  );
}

// 初始化
(async function () {
  if (window.location.hostname !== "localhost") {
    Sentry.init({
      dsn: "https://aa0df99d18fe471daee7248555977b3d@sentry.ingka-system.cn/75",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_HOST_TYPE,
      release: process.env.REACT_APP_VERSION,
    });
  }
  localStorage.setItem(STORAGE_KEYS.GROUP_ID, uuids4());
  // 初始化时需要判断主次流程存入localStorage中，store中会根据fromFlow判断是否init初始的state
  saveFromFlowToSession(window.location.pathname);
  // 初始化时需要判断页面来源存入localStorage中
  saveFromPageToSession(window.location.pathname);

  /** 旧访问路径兼容开始， 放在最头部 */
  const redirectUrl = legacyRedirectAdapter();
  if (redirectUrl.length > 0) {
    window.location.replace(redirectUrl);
    return; // 阻止进一步执行
  }
  /** 旧访问路径兼容结束 */

  /** 有版本更新，跳转到首页并清空localStorage开始 ，otherEntranceRouters：其他入口进入，不需要清缓存的页面*/
  const latestVersion = packageInfo.version;
  // const isLeadsPag = isLeadsPage(window.location.href)
  if (
    localStorage.getItem("version") !== latestVersion &&
    !otherEntranceRouters.some((item) =>
      window.location.pathname.startsWith(item)
    )
  ) {
    localStorage.clear();
    localStorage.setItem("version", latestVersion);
    window.location.replace("/");
    return;
  }

  // 初始化ikea-jssdk的参数
  init();
  try {
    ikea.openLoading()
  } catch (e) {
    console.log("open loading error:", e)
  }

  // 获取platform
  let platformValue = PLATFORM.H5;
  const getPlatformPromise = new Promise((resolve, reject) => {
    fetchCurrentPlatform()
      .then(({ platform }) => {
        platformValue = platform;
        resolve(platform);
      })
      .catch(() => {
        reject();
      });
  });

  // 每次进程序需要判断这次的layout参数和上次的是否一样，如果不一样需要刷新清空缓存返回首页
  // const checkoutLayout = (curLayout: string) => {
  //   const oldLayout = localStorage.getItem('exteriorOrInternal');
  //   if (oldLayout && oldLayout !== curLayout) {
  //     localStorage.clear();
  //     localStorage.setItem('exteriorOrInternal', curLayout);
  //     window.location.replace('/');
  //     return;
  //   } else if (!oldLayout) {
  //     localStorage.setItem('exteriorOrInternal', curLayout);
  //   }
  // }

  function isNeedAB() {
    // const href = window.location.href
    // if (
    //   href.includes('custom_landingpage') ||
    //   href.includes('wrd_landingpage')
    // ) {
    //   return false
    // }
    return true
  }

  function render() {
    ReactDOM.render(
      <React.StrictMode>
        <PaxCtxProvider>
          <SearchConfigCtxProvider>
            <Router>
              <App platform={platformValue} />
            </Router>
          </SearchConfigCtxProvider>
        </PaxCtxProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }

  getPlatformPromise.then(async () => {
    const value = await getDeviceId();
    const gioUuid = getCookie("gr_user_id");
    window.gio("visitor.set", "pax_visituserid", gioUuid);
    window.gio("visitor.set", "pax_deviceid", value || "-");

    aidTrack.init(platformValue)
    if (platformValue !== PLATFORM.H5) {
      setGioUser();
    }
    render()
    getAbTestPromise()
    try {
      ikea.closeLoading()
    } catch (e) {
      console.log("close loading error:", e)
    }
  })
  //测试环境下显示vconsole
  const isDebugMode = ["dev", "test"].includes(
    process.env.REACT_APP_HOST_TYPE as string
  );
  const isLocalhost = window.location.hostname.indexOf("localhost") > -1;
  if (isDebugMode && !isLocalhost) {
    new VConsole();
  }
})();
