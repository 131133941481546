import PeopleTypeMale from "../../assets/icons/people_type_male.svg";
import PeopleTypeMaleActive from "../../assets/icons/people_type_male_active.svg";
import PeopleTypeFemale from "../../assets/icons/people_type_female.svg";
import PeopleTypeFemaleActive from "../../assets/icons/people_type_female_active.svg";
import PeopleTypeCouple from "../../assets/icons/people_type_couple.svg";
import PeopleTypeCoupleActive from "../../assets/icons/people_type_couple_active.svg";
import PeopleTypeThree from "../../assets/icons/people_type_three.svg";
import PeopleTypeThreeActive from "../../assets/icons/people_type_three_active.svg";
import PeopleTypeFive from "../../assets/icons/people_type_five.svg";
import PeopleTypeFiveActive from "../../assets/icons/people_type_five_active.svg";
import PeopleTypeOlder from "../../assets/icons/people_type_older.svg";
import PeopleTypeOlderActive from "../../assets/icons/people_type_older_active.svg";
import { DoorType, PeopleType } from "../../common/CommonType";

export interface IList {
  code: number;
  text: string;
  value: string;
  checked: boolean;
  icon?: any;
  activeIcon?: any;
  desc?: string
}

interface IPaxCombine {
  [key: string]: ICombine[]
}

export interface ICombine {
  text: string;
  code: number;
  value: string;
  checked: boolean;
  isSlidingDoor: boolean;
  multiple75AndHingDoor?: boolean // 多个750并且装合叶门的柜体组合
  defaultComb: boolean;
}

export const FrameNumText ={
  1: "单柜体",
  2: "双柜体",
  3: "三柜体",
  4: "四柜体",
  5: "五柜体",
  6: "六柜体",
  7: "七柜体"
} as {
  [key: number]: string
}

//Pax组合
export const paxCombine: IPaxCombine = {
  50: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '50 cm', code: 1, value: '/50/', checked: false, isSlidingDoor: false, defaultComb: true }
  ],
  75: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '75 cm', code: 1, value: '/75/', checked: false, isSlidingDoor: false, defaultComb: true }
  ],
  100: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '50+50 cm', code: 1, value: '/50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '100 cm', code: 2, value: '/100/', checked: false, isSlidingDoor: false, defaultComb: true }
  ],
  125: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '75+50 cm', code: 1, value: '/75+50/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '50+75 cm', code: 2, value: '/50+75/', checked: false, isSlidingDoor: false, defaultComb: false }
  ],
  150: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: true, defaultComb: false },
    { text: '50+100 cm', code: 1, value: '/50+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '100+50 cm', code: 2, value: '/100+50/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '50+50+50 cm', code: 3, value: '/50+50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '75+75 cm', code: 4, value: '/75+75/', checked: false, isSlidingDoor: true, multiple75AndHingDoor:true, defaultComb: true }
  ],
  175: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '75+100 cm', code: 1, value: '/75+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '100+75 cm', code: 2, value: '/100+75/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '75+50+50 cm', code: 3, value: '/75+50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+75+50 cm', code: 4, value: '/50+75+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+50+75 cm', code: 5, value: '/50+50+75/', checked: false, isSlidingDoor: false, defaultComb: false }
  ],
  200: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: true, defaultComb: false },
    { text: '100+100 cm', code: 1, value: '/100+100/', checked: false, isSlidingDoor: true, defaultComb: true },
    { text: '50+100+50 cm', code: 2, value: '/50+100+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '100+50+50 cm', code: 3, value: '/100+50+50/', checked: false, isSlidingDoor: true, defaultComb: false },
    { text: '50+50+100 cm', code: 4, value: '/50+50+100/', checked: false, isSlidingDoor: true, defaultComb: false },
    // { text: '75+75+50 cm', code: 5, value: '/75+75+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    // { text: '75+50+75 cm', code: 6, value: '/75+50+75/', checked: false, isSlidingDoor: false, defaultComb: false },
    // { text: '50+75+75 cm', code: 7, value: '/50+75+75/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+50+50+50 cm', code: 8, value: '/50+50+50+50/', checked: false, isSlidingDoor: true, defaultComb: false }
  ],
  225: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '100+75+50 cm', code: 1, value: '/100+75+50/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '75+100+50 cm', code: 2, value: '/75+100+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    // { text: '75+75+75 cm', code: 3, value: '/75+75+75/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+75+50+50 cm', code: 4, value: '/50+75+50+50/', checked: false, isSlidingDoor: false, defaultComb: false }
  ],
  250: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '100+100+50 cm', code: 1, value: '/100+100+50/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '100+50+100 cm', code: 2, value: '/100+50+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+100+100 cm', code: 3, value: '/50+100+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    // { text: '75+100+75 cm', code: 4, value: '/75+100+75/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+100+50+50 cm', code: 5, value: '/50+100+50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+50+50+50+50 cm', code: 8, value: '/50+50+50+50+50/', checked: false, isSlidingDoor: false, defaultComb: false }
  ],
  275: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: false, defaultComb: false },
    { text: '100+100+75 cm', code: 1, value: '/100+100+75/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '100+75+100 cm', code: 2, value: '/100+75+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '75+100+100 cm', code: 3, value: '/75+100+100/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+100+75+50 cm', code: 4, value: '/50+100+75+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    // { text: '75+75+75+50 cm', code: 5, value: '/75+75+75+50/', checked: false, isSlidingDoor: false, defaultComb: false }
  ],
  300: [
    { text: '全部柜体组合', code: 0, value: '', checked: true, isSlidingDoor: true, defaultComb: false },
    { text: '100+100+100 cm', code: 1, value: '/100+100+100/', checked: false, isSlidingDoor: false, defaultComb: true },
    { text: '75+75+75+75 cm', code: 2, value: '/75+75+75+75/', checked: false, isSlidingDoor: true, multiple75AndHingDoor:true, defaultComb: true },
    { text: '100+100+50+50 cm', code: 3, value: '/100+100+50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+100+100+50 cm', code: 4, value: '/50+100+100+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+50+50+50+50+50 cm', code: 5, value: '/50+50+50+50+50+50/', checked: false, isSlidingDoor: false, defaultComb: false },
    { text: '50+50+100+50+50 cm', code: 6, value: '/50+50+100+50+50/', checked: false, isSlidingDoor: false, defaultComb: false }
  ]
}

// 根据选中柜子的宽度和类型筛选出柜子所有的组合信息list,width(cm)
export const filterDoorCombList = (width: number, doorType: DoorType) => {
  return paxCombine[width].filter((item) => {
    if (doorType === "SLIDING_ONLY") {
      return !!item.isSlidingDoor;
    } else {
      return !item.multiple75AndHingDoor;
    }
  });
};
export const PriceList:IList[]=[{
      code: 1,
      value: '',
      text: '全部价格类型',
      checked: true
}, {
      code: 2,
      text: '经济型',
      value: 'ECONOMICS',
      checked: false
    }, {
      code: 3,
      text: '精致型',
      value: 'ELITE',
      checked: false
    }, {
      code: 4,
      text: '豪华型',
      value: 'LUXURY',
      checked: false
    }]
export const fittingList: IList[] = [
  {
    code: 1,
    value: "GLASS_SHELF",
    text: "不要玻璃隔板",
    icon: require("../../assets/images/no_need/glass_shelf.png"),
    checked: true,
  },
  {
    code: 2,
    value: "NORMAL_DRAWER",
    text: "不要抽屉",
    icon: require("../../assets/images/no_need/drawer.png"),
    checked: false,
  },
  {
    code: 3,
    value: "GLASS_DRAWER",
    text: "不要玻璃抽屉",
    icon: require("../../assets/images/no_need/glass_drawer.png"),
    checked: false,
  },
  // {
  //   code: 4,
  //   value: "LOCKABLE_DRAWER",
  //   text: "不要带锁抽屉",
  //   icon: require("../../assets/images/no_need/locker_drawer.png"),
  //   checked: false,
  // },
  {
    code: 5,
    value: "CLOTHES_RAIL",
    text: "不要挂衣杆",
    icon: require("../../assets/images/no_need/cloth_rail.png"),
    checked: false,
  },
  {
    code: 6,
    value: "MESH_BASKET",
    text: "不要网篮",
    icon: require("../../assets/images/no_need/mesh_basket.png"),
    checked: false,
  },
  {
    code: 7,
    value: "TRAY",
    text: "不要托盘",
    icon: require("../../assets/images/no_need/tray.png"),
    checked: false,
  },
  {
    code: 8,
    value: "SHOE_SHELF",
    text: "不要鞋架",
    icon: require("../../assets/images/no_need/shoe_shelf.png"),
    checked: false,
  },
  {
    code: 9,
    value: "TROUSER_HANGER",
    text: "不要裤架",
    icon: require("../../assets/images/no_need/trousers_hanger.png"),
    checked: false,
  },
]

export const peopleList: IList[] = [
  {
    code: 1,
    value: PeopleType.MALE,
    icon: PeopleTypeMale,
    activeIcon: PeopleTypeMaleActive,
    text: "独居男性",
    checked: false,
  },
  {
    code: 2,
    value: PeopleType.FEMALE,
    icon: PeopleTypeFemale,
    activeIcon: PeopleTypeFemaleActive,
    text: "独居女性",
    desc: "更多挂衣空间",
    checked: false
  },
  {
    code: 3,
    value: PeopleType.COUPLE,
    icon: PeopleTypeCouple,
    activeIcon: PeopleTypeCoupleActive,
    text: "二人世界",
    checked: false
  },
  {
    code: 4,
    value: PeopleType.THREE,
    icon: PeopleTypeThree,
    activeIcon: PeopleTypeThreeActive,
    text: "三口之家",
    checked: false
  },
  {
    code: 5,
    value: PeopleType.FIVE_WITH_ONE_CHILD,
    icon: PeopleTypeFive,
    activeIcon: PeopleTypeFiveActive,
    text: "多口之家",
    desc: "更多抽屉收纳",
    checked: false
  },
  {
    code: 6,
    value: PeopleType.ELDER_COUPLE,
    icon: PeopleTypeOlder,
    activeIcon: PeopleTypeOlderActive,
    text: "年长夫妇",
    checked: false,
    desc: "更少弯腰操作",
  },
];