
import { PathObjectType } from 'ikea-jssdk';
class Miniprogram {
  /**
   * @description the page navigating to other miniprogram, only support app(h5 + native) and miniprogram(h5 + native)
   * @param appId the appId for miniprogram
   * @param name the name for miniprogram, required for app
   * @param path the path for miniprogram
   * @param embed whether to embed the half screen of miniprogram in the current page, default is false
   */
  public static Page (params: {appId: string, name?: string, path: string, embed?: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: '/miniprogram?name={{name}}&path=@command_encode({{path}})&embed={{embed}}',
        version: '>=1.0.0'
      },
      smp: {
        path: '/pages/jump_page/jump_page?type=miniProgram&appId={{appId}}&path=@command_encode({{path}})&embed={{embed}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        appId: {
          type: 'required',
          desc: 'the appId for miniprogram'
        },
        name: {
          type: 'optional',
          desc: 'the name for miniprogram, required for app'
        },
        path: {
          type: 'required',
          desc: 'the path for miniprogram'
        },
        embed: {
          type: 'optional',
          desc: 'whether to embed the half screen of miniprogram in the current page, default is false'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description the page navigating to FMC miniprogram
   * @param path the path for miniprogram
   */
  public static Fmc (params: {path: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: '/miniprogram?name=gh_eca4e999a0de&path=@command_encode({{path}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '/pages/jump_page/jump_page?type=miniProgram&appId=wx3fa784987d98fde2&path=@command_encode({{path}})'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        path: {
          type: 'required',
          desc: 'the path for miniprogram'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description the page navigating to shoppable miniprogram
   * @param path the path for miniprogram
   */
  public static Smp (params: {path: string}) {
    const pathObject: PathObjectType = {
      app: {
        path: '/miniprogram?name=gh_f8c80781c128&path=@command_encode({{path}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '/pages/jump_page/jump_page?type=miniProgram&appId=wxd47892129d2fa9e9&path=@command_encode({{path}})'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        path: {
          type: 'required',
          desc: 'the path for miniprogram'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
}
export default Miniprogram;
