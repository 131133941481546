import { useEffect, useState } from "react";
import { useStore } from "@/hooks/useStore";
import listCss from "./ServiceMask.module.scss";
import closeIcon from "@/assets/icons/close.svg";
import RadioButton from "@ingka/radio-button";
import Button from "@ingka/button";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/button/dist/style.css";

interface Props {
  isShowService: boolean;
  closeModelMask: () => void;
  getServiceVal: (screenMaterial: any) => void;
  serviceVal: storeItem;
  storeList: storeItem[];
}

interface storeItem {
  id: number;
  code: string;
  name: string;
  serviceVal: storeItem;
}

function ServiceMask(props: Props) {
  // const countrySelected = {
  //   name: "中国",
  //   id: "1",
  // };
  const {
    isShowService,
    closeModelMask,
    getServiceVal,
    serviceVal,
    storeList,
  } = props;
  const [screenMaterial, setScreenMaterial] = useState<storeItem>();

  const screenItemClick = (item: storeItem) => {
    setScreenMaterial(item);
  };
  const submitBtn = () => {
    closeModelMask();
    // const idx = storeList.map((item) => item.id === screenMaterial).indexOf(true);
    getServiceVal(screenMaterial);
  };

  useEffect(() => {
    setScreenMaterial(serviceVal);
  }, [isShowService]);

  // useEffect(() => {
  //   if (serviceVal === null && storeList.length > 0) {
  //     setScreenMaterial(storeList[0][0]);
  //   }
  // }, [storeList]);

  return (
    <div
      className={listCss["mask-size"]}
      role="none"
      onClick={closeModelMask}
      style={{ display: isShowService ? "" : "none" }}
    >
      <div
        className={listCss["mask-content"]}
        role="none"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={listCss["mask-content-title"]}>
          选择商场
          <div
            className={listCss["mask-content-close"]}
            role="none"
            onClick={closeModelMask}
          >
            <img src={closeIcon} alt="" />
          </div>
        </div>

        <div className={listCss["screen-content-card"]}>
          {storeList.map(
            (cityItem: any, index) =>
              cityItem[0]?.city && (
                <div key={index} className={listCss["checkbox-content"]}>
                  <div className={listCss["checkbox-content-title"]}>
                    {cityItem[0].city}
                  </div>
                  {cityItem.map((shopItem: storeItem) => (
                    <div
                      className={`
                 ${listCss["checkbox-item"]} 
                 ${
                   shopItem.code === screenMaterial?.code
                     ? listCss["checkbox-item-active"]
                     : ""
                 }
               `}
                      key={shopItem.id}
                      role="presentation"
                      onClick={() => screenItemClick(shopItem)}
                    >
                      <div>{shopItem.name}</div>
                      <div
                        className={`
                   ${listCss["checkbox-item-select"]}
                  
                  `}
                      >
                        <RadioButton
                          id={`ra-${shopItem.id}`}
                          subtle
                          checked={shopItem.code === screenMaterial?.code}
                          onChange={() => screenItemClick(shopItem)}
                          value="radio button value"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )
          )}
        </div>

        <div style={{ padding: "24px" }}>
          <Button fluid type="primary" text="保存" onClick={submitBtn} />
        </div>
      </div>
    </div>
  );
}

export default ServiceMask;
