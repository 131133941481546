import { IStitchingFrameColor } from "common/SelectExteriorStyleTypes";
import { ResourceManager } from "@aidesign/pax-render-2d";
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { getAllPropIn, getConfig } from "@/api/configAPIs";
import { getToken } from "@/api/request";
import { FrameColor, FROM_FLOW, PLATFORM, SlimFrame, STORAGE_KEYS } from "@/common/CommonType";
import { IRecommend } from '@/common/WardrobeTypes';
import modelCache from "@/utils/modelCache";
import { ICombine } from "@/components/Interior/FilterConfig";

export interface AvailableColors {
  colors: FrameColor[]
  depth: string,
  height: string
}

export interface FrameStockStatus {
  color: FrameColor,
  height: string,
  depth: string,
  widths: number[]
}

interface ConfigResponse {
  appCheckout: boolean;
  availableFrameColors: AvailableColors[];
  outOfStockFrames: FrameStockStatus[];
}

export interface ICachedLocation {
  pathname: string;
  search: string;
}

interface IPaxState {
  platform: PLATFORM;
  previousLocation: ICachedLocation | null;
  currentLocation: ICachedLocation | null;
  tokenLoaded: boolean;
  availableFrameColors: AvailableColors[];
  outOfStockFrames: FrameStockStatus[];
  recommendTemplate: IRecommend | null;
  propInsLoaded: boolean;
  inspireData: IInspireItem[];
  editTemplate: IRecommend | null;
  clickFrameSortNo: number;
  reloadClickArea: boolean;
  editStep: number;
  clickFrameUuId: string | null;
  frameColorReplacement: FrameColor[];
  stitchingFrameColorList: IStitchingFrameColor[];
  templateHasChanged: boolean;
  doorImage: string;
  noDoorImage: string;
  showToBottomBtn: boolean;
  hasToBottom: boolean;
  allFrames: SlimFrame[];
  filterCombineList: ICombine[],
  showSelectTip: boolean;
  detailOldTemplate: IRecommend | null;
  oldNewIdMap: any;
}

export interface IInspireData {
  data: IInspireItem[];
}

export interface IInspireItem {
  inspirationTemplateId: number;
  doorType: number;
  mainImageUrl: string;
  frameColor: number;
  mainImageWidth: number;
  mainImageHeight: number;
  doorImageUrl: string;
  handleImageUrl: string;
  doorDetailImageUrls: string[];
  components: IInspireItemComponent[];
  tags: IInspireItemTag[];
  slidingDoorFrameColor?: string;
}

export interface IInspireItemTag {
  tagName?: string;
  description: string;
}

interface IInspireItemComponent {
  componentId: number;
  type: number;
  productName: string;
  tags?: IInspireItemTag[];
}

export enum PaxActionType {
  Update,
  UpdateInspirationId,
  SavePurchaseList,
  SaveInspireData,
  UpdateTemplates,
  SaveActiveExterior
}

const initialPaxState: IPaxState = {
  platform: PLATFORM.H5,
  previousLocation: null,
  currentLocation: null,
  tokenLoaded: false,
  availableFrameColors: [],
  outOfStockFrames: [],
  recommendTemplate: null,
  propInsLoaded: false,
  inspireData: [],
  editTemplate: null,
  clickFrameSortNo: 0,
  reloadClickArea: true,
  editStep: 1,
  clickFrameUuId: null,
  frameColorReplacement: [],
  stitchingFrameColorList: [],
  templateHasChanged: false,
  doorImage: "",
  noDoorImage: "",
  showToBottomBtn: true,
  hasToBottom: false,
  allFrames: [],
  filterCombineList: [],
  showSelectTip: false,
  detailOldTemplate: null,
  oldNewIdMap: new Map()
}

interface UpdateAction {
  type: PaxActionType.Update,
  payload: Partial<IPaxState>,
}

interface UpdateInspirationIdAction {
  type: PaxActionType.UpdateInspirationId,
  payload: Partial<IPaxState>
}

interface SavePurchaseListAction {
  type: PaxActionType.SavePurchaseList,
  payload: Partial<IPaxState>,
}

interface SaveInspireDataAction {
  type: PaxActionType.SaveInspireData,
  payload: Partial<IInspireData>,
}

type Actions = UpdateAction | SavePurchaseListAction | SaveInspireDataAction | UpdateInspirationIdAction

const saveLocalStorage = (state: any) => {
  // 判断从哪里跳转到我们的页面，如果是主流程则需要保存状态，如果是非主流程则不需要保存状态
  const fromFlow = localStorage.getItem(STORAGE_KEYS.FROM_FLOW)
  if (fromFlow === FROM_FLOW.MAIN_FLOW) {
    localStorage.setItem(STORAGE_KEYS.PAX_GLOBAL_DATA, JSON.stringify(state))
  }
}

const paxReducer = (state: IPaxState, action: Actions): IPaxState => {
  switch (action.type) {
    case PaxActionType.Update:
      const newState = Object.assign({}, state, action.payload)

      saveLocalStorage({
        recommendTemplate: newState.recommendTemplate,
        editTemplate: newState.editTemplate,
        currentLocation: newState.currentLocation,
        platform: newState.platform,
        filterCombineList: newState.filterCombineList
      })
      return newState
    case PaxActionType.SavePurchaseList:
      return Object.assign({}, state, action.payload)
    case PaxActionType.SaveInspireData:
      return Object.assign({}, state, { inspireData: action.payload.data });
    case PaxActionType.UpdateInspirationId:
      const n = Object.assign({}, state, action.payload)
      return n;
    default:
      return state
  }
}

const init = () => {
  console.log("init pax state!!!!")
  try {
    const fromFlow = localStorage.getItem(STORAGE_KEYS.FROM_FLOW)
    const json = localStorage.getItem(STORAGE_KEYS.PAX_GLOBAL_DATA)
    if (fromFlow === FROM_FLOW.MAIN_FLOW && json) {
      const state = JSON.parse(json)
      return Object.assign({}, initialPaxState, state)
    }
  } catch (e) {
    console.warn("error to load localstorage pax settings!")
  }
  return initialPaxState
}

const PaxContext = createContext<{
  state: IPaxState,
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialPaxState,
  dispatch: () => undefined,
})

export const usePaxConfig = () => useContext(PaxContext)

export const PaxCtxProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(paxReducer, initialPaxState, init)

  const loadConfig = useCallback(async () => {
    modelCache.presetFramePersist()
    await getToken()
    const isLoaded = await modelCache.startPersist();
    getConfig<ConfigResponse>().then(res => {
      dispatch({
        type: PaxActionType.Update,
        payload: {
          tokenLoaded: isLoaded,
          availableFrameColors: res.availableFrameColors || [],
          outOfStockFrames: res.outOfStockFrames || [],
          allFrames: modelCache.frames || []
        }
      })
    })
    getAllPropIn<any[]>().then(res => {
      ResourceManager.updateRes([], [], res as any[])
      dispatch({
          type: PaxActionType.Update,
          payload: { propInsLoaded: true }
        }
      )
    })
  }, [])

  useEffect(() => {
    loadConfig()
  }, [loadConfig])

  return (
    <PaxContext.Provider value={{ state, dispatch }}>
      {children}
    </PaxContext.Provider>
  )
}
