import { v4 as uuids4 } from 'uuid';
import {
  IWardrobeWithProduct,
  ISlidingDoor,
  IPoint,
  IWardrobeDoorOrKnob,
  IProduct,
  IFrame,
  IComponent
} from 'common/WardrobeTypes';
import { IStitchingFrameColor } from 'common/SelectExteriorStyleTypes';
import { DOOR_TYPE } from 'common/CommonType';
import { cloneDeep, find } from 'lodash';

// export const noHandlerDoorList = ['40172749', '20172750', '60311565'] //合叶门中不能装把手的门的货号
//拼接滑门面板样式
const combinations = [{
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "A",
  "reverse": false,
  "colIndex": 0,
  "rowIndex": 0,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "A",
  "reverse": false,
  "colIndex": 1,
  "rowIndex": 0,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "B",
  "reverse": true,
  "colIndex": 0,
  "rowIndex": 1,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "B",
  "reverse": true,
  "colIndex": 1,
  "rowIndex": 1,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "A",
  "reverse": false,
  "colIndex": 0,
  "rowIndex": 2,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "A",
  "reverse": false,
  "colIndex": 1,
  "rowIndex": 2,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "B",
  "reverse": true,
  "colIndex": 0,
  "rowIndex": 3,
  "componentId": 0
}, {
  "side": 'DOUBLE_SIDE_REVERSIBLE',
  "style": "B",
  "reverse": true,
  "colIndex": 1,
  "rowIndex": 3,
  "componentId": 0
}]

interface IParams{
  door: IWardrobeDoorOrKnob;
  knob: IWardrobeDoorOrKnob | null;
  doorX: number;
  knobX: number | null;
}

interface ICommonData {
  id: string;
  name: string;
  products: IProduct[];
  componentId: number;
  locationType: string;
  startPoint: IPoint;
  occupiedWidth: number;
  occupiedDepth: number;
  occupiedHeight: number;
}
interface IKnobs extends ICommonData{
  relateItemId: string;
  relativeLocationType: string;
}
interface IDoorInfos{
  doors: ICommonData[];
  knobs: IKnobs[];
  hinges: {
    id: string;
    products: IProduct[];
    componentId: number;
    quantity: number;
    relateItemId: string; //相对于门
  }[],
  frameId:string;
}

export function clearDoor(wardrobeWithProduct: IWardrobeWithProduct) {
  wardrobeWithProduct.slidingDoors = [];
  wardrobeWithProduct.hingeDoors = [];
  return wardrobeWithProduct;
}

export const isMirrorDoor = (door:IWardrobeDoorOrKnob)=>{

  return door.products[0].name.indexOf('VIKEDAL') > -1 || door.products[0].name.indexOf('AULI') > -1 || door.products[0].name.indexOf('ÅHEIM') > -1
}

const checkBILLSBRO_LATMASK = (knob: IWardrobeDoorOrKnob | null): boolean => {
  if (knob === null) {
    return false
  }
  for (const p of knob.products) {
    if (p.name.indexOf("BILLSBRO") > -1 || p.name.indexOf("LATMASK") > -1) {
      return true;
    }
  }
  return false
}
// 聚合components中的products信息
const getProductsData = (products: IProduct[], modelUrl = "") => {
  return products.map((item: IProduct) => {
    return {
      id: item.id,
      name: item.name,
      globalItemNo: item.globalItemNo,
      localItemNo: item.localItemNo,
      mainProduct: item.mainProduct,
      imageUrl: item.imageUrl,
      modelUrl: item.modelUrl || modelUrl
    }
  })
}
// 选中滑门后，重组wardrobeWithProduct字段
export function getSlidingDoor(wardrobeWithProduct: IWardrobeWithProduct, door: IWardrobeDoorOrKnob, doorType: DOOR_TYPE, frame?: IStitchingFrameColor) {
  const frameIds = wardrobeWithProduct.frames.map((item: { id: string }) => item.id)
  const width = wardrobeWithProduct.totalLength;
  // 选中滑门后，合叶门的参数置空
  wardrobeWithProduct.hingeDoors = []
  // 初始化slidingDoors的值
  const slidingDoors: ISlidingDoor[] = []
  const doorItem:ISlidingDoor = {
    doorType: DOOR_TYPE.INTEGRAL, // 'INTEGRAL'- 整体滑门 'STITCHING'- 拼接滑门
    doorId: uuids4(),
    components: [],
    frameIds: []
  }
  //整体滑门和拼接滑门有区别
  if (doorType === DOOR_TYPE.INTEGRAL) { //整体滑门
    doorItem.doorType = DOOR_TYPE.INTEGRAL;
    // 拼接滑门以每对门为单位
    doorItem.components.push({
      componentType: DOOR_TYPE.INTEGRAL,
      componentId: door.componentId,
      products: getProductsData(door.products),
      occupiedWidth: door.width,
      heightOfAnchor: door.height,
      occupiedDepth: door.depth,
    })
  } else { //拼接滑门面板
    doorItem.doorType = DOOR_TYPE.STITCHING;
    // 拼接滑门以每块门为单位
    for (let i = 0; i < 2; i++){
      doorItem.components.push({
        componentType: DOOR_TYPE.STITCHING_PANEL,
        componentId: door.componentId,
        products: getProductsData(door.products),
        occupiedWidth: door.width,
        heightOfAnchor: door.height,
        occupiedDepth: door.depth,
      })
    }
    //拼接滑门框架（除了300宽的柜子会有两对门需要两个外框）
    if (frame) {
      doorItem.components.push({
        componentType: DOOR_TYPE.STITCHING_FRAME,
        componentId: frame.component.componentId,
        products: getProductsData(frame.component.products),
        occupiedWidth: frame.component.width,
        heightOfAnchor: frame.component.height,
        occupiedDepth: frame.component.depth,
      })
    }
    // 拼接滑门门块组合
    const newCombinations = cloneDeep(combinations)
    doorItem.combinations = newCombinations.map(c => {
      c.componentId = door.componentId
      console.log(door)
      c.side = door.side
      if (door.side === 'SINGLE_SIDE') {
        c.reverse = false
      }
      return c;
    })
  }
  slidingDoors.push(doorItem)
  //整体滑门没有门框，是以一对门为单位，拼接滑门有门框，是以一扇门为单位
  // 只有300的柜子会装两对滑门，所以slidingDoors的长度为2
  if (width === 3000) {
    slidingDoors.push(Object.assign({}, doorItem, { doorId: uuids4() }))
    slidingDoors[0].frameIds = frameIds.slice(0, 2)
    slidingDoors[1].frameIds = frameIds.slice(2, 4)
  }else{
    doorItem.frameIds = frameIds
  }
  Object.assign(wardrobeWithProduct, { slidingDoors })
  return wardrobeWithProduct
}
//计算合页门
export function getHingeDoor(wardrobeWithProduct: IWardrobeWithProduct, door: IWardrobeDoorOrKnob, mirrorDoor: IWardrobeDoorOrKnob, knob: IWardrobeDoorOrKnob | null, categoryComponents: Array<IWardrobeDoorOrKnob> | undefined) {
  wardrobeWithProduct.slidingDoors = []
  const hingeDoors: IDoorInfos[] = []
  let isSecond50Frame = true
  let params: IParams[]
  const knobMargin = checkBILLSBRO_LATMASK(knob) ? 10 : 50
  wardrobeWithProduct.frames.forEach((frame: IFrame, index: number) => {
    if (frame.width === 500) {
      isSecond50Frame = !isSecond50Frame || (index === wardrobeWithProduct.frames.length - 1 && index > 0)
      if (door.width === 250) {
        params = [
          { door, knob, doorX: 0, knobX: isSecond50Frame ? knobMargin : door.width - knobMargin },
          { door, knob, doorX: 500, knobX: isSecond50Frame ? knobMargin : 500 - door.width + knobMargin }
        ]
      } else {
        params = [
          { door, knob, doorX: isSecond50Frame ? 500 : 0, knobX: isSecond50Frame ? knobMargin : door.width - knobMargin }
        ]
      }
      
    }
    if (frame.width === 750) {
      if (door.width === 500) {
        // 判断是否有同品类的250宽的非镜门
        const sameTypeDoor = getSameType250Door(door, categoryComponents)
        // VIKEDAL维克多柜门镜玻璃 只能装 BILLSBRO比斯布罗把手，装在缝隙里
        // 根据VIKEDAL字段判断是:VIKEDAL维克多柜门镜玻璃
        // const konbPosition = door.pairedDoorComponent?.width === 370 ? 370 : 5000 
        params = [
          { door, knob, doorX: 0, knobX: isMirrorDoor(door) ? 500 : door.width - knobMargin },
          { door: sameTypeDoor || door.pairedDoorComponent || mirrorDoor, knob: sameTypeDoor ? knob : null, doorX: 750, knobX: 250 + knobMargin }
        ]
       
      }
      if (door.width === 370) { //只能装
        params = [
          { door, knob, doorX: 0, knobX: door.width - knobMargin },
          { door, knob, doorX: 750, knobX: knobMargin }
        ]
      }
      if (door.pairedDoorComponent?.width === 370) {
        params = [
          { door: door.pairedDoorComponent, knob, doorX: 0, knobX: door.pairedDoorComponent.width - knobMargin },
          { door: door.pairedDoorComponent, knob, doorX: 750, knobX: knobMargin }
        ]
      }
    }
    if (frame.width === 1000) {
      params = [
        { door, knob, doorX: 0, knobX: isMirrorDoor(door) ? 500 : door.width - knobMargin },
        { door, knob, doorX: 1000, knobX: isMirrorDoor(door) ? 0 : knobMargin }
      ]
    }
    hingeDoors.push(getHingeDoorInfo(frame.id, params))
  })
  Object.assign(wardrobeWithProduct, { hingeDoors })
  console.log(wardrobeWithProduct)
  return wardrobeWithProduct
}
// 找门板的相同类型250宽的门
export function getSameType250Door(doorComponent: IWardrobeDoorOrKnob, categoryComponents: Array<IWardrobeDoorOrKnob> | undefined) {
  if (!categoryComponents) return null
  const doorComp = categoryComponents.find((doorComp: IWardrobeDoorOrKnob) => {
    const doorMainProduct1 = find(doorComponent.products, { mainProduct: true })
    const curDoorMainProduct = find(doorComp.products, { mainProduct: true })
    if (!doorMainProduct1 || !curDoorMainProduct) return false
    return (doorMainProduct1.name === curDoorMainProduct.name &&
      doorMainProduct1.validDesignText === curDoorMainProduct.validDesignText &&
      doorComp.height === doorComponent.height &&
      doorComp.width === 250)
  })
  return doorComp
}

function getHingeDoorInfo(frameId: string, params: IParams[]) {
  const doorInfos: IDoorInfos = {
    doors: [],
    knobs: [],
    hinges: [],
    frameId: frameId
  }
  params.forEach(element => {
    const doorId = uuids4()
    doorInfos.doors.push(Object.assign({}, {
      id: doorId,
      products: getProductsData(element.door.products, element.door.modelUrl),
      componentId: element.door.componentId,
      name: element.door.componentName,
      occupiedWidth: element.door.width,
      occupiedHeight: element.door.height,
      occupiedDepth: element.door.depth,
      locationType: 'ABSOLUTE',
      startPoint: { x: element.doorX, y: 0, z: 0 }
    }))
    element.knob && doorInfos.knobs.push(Object.assign({}, {
      id: uuids4(),
      products: getProductsData(element.knob.products, element.door.modelUrl),
      componentId: element.knob.componentId,
      name: element.knob.componentName,
      locationType: 'RELATIVE',
      startPoint: { x: element.knobX || 0, y: 1000, z: 0 },
      relativeLocation: { x: element.knobX || 0, y: 1000, z: 0 },
      relateItemId: doorId,
      relativeLocationType: 'OUTSIDE',
      occupiedWidth: element.knob.width,
      occupiedHeight: element.knob.height,
      occupiedDepth: element.knob.depth,
    }))
    doorInfos.hinges.push(Object.assign({}, {
      id: uuids4(),
      componentId: element.door.hinge.componentId,
      quantity: element.door.hinge.componentId === 19 ? 3 : 4,
      relateItemId: doorId,
      products: getProductsData([element.door.hinge.product]),
    }))
  });
  return doorInfos;
}

const HingeDoorAnchors236 = [[1, 4], [30, 33], [36, 39], [65, 68]]
const HingeDoorAnchors201 = [[1, 4], [27, 30], [54, 57]]

const hasConflictAnchor = (comp: IComponent, height = 2360) => {
  if (comp.name.indexOf('分隔件') > -1 ||
    comp.name.indexOf('挂衣杆') > -1 ||
    comp.name.indexOf('鞋架') > -1 ||
    comp.locationType === 'RELATIVE'
  ) {
    return false
  }

  let anchors
  if (height === 2360) {
    anchors = HingeDoorAnchors236
  } else {
    anchors = HingeDoorAnchors201
  }

  let y1 = comp.startPoint.y
  let y2 = comp.startPoint.y + comp.heightOfAnchor - 1

  if (comp.name.indexOf('密网篮') > -1) {
    y1 = comp.startPoint.y + 5
    y2 = y1
  }

  for (const [a1, a2] of anchors) {
    if (!(a1 > y2 || a2 < y1)) {
      console.log("conflict component!", comp, anchors)
      return true
    }
  }
  return false
}

export const canInstallHingeDoor = (w: IWardrobeWithProduct): boolean => {
  for (const frame of w.frames) {
    for (const component of frame.components || []) {
      if (hasConflictAnchor(component, w.height)) {
        return false
      }
    }
  }
  return true
}

export const removeConflictsComponents = (w: IWardrobeWithProduct) => {
  for (const frame of w.frames) {
    frame.components = frame.components.filter((component) => {
      return !hasConflictAnchor(component, w.height)
    })
  }
}
