import React, { useState, useRef, useEffect } from "react";
import Styles from "./Reservation.module.scss";
import Privacy from "@/components/Privacy/Privacy";
import reservationBg from "@/assets/images/leads/reservation_v2_bg.jpg";
import installer from "@/assets/images/leads/installer.png";
import arrowDown from "@/assets/icons/arrow_down_black.svg";
import checked from "@/assets/icons/checked.svg";
import locationImg from "@/assets/icons/location.svg";
import ServiceMask from "@/components/ServiceMask/ServiceMask";
import Button from "@ingka/button";
import "@ingka/button/dist/style.css";
import BaseModal from "@/components/Modal/BaseModal";
import NavBar from "components/Navbar/NavBar";
import classnames from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { saveLeads } from "@/api/commonRequest";
import { TopToast } from "@/components/Toast/TopToast";
import Toast from "@/components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import { usePaxConfig } from "@/reducers/paxReducer";
import { pageViewGio } from "@/hooks/gio";
import { GIO, customize_leads_gio } from "@/utils/gioUtils";
import { getPageInfo, pageViewHelper } from "@/utils/common-tool";
import ikea from "ikea-jssdk";
import { useStore } from "@/hooks/useStore";
import { PLATFORM } from "@/common/CommonType";
const pvHelper = pageViewHelper();

const preList = [
  {
    title: "姓名*",
    value: "",
    verification: "请填写姓名",
  },
  {
    title: "手机号*",
    value: "",
    verification: "请填写手机号",
  },
];

function Reservation() {
  const inputRef: any = useRef([React.createRef(), React.createRef()]);
  const inputCheckRef: any = useRef([React.createRef(), React.createRef()]);
  const [inputFocus, setInputFocus] = useState("");
  const [getName, setName] = useState("");
  const [getPhone, setPhone] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [isShowPrivacy, setShowPrivacy] = useState(false); //隐私政策弹窗
  const [isShowService, setShowService] = useState(false); // 服务商场弹窗
  const [isShowOrder, setShowOrder] = useState(false); // 预约弹窗
  const [isShowTooltip, setShowTooltip] = useState(false); // 确认同意隐私政策
  const [serviceVal, setServiceVal] = useState<any>(null);
  const { storeList } = useStore();

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const timer: any = useRef();
  const tooltipTimer: any = useRef();
  const { state } = usePaxConfig();
  const { previousLocation, platform } = state;

  const setTooltipTimer = () => {
    clearTimeout(tooltipTimer.current);
    tooltipTimer.current = setTimeout(() => setShowTooltip(false), 3000);
  };

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.slice(1));

  enum sourceEnum {
    wardrobe_size = 1,
    wardrobe_quit = 2,
    wardrobe_search = 3,
    wardrobe_category = 4,
    sms_customized_service = 5,
    Store_PAXTool = 6,
    Store833_popup = 833
  }
  const source: any = searchParams.get("source") ?? "sms_customized_service";
  const from = searchParams.get("from") || '其他';
  const size = searchParams.get('size');
  const price = searchParams.get('price');

  const openToastMsg = (msg: string) => {
    setToastMsg(msg);
    setToastOpen(true);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setToastOpen(false);
    }, 2000);
  };

  const inputOnFocus = (e: any, index: number) => {
    setInputFocus(preList[index].title);
    inputCheckRef.current[index].current.style.display = "none";
  };

  const inputOnBlur = (index: number) => {
    if (index === 0) {
      setName(inputRef.current[0].current.value);
    } else {
      setPhone(inputRef.current[1].current.value);
    }
    setInputFocus("");
  };

  const getValue = (index: number) => {
    return index === 0 ? getName : index === 1 ? getPhone : "";
  };

  const handleCheck = () => {
    setIsCheck(!isCheck);
    if (isCheck) {
      setShowTooltip(true);
      setTooltipTimer();
    }
  };

  useEffect(() => {
    // 这么做是为了避免hook的时序,造成previousLocation获取不到正确值而造成的埋点prePage相关数据不正确
    pvHelper(
      pageViewGio.bind(
        null,
        previousLocation ? previousLocation.pathname : undefined,
        undefined,
        undefined,
        { pageSource_var: from || '其他' }
      )
    );
  }, [previousLocation]);

  useEffect(() => {
    document.title = "宜家定制衣柜服务";
    if (platform !== PLATFORM.H5) {
      ikea.getUserInfo().then((res: any) => {
        if (res.type === "success") {
          setPhone(res.value.mobile);
          setName(res.value.firstName);
        }
      });
    }

    // const previousPath = state.previousLocation ? state.previousLocation.pathname : ""
    // const previousPageInfo = getPageInfo(previousPath);
    // debugger;
    // GIO('track', 'pageView', {
    //   eventType_var: 'page_view',
    //   prePageType_var: previousPageInfo.pageType,
    //   prePageId_var: window.location.origin + previousPath,
    //   prePageName_var: previousPageInfo.pageName,
    //   pageSource_var: from || '其他',
    // });

    return () => {
      document.title = "打造你的PAX衣柜";
    };
  }, []);

  useEffect(() => {
    if (storeList.length > 0) {
      if (platform === PLATFORM.APP) {
        setServiceVal(storeList[0][0]);
      }
    }
  }, [storeList]);

  return (
    <div className={Styles.maskContainer}>
      <NavBar noClose text={"宜家定制衣柜服务"} noChat={true} />
      <div className={Styles.maskScroll}>
        <img className={Styles.maskPicture} src={reservationBg} alt="" />
        <div className={Styles.maskTitle}>*定制衣柜业务只能在门店进行，如需要请选择门店。</div>
        {/* 选择商场 */}
        <div className={Styles.maskMiddle}>
          <div className={Styles.arrowReac}>
            <input
              type="text"
              value={serviceVal?.name || "请选择服务商场"}
              onClick={() => {
                setShowService(true);
              }}
              className={Styles.maskInput}
              readOnly
            />
            <img className={Styles.arrowImg} src={arrowDown} alt="" />
          </div>
        </div>
        {/* 输入姓名电话 */}
        <div className={Styles.maskMiddle}>
          {preList.map((item, index) => (
            <div key={item.title} className={Styles.middleItem}>
              <div className={Styles.middleTitle}>{item.title}</div>
              <input
                ref={inputRef.current[index]}
                onFocus={(e) => inputOnFocus(e, index)}
                onBlur={() => inputOnBlur(index)}
                defaultValue={getValue(index)}
                type={item.title === "姓名*" ? "text" : "number"}
                className={classnames(Styles.maskInput, {
                  [Styles.activeInput]: inputFocus === item.title,
                })}
              />
              <div
                ref={inputCheckRef.current[index]}
                className={Styles.verification}
              >
                {item.verification}
              </div>
            </div>
          ))}
        </div>
        {/* 隐私政策 */}
        <div className={Styles.maskGou}>
          <div role="none" onClick={handleCheck} style={{ display: "flex" }}>
            {isCheck ? (
              <img src={checked} alt="" className={Styles.maskImg} />
            ) : (
              <Tooltip
                classes={{
                  popper: Styles.popper,
                  tooltip: Styles.tooltip,
                  arrow: Styles.MuiTooltipArrow,
                }}
                placement={"top-start"}
                open={isShowTooltip}
                arrow
                title="请先阅读并同意宜家隐私政策"
              >
                <div className={Styles.privacy} />
              </Tooltip>
            )}
          </div>
          <span className={Styles.agreement}>
            {"我已阅读并同意 "}
            <span
              onClick={() => {
                setShowPrivacy(true);
              }}
              role="none"
            >
              隐私政策
            </span>
          </span>
        </div>
        <div className={Styles.maskTip}>
          <div className={Styles.maskTipText}>
            预约完成后，会有相关宜家工作人员给您电话沟通。
          </div>
          <img src={installer} alt="installer" style={{ objectFit: 'cover' }} />
        </div>
      </div>
      <div className={Styles.maskFooter}>
        <Button
          fluid
          type="primary"
          text="立即预约"
          onClick={() => {
            if (!isCheck) {
              setShowTooltip(true);
              setTooltipTimer();
              return;
            }
            if (!serviceVal) {
              setShowService(true);
              return;
            }
            if (getName === "") {
              openToastMsg("请输入姓名");
              return;
            }
            if (getPhone === "") {
              openToastMsg("请输入手机号");
              return;
            }
            if (getName !== "" && getPhone !== "") {
              if (!/^1[3-9]\d{9}$/.test(getPhone)) {
                openToastMsg("请输入正确的手机号");
                return;
              }
              saveLeads({
                storeId: serviceVal.id,
                userName: getName,
                mobileNumber: getPhone,
                pageEnumId: parseInt(sourceEnum[source]),
                sourceStoreId: searchParams.get('utm_campaign') || '',
              }).then((res: any) => {
                if (res.errorCode === "ok") {
                  // setShowOrder(true);
                  customize_leads_gio(
                    previousLocation ? previousLocation.pathname : "",
                    "paxCustomizeLeads",
                    {
                      objectName_var: '立即预约',
                      objectType_var: '衣柜',
                      payAmount_var: price || '-',
                      size_var: size || '-',
                      pageSource_var: from,
                    }
                  ).then(() => {
                    searchParams.append('address', serviceVal.address);
                    searchParams.append('mobile', serviceVal.customizeMobile || '');
                    searchParams.append('tel', serviceVal.customizeTelephone || '' );
                    history.push( `/reserved/${serviceVal.name}?${searchParams.toString()}`);
                  });
                } else {
                  Toast.show({
                    content: "请求失败",
                  });
                }
              });
            }
          }}
        />
      </div>
      {/* 隐私政策弹窗 */}
      {isShowPrivacy ? (
        <Privacy
          materialMaskPdf={isShowPrivacy}
          closeMaskPdf={() => {
            setShowPrivacy(false);
          }}
        />
      ) : null}
      {/* 商场弹窗 */}
      {isShowService ? (
        <ServiceMask
          storeList={storeList}
          isShowService={isShowService}
          serviceVal={serviceVal}
          closeModelMask={() => {
            setShowService(false);
          }}
          getServiceVal={(serviceVal) => {
            setServiceVal(serviceVal);
          }}
        />
      ) : null}
      {/* 预约成功弹窗 */}
      <BaseModal
        open={isShowOrder}
        onClose={() => {
          setShowOrder(false);
        }}
      >
        <div className={Styles.Modal}>
          <div className={Styles.ModalTitle}>PAX 定制咨询 预约成功</div>
          <div className={Styles.ModalLocation}>
            <img src={locationImg} alt="" />
            <span>预约地点</span>
          </div>
          <div className={Styles.ModalStore}>{serviceVal?.name}</div>
          <div className={Styles.ModalText}>
            您将在近期收到 <span>400-800-2345</span>
            的电话沟通设计需求，请您注意接听来电。
          </div>
          <div className={Styles.ModalBtn}>
            <Button
              fluid
              type="primary"
              text="我知道了"
              onClick={() => {
                setShowOrder(false);
              }}
            />
          </div>
        </div>
      </BaseModal>
      {toastOpen && (
        <TopToast
          desc={toastMsg}
          // onClose={() => setIsShowToast(false)}
        ></TopToast>
      )}
    </div>
  );
}
export default Reservation;
