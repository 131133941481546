import { IWardrobeWithProduct } from "@/common/WardrobeTypes";

const getId = (id: string) => {
  return id.substring(0, 6)
}
// 修剪衣柜数据
export const trimWardrobe = (origin: IWardrobeWithProduct) => {
  try {
    const result: any = {
      id: origin.id,
      frames: [],
      slidingDoors: [],
      hingeDoors: [],
      combination: origin.combination,
      color: origin.color,
      height: origin.height,
      totalLength: origin.totalLength, // 宽度（mm）
      depth: origin.depth,
      wardrobeType: origin.wardrobeType,
      templateId: origin.templateId,
    }

    origin.frames.forEach((f) => {
      const slimFrame: any = {
        id: getId(f.id),
        color: f.color,
        depth: f.depth,
        frameId: f.frameId,
        frameType: f.frameType,
        height: f.height,
        location: f.location,
        sortNo: f.sortNo,
        templateId: f.templateId,
        width: f.width,
        components: [],
      }
      f.components.forEach((c) => {
        const newComp: any = {
          id: getId(c.id),
          componentId: c.componentId,
          locationType: c.locationType,
          name: c.name,
          startPoint: c.startPoint,
        }
        if (c.relateItemId) {
          newComp.relateItemId = getId(c.relateItemId)
        }
        slimFrame.components.push(newComp)
      })
      result.frames.push(slimFrame)
    })

    origin.hingeDoors?.forEach((h) => {
      const slimHingeDoor: any = {
        doors: [],
        knobs: [],
        frameId: getId(h.frameId),
      }

      h.doors?.forEach((d) => {
        const slimDoor: any = {
          componentId: d.componentId,
          locationType: d.locationType,
          startPoint: d.startPoint,
        }
        slimHingeDoor.doors.push(slimDoor)
      })
      h.knobs?.forEach((k) => {
        slimHingeDoor.knobs.push(k.componentId)
      })
      result.hingeDoors.push(slimHingeDoor)
    })

    origin.slidingDoors?.forEach(s => {
      const slimSlidingDoor: any = {
        frameIds: s.frameIds.map(f => getId(f)),
        combinations: s.combinations,
        doorType: s.doorType,
        doorId: getId(s.doorId),
        components: [],
      }
      s.components.forEach(c => {
        slimSlidingDoor.components.push({
          componentId: c.componentId,
        })
      })
      result.slidingDoors.push(slimSlidingDoor)
    })
    return result
  } catch (e) {
    console.error("trim template error:", e)
  }
  return origin
}
