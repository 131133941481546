import { IWardrobeDoorOrKnob } from 'common/WardrobeTypes';
import { EXTERIOR_IMAGE_TYPE } from 'common/CommonType';
import { IStitchingFrameColor, IWardrobeDoorOrKnobCategory } from '../common/SelectExteriorStyleTypes'
import { IWardrobeWithProduct } from '../common/WardrobeTypes';
import { IInspireItem } from '../reducers/paxReducer';
import { find } from 'lodash';


export function getDoorOrKnobByComponentId(knobData: Array<IWardrobeDoorOrKnobCategory>, componentId: number) {
  let target:any = null;
  for(const category of knobData) {
    target = category.components?.find(cmp => cmp.componentId === componentId);
    if (target) {
      target = { ...target, categoryName: category.categoryName }
      break;
    }
  }
  return { ...target };
}

export function getChangedDoor(doorDataList: IWardrobeDoorOrKnobCategory[], selectedDoor: IWardrobeDoorOrKnob) {
  let target:any = null;
  for(const category of doorDataList) {
    target = category.components.find(cmp => cmp.componentId === selectedDoor.componentId);
    if (target) {
      target = { ...target, categoryName: category.categoryName }
      break;
    }
  }
  if (target) {
    return target
  } else {
    for(const category of doorDataList) {
      const selectDoorMainProduct = selectedDoor.products.find(pro => !!pro.mainProduct);
      target = category.components.find(com => {
        const mainProduct = com.products && com.products.find(comPro => !!comPro.mainProduct);
        if (selectDoorMainProduct && mainProduct) {
          return selectDoorMainProduct.name === mainProduct.name && selectDoorMainProduct.validDesignText === mainProduct.validDesignText;
        } else {
          return false
        }
      })
      if (target) {
        target = { ...target, categoryName: category.categoryName }
        break;
      }
    }
  }
  // if (!target) {
  //   target = {...doorDataList[0].components[0], categoryName: doorDataList[0].categoryName}
  // }
  return target;
}

export function getStitchingFrameColorByComponentId(list: Array<IStitchingFrameColor>, componentId: number) {
  const result = list.find(item => item?.component?.componentId === componentId);
  return result;
}

export function getDefaultSelectedDoor(doorData: Array<IWardrobeDoorOrKnobCategory>) {
  const category = doorData?.[0];
  const components = category.components.filter(item => {
    return item.width !== 250;
  });
  const target = components[0];
  return (target ? { ...target, categoryName: category.categoryName } : {}) as IWardrobeDoorOrKnob
}

export function getSelectedDoorId(product: IWardrobeWithProduct) {
  const { slidingDoors=[], hingeDoors=[] } = product;
  if (hingeDoors.length > 0) {
    const id = +hingeDoors[0].doors?.[0].componentId
    return id === 69 ? 73 : id
  }
  if (slidingDoors.length > 0) {
    return +(slidingDoors[0].components?.filter(c => ['STITCHING_PANEL', 'INTEGRAL'].includes(c.componentType!))?.[0]?.componentId?? -1)
  }
  return -1;
}

export function getAllDoorsId(product: IWardrobeWithProduct) {
  const doorIds:number[] = []
  const { slidingDoors=[], hingeDoors=[] } = product;
  if (hingeDoors.length > 0) {
    hingeDoors.forEach(item => {
      item.doors?.forEach(subItem => {
        doorIds.push(+subItem.componentId)
      })
      // doorIds.push(+item.doors?.[0].componentId)
    })
  }
  if (slidingDoors.length > 0) {
    slidingDoors.forEach(item => {
      doorIds.push(+(slidingDoors[0].components?.filter(c => ['STITCHING_PANEL', 'INTEGRAL'].includes(c.componentType!))?.[0]?.componentId?? -1))
    })
  }
  return [...new Set(doorIds)]
}

export function getAllKnobsId(product: IWardrobeWithProduct) {
  const { hingeDoors=[] } = product;
  const knobIds:number[] = [];
  if (hingeDoors.length > 0) {
    hingeDoors.forEach(item => {
      item.knobs?.forEach(subItem => {
        knobIds.push(subItem.componentId)
      })
    })
  }
  return [...new Set(knobIds)]
}

export function getSelectedKnobId(product: IWardrobeWithProduct) {
  const { hingeDoors=[] } = product;
  if (hingeDoors.length > 0) {
    return hingeDoors[0].knobs?.[0]?.componentId ?? -1;
  }
  return -1;
}

export function getSelectedStitchingFrameColorId(product: IWardrobeWithProduct) {
  const { slidingDoors=[] } = product;
  if (slidingDoors.length > 0) {
    return slidingDoors[0].components?.filter(c => c.componentType === 'STITCHING_FRAME')?.[0]?.componentId ?? -1;
  }
  return -1;
}

export function getDoorKnobStitchingFrameIdsFromInspiration(inspireItem: IInspireItem) {
  const components = inspireItem.components;
  const door = components.find((comp: any) => comp.type === 1);
  const knob = components.find((comp: any) => comp.type === 2);
  const stitchingFrame = components.find((comp: any) => comp.type === 3);
  return {
    doorId: door?.componentId?? -1,
    knobId: knob?.componentId?? -1,
    stitchingFrameId: stitchingFrame?.componentId?? -1
  }
}

export function getMirrorDoor(doorCategoryList: Array<IWardrobeDoorOrKnobCategory>) {
  let target:any = null;
  for(const category of doorCategoryList) {
    target = category.components.find(cmp => {
      const doorMainProduct = find(cmp.products, { mainProduct: true })
      if (!doorMainProduct) return false;
      return (cmp.width === 250 && (doorMainProduct.name.indexOf("VIKEDAL") > -1 || doorMainProduct.name.indexOf("ÅHEIM") > -1))
    })
    if (target !== undefined) break;
  }
  return target as IWardrobeDoorOrKnob;
}

export function getQualityImages(door: IWardrobeDoorOrKnob) {
  const imageList = door?.images??[];
  if (imageList.length === 1) {
    return imageList.map(item => item.imageUrl)
  }
  return imageList.filter(item => item.imageType === EXTERIOR_IMAGE_TYPE.QUALITY_PRODUCT_IMAGE).map(item => item.imageUrl) || imageList.filter(item => item.imageType === EXTERIOR_IMAGE_TYPE.MAIN_PRODUCT_IMAGE).map(item => item.imageUrl);
}

// 是否是自带把手（不能装把手）的门板，依靠componentId区分
export function isOwnKnobDoor(componentId: number) {
  const specials = [1729, 1731, 1734, 1736, 1738, 1740];
  return specials.includes(componentId);
}
