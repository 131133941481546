import { getCookie } from "@/utils/cookie-tool";
import { isProd } from "@/utils/common-tool";

const API_HOST = isProd() ?
  "https://aid-common-service.aidesign.ingka-internal.cn/aid-common-api/api" :
  "https://aid-common-service-qa.aidesign.ingka-dt.cn/aid-common-api/api"

type TraceType = "track" | "error" | "perf" | "console"
type EventType = "click" | "view"
type Extra = Record<string, string | number>
type AidTrack = {
  traceType: TraceType,
  eventType: EventType,
  eventName: string,
  app: string,
  platform: string,
  pageId: string,
  pageName: string,
  previousPageId?: string,
  previousPageName?: string,
  deviceId: string,
  visitor?: Extra,
  biz?: Extra,
  extra?: Extra
}

const Visitor: Extra = {}
const Biz: Extra = {}
const APP = isProd() ? "pax-online" : "pax-online-qa"
let platform = "unknown"
let deviceId = "-"

const getDeviceId = () => {
  try {
    const userString = localStorage.getItem('gr_user_id') || "{}"
    const id1 = JSON.parse(userString).value || ""
    const id2 = getCookie('gr_user_id') || ""
    deviceId = id1 || id2 || deviceId
  } catch (e) {
    console.log(e)
  }
}

const init = (p: string) => {
  getDeviceId()
  platform = p || "unknown"
}

const setDeviceId = (id: string) => {
  deviceId = id
}

const setVisitor = (key: string, value: string | number) => {
  Visitor[key] = value
}

const setBiz = (key: string, value: string | number) => {
  Biz[key] = value
}

const send = (data: Partial<AidTrack>) => {
  try {
    const params: AidTrack = Object.assign({}, {
      traceType: "track",
      eventType: "default",
      eventName: "default",
      app: APP,
      platform,
      pageId: window.location.href,
      pageName: "default",
      deviceId,
      visitor: Visitor,
      biz: Biz,
    }, data)
    const blob = new Blob([JSON.stringify(params)], { type: 'application/json' })
    const url = API_HOST + '/trace'
    navigator.sendBeacon(url, blob)
  } catch (e) {
    console.log(e)
  }
}

export default {
  init,
  setVisitor,
  setBiz,
  setDeviceId,
  send
}
