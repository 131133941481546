import { PLATFORM } from "@/common/CommonType";
import qs from "query-string";
import { getCookie } from "./cookie-tool";
import { whichPlatform } from "./ikea-sdk";

export function copyToClipBoard(text: string) {
  try {
    const range = document.createRange();
    const selection = document.getSelection();
    const mark = document.createElement("span");
    mark.textContent = text;
    mark.style.all = "unset";
    mark.style.position = "fixed";
    mark.style.top = `0px`;
    mark.style.clip = "rect(0, 0, 0, 0)";
    mark.style.whiteSpace = "pre";
    mark.style.webkitUserSelect = "text";
    // @ts-ignore
    mark.style.MozUserSelect = "text";
    // @ts-ignore
    mark.style.msUserSelect = "text";
    mark.style.userSelect = "text";
    mark.addEventListener("copy", function (e: any) {
      console.log("-----fire copy");
      e.stopPropagation();
      e.clipboardData.clearData();
      e.clipboardData.setData("text/plain", text);
    });
    document.body.appendChild(mark);
    range.selectNodeContents(mark);
    selection?.addRange(range);
    var successful = document.execCommand("copy");
    if (!successful) {
      throw new Error("copy command was unsuccessful");
    }
  } catch (err) {
    try {
      // @ts-ignore
      window.clipboardData.setData(options.format || "text", text);
    } catch (err) {
    }
  }
}

/**
 * 旧hash路由重定向适配
 * @returns 重定向的url地址， 如无需重定向，则返回''
 */
export function legacyRedirectAdapter() {
  const { pathname, hash } = window.location;
  const [hashValue, search] = hash.slice(1)?.split("?") ?? [];
  const queryParams = qs.parse(search) as any;
  const { redirect, id, ...rest } = queryParams;
  let queryStr = "";
  let targetUrl = "";
  if (pathname === "/" && hashValue?.length > 0) {
    switch (hashValue as string) {
      // // 旧分享页面和旧offline扫码页面
      case "/embed":
        queryStr = qs.stringify({
          ...rest,
          from_qr: "y",
          // channel: 'smp',
          // source_page: 'detail',
        });
        targetUrl = `/${redirect === "y" ? "share" : "scan"}?${queryStr}`;
        break;
      // 旧分享页面（原始链接）
      case "/share":
        queryStr = qs.stringify({
          ...rest,
          storeId: 20,
          languageId: 1,
        });
        targetUrl = `/productList?${queryStr}`;
        break;
      // 旧设计步骤中间页、详情页，全部跳到首页
      case "/detail":
      case "/stepfirst":
      case "/stepsecond":
      case "/stepthird":
        targetUrl = `/`;
        break;
      default:
        break;
    }
  }
  return targetUrl;
}

export const compareVersion = (version1: string, version2: string) => {
  const arr1 = version1.split(".");
  const arr2 = version2.split(".");

  const len = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < len; i++) {
    let data1 = 0,
      data2 = 0;
    if (i < arr1.length) {
      data1 = parseInt(arr1[i]);
    }
    if (i < arr2.length) {
      data2 = parseInt(arr2[i]);
    }

    if (data1 < data2) {
      return -1;
    } else if (data1 > data2) {
      return 1;
    }
  }
  return 0;
};

export const getCurrentStep = () => {
  const routeList = [
    {
      step: "step_second_interior_design",
      page: "/interior_design",
    },
    {
      step: "step_second_wardrobe_list",
      page: "/wardrobe_list",
    },
    {
      step: "step_second_template_overview",
      page: "/template_overview",
    },
    {
      step: "step_third",
      page: "/selectExteriorStyle",
    },
    {
      step: "step_detail",
      page: "/detail",
    },
    {
      step: "step_share",
      page: "/share",
    },
    {
      step: "step_scan",
      page: "/scan",
    },
    {
      step: "step_productList",
      page: "/productList",
    },
    {
      step: "step_designList",
      page: "/designList",
    },
    {
      step: "step_first",
      page: "/",
    },
  ];
  for (let i = 0; i < routeList.length; i++) {
    const item = routeList[i];
    if (window.location.pathname.indexOf(item.page) > -1) {
      return item.step;
    }
  }
  return `step_${window.location.pathname}`;
};

// 判断是否生产环境
export function isProdMode() {
  const prodMode =
    ["prod"].includes(process.env.REACT_APP_HOST_TYPE as string) ||
    window.location.host === "paxonline.aidesign.ingka-internal.cn" ||
    window.location.host === "pax.web.ikea.cn";
  return prodMode;
}

export const SUPPORT_CART =
  compareVersion(getCookie("app_version") || "0", "2.3.0") >= 0;
export const SUPPORT_JS_BRIGE_IMAGE =
  compareVersion(getCookie("app_version") || "0", "2.4.0") >= 0;

// app环境需要判断版本显示保存到我的设计方案功能
export const supportSaveDesign = (platform: PLATFORM) => {
  const supportVersion = isProdMode() ? "3.11.0" : "3.10.0";
  const support =
    platform === PLATFORM.APP
      ? compareVersion(getCookie("app_version") || "0", supportVersion) >= 0
      : true;
  return support;
};

export async function reportScanDataToGio(params?: { designCode: string }) {
  const { search } = window.location;
  const [queryString] = search.slice(1)?.split("?") ?? [];
  const queryParams = qs.parse(queryString) as any;
  const { from_qr, qrSource = "planner", storeId, uniqueCode } = queryParams;
  if (from_qr !== "y") return;
  const platform = await whichPlatform();

  window.gio("track", "scanData", {
    eventType_var: "scan_success",
    platform_var: platform,
    pageId_var: params?.designCode || uniqueCode,
    pageType_var: "pax_scan",
    pageName_var: "衣柜详情",
    prePageName_var: "清单页_立柜",
    prePageType_var: "pax_inventory",
    prePageId_var: "-",
    objectType_var: "QRcode",
    objectId_var: params?.designCode || uniqueCode,
    attributeName1_var: "storeCode_var",
    attributeValue1_var: storeId,
    attributeName2_var: "qrSource_var",
    attributeValue2_var: qrSource,
    designCode_var: params?.designCode || uniqueCode,
    storeId_var: storeId,
  });
}

export async function reportClickDataToGioFromScan(params: {
  designCode: string;
  eventTypeVar: string;
  moduleVar: string;
}) {
  const { search } = window.location;
  const [queryString] = search.slice(1)?.split("?") ?? [];
  const queryParams = qs.parse(queryString) as any;
  const { from_qr, qrSource = "planner", storeId, uniqueCode } = queryParams;
  const pageInfo = getPageInfo();
  const platform = await whichPlatform();
  // todo: ?
  // window.gio('track', 'clickData', {
  //   eventType_var: 'click',
  //   platform_var: platform,
  //   pageType_var: pageInfo.pageType,
  //   pageId_var: window.location.href,
  //   pageName_var: pageInfo.pageName,
  //   module_var: params.moduleVar,
  //   objectName_var: '重新设计',
  // });
  if (from_qr !== 'y') return;
  window.gio('track', 'clickData', {
    eventType_var: params.eventTypeVar,
    platform_var: platform,
    pageType_var: "pax_scan",
    pageId_var: params.designCode || uniqueCode,
    pageName_var: "衣柜详情",
    prePageType_var: "pax_inventory",
    prePageName_var: "清单页_立柜",
    prePageId_var: "-",
    module_var: params.moduleVar,
    objectType_var: "pax_design",
    objectId_var: params.designCode || uniqueCode,
    objectName_var: "扫码衣柜",
    attributeName1_var: "storeCode_var",
    attributeValue1_var: storeId,
    attributeName2_var: "qrSource_var",
    attributeValue2_var: qrSource,
    designCode_var: params?.designCode || uniqueCode,
    storeId_var: storeId,
  });
}

export function isScanFromQRcode() {
  const { search } = window.location;
  const [queryString] = search.slice(1)?.split("?") ?? [];
  const queryParams = qs.parse(queryString) as any;
  const { from_qr } = queryParams;
  return from_qr === "y";
}

export async function reportPageViewToGioFromScan(params: {
  designCode: string;
  eventTypeVar: string;
}) {
  const { search } = window.location;
  const [queryString] = search.slice(1)?.split("?") ?? [];
  const queryParams = qs.parse(queryString) as any;
  const { from_qr, qrSource = "planner", storeId, uniqueCode } = queryParams;
  if (from_qr !== "y") return;
  const platform = await whichPlatform();

  window.gio("track", "pageView", {
    eventType_var: params.eventTypeVar,
    platform_var: platform,
    pageType_var: "pax_scan",
    pageId_var: params.designCode || uniqueCode,
    pageName_var: "扫码衣柜",
    prePageType_var: "pax_inventory",
    prePageName_var: "清单页_立柜",
    prePageId_var: "-",
    attributeName1_var: "storeCode_var",
    attributeValue1_var: storeId,
    attributeName2_var: "qrSource_var",
    attributeValue2_var: qrSource,
    storeId_var: storeId,
    designCode_var: uniqueCode,
  });
}

const pageConfig = {
  index: {
    pageType: "pax_requirement",
    pageName: "1/3衣柜基础信息",
  },
  interior_design: {
    pageType: "pax_requirement",
    pageName: "2/3选择内配方案",
  },
  wardrobe_list: {
    pageType: "pax_recommend",
    pageName: "2/3选择内配方案",
  },
  design_model: {
    pageType: "pax_editor_design",
    pageName: "2/3替换内配布局",
  },
  editor: {
    pageType: "pax_editor",
    pageName: "2/3替换内配布局",
  },
  template_overview: {
    pageType: "pax_template_overview",
    pageName: "2/3选择内配方案",
  },
  selectExteriorStyle: {
    pageType: "pax_adjustment",
    pageName: "3/3衣柜外观样式",
  },
  inspire: {
    pageType: "pax_inspiration",
    pageName: "寻找PAX外观灵感",
  },
  poster: {
    pageType: "pax_poster",
    pageName: "你的PAX衣柜",
  },
  scan: {
    pageType: "pax_design_scan",
    pageName: "你的PAX衣柜",
  },
  detail: {
    pageType: "pax_pip",
    pageName: "衣柜详情",
  },
  designList: {
    pageType: "pax_design_list",
    pageName: "衣柜详情",
  },
  custom_landingpage: {
    pageType: "pax_custom_landingpage",
    pageName: "定制衣柜_落地页",
  },
  wrd_landingpage: {
    pageType: "pax_wrd_landingpage",
    pageName: "全屋定制_落地页",
  },
  leads: {
    pageType: "pax_custom_leads",
    pageName: "定制衣柜_留资",
  },
  quotation: {
    pageType: "pax_custom_offer",
    pageName: "定制衣柜_报价",
  },
} as {
  [key: string]: {
    pageType: string;
    pageName: string;
  };
};

export function getPageInfo(pathName?: string) {
  const href = pathName || window.location.href;
  const key =
    Object.keys(pageConfig).find((item) => {
      return href.toLowerCase().includes(item.toLowerCase());
    }) || "index";
  return pageConfig[key];
}

export function impression(
  onImpression: () => void,
  dom: HTMLElement,
  once = true
) {
  let isExecuteOnce = false;
  const intersectionObserver = new IntersectionObserver((entries) => {
    if (once && isExecuteOnce) return;
    if (entries[0] && entries[0].intersectionRatio <= 0) return;
    onImpression();
    isExecuteOnce = true;
  });
  intersectionObserver.observe(dom);
}

export function pageViewHelper() {
  let timer: any = null;
  return (cb: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      typeof cb === "function" && cb();
    }, 0);
  };
}

export const isQA = () => {
  return (
    window.location.hostname === 'paxonline-qa.aidesign.ingka-dt.cn'
  )
}

export const isProd = () => {
  return (
    window.location.hostname === "paxonline.aidesign.ingka-internal.cn"
  )
}
