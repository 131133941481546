import { useCallback, useState, Dispatch, SetStateAction } from 'react';
import { useConfig } from '@/reducers/searchConfReducer';
import { usePaxConfig } from '@/reducers/paxReducer';
import { FrameColorList } from '@/constants/colors';
import { DOOR_TYPE_CN, PEOPLE_TYPE_CN, DoorType, PLATFORM } from '@/common/CommonType';
import { NEED_LIST } from '@/components/InteriorFilter/optionList';
import { DISLIKE_LIST } from '@/components/InteriorFilter/dislikeList';
import { getCscChannel, getCustomerService, getCustomerServiceV2, getGioPlatform, jumpStoreCompanionCS, whichPlatform } from '@/utils/ikea-sdk';
import { workTimeFn } from '@/utils/access-tool';
import { getCurrentStep } from '@/utils/common-tool';
import TemplateTool from '@/utils/template-tool';
import { InfoType } from "@/hooks/userInfo";
import { IDetailData } from "common/Detail";

// 聚合联系CSC数据
export const getCSCData = (detailData: Partial<IDetailData>) => {
  const {
    wardrobeWithProduct: {
      color,
      totalLength = 0,
      depth = 0,
      height = 0,
      hingeDoors,
      slidingDoors,
    } = {},
    purchaseList: { designCode = "" } = {},
  } = detailData;
  const cscData = {
    designCode,
    doorType: hingeDoors && hingeDoors.length > 0
      ? DoorType.HINGE
      : slidingDoors && slidingDoors.length > 0
      ? DoorType.SLIDING
      : DoorType.NONE,
    width: totalLength / 10,
    depth: depth / 10,
    height: height / 10,
    frameColor: color || "",
    isFromShare: true,
  };
  return cscData;
};

export interface ICSCData {
  isFromShare?: boolean
  designCode?: string
  doorType: DoorType
  width: number
  depth: number
  height: number
  frameColor: string
  needList?: object[],
  dislikeList?: string[],
}

export interface IResultCSC {
  autoText: string,
  isWorkTime: boolean,
  CSCContent: string,
  clickCSCHandle: (notNeedCSC?: boolean) => void;
  openCSC: boolean;
  setOpenCSC: Dispatch<SetStateAction<boolean>>,
  infoType: InfoType,
  showCSCTip: boolean,
  jumpCSC: () => void,
  jumpNewCSC: () => void,
}

const gioTrack = (platform: string, cscData?: ICSCData) => {
   try {
      window.gio('track', 'contactCSC', {
        'page_var': `online_${getCurrentStep()}`,
        'designCode_var': cscData?.designCode || "",
        'platform_var': platform,
        'workTime_var': workTimeFn()
      });
    } finally {
      console.log('gio埋点报错')
    }
}

export function useCSC(cscData?: ICSCData, customerText?:string, onClick?: () => void): IResultCSC {
  const { state } = useConfig();
  const { state: { recommendTemplate } } = usePaxConfig();
  const isWorkTime = workTimeFn();

  const [CSCContent, setCSCContent] = useState("");
  const [showCSCTip, setShowCSCTip] = useState(true);
  const [infoType, setInfoType] = useState<InfoType>(InfoType.NON_WORKING);
  const [openCSC, setOpenCSC] = useState(false);

  let autoText = '';
  if (customerText) {
    autoText = autoText + customerText
  } else if (cscData) {
    const { isFromShare = false, designCode = '' } = cscData;
    // 如果不是从分享来的，要通过之前设计的参数
    if (!isFromShare) {
      const {
        doorType,
        peopleType,
        needList = [],
        dislikeList = [],
      } = state;
      // 获取柜子的相关信息
      const templateTool = new TemplateTool(recommendTemplate?.wardrobeWithProduct)
      const width = templateTool.wardrobeWidth
      const depth = templateTool.wardrobeDepth
      const height = templateTool.wardrobeHeight
      const frameColor = templateTool.wardrobeFrameColor
      const fColor = FrameColorList.find(item => item.colorName === frameColor);
      // 如果有designCode，表示是在detail页，不然就是在前三步设计页
      const temp = [
        designCode ? `我设计的帕克思PAX衣柜设计代码：${designCode}，` : '我需要购买PAX衣柜，',
        `我的衣柜尺寸：${width > 300 ? '300以上' : width}cm宽，${height}cm高，${depth}cm深。`,
        `我选择的衣柜颜色${fColor ? fColor.displayName : ''}，`,
        `门板样式${DOOR_TYPE_CN[doorType]}。`,
        `衣柜使用人群${PEOPLE_TYPE_CN[peopleType]}。`,
      ];
      if (needList.length > 0) {
        temp.push(`我的储物需求：${NEED_LIST.filter(item => ~needList.findIndex(need => need.order === item.order)).map(item => item.name).join('，')}。`);
      }
      if (dislikeList.length > 0) {
        temp.push(`我不需要：${DISLIKE_LIST.filter(item => ~dislikeList.findIndex(dislike => dislike === item.id)).map(item => item.name).join('，')}。`);
      }
      // todo: 可能需要加购的信息
      autoText = temp.join('');
    }
    // 如果是从分享来的，也就是不通过前三步直接进来的，需要自己拼话术
    if (isFromShare) {
      const {
        doorType,
        width,
        depth,
        height,
        frameColor,
      } = cscData;
      const fColor = FrameColorList.find(item => item.colorName === frameColor);
      const temp = [
        designCode ? `我设计的帕克思PAX衣柜设计代码：${designCode}，` : '我设计的帕克思PAX衣柜',
        `我的衣柜尺寸：${width}cm宽，${height}cm高，${depth}cm深。`,
        `我选择的衣柜颜色${fColor ? fColor.displayName : ''}，`, 
        `门板样式${DOOR_TYPE_CN[doorType]}。`,
      ]
      autoText = temp.join('');
    }
  }

  const jumpCSC = useCallback(async () => {
    const gioPlatform = await getGioPlatform();
    const platform = await whichPlatform()
    gioTrack(gioPlatform, cscData)
    if (platform === PLATFORM.IKEA) {
      return window.location.href = encodeURI(`https://res.app.ikea.cn/page/customer_service.html?channel=pax&autoText=${autoText}`);
    }
    if (platform === PLATFORM.STORE_COMPANION) {
      return jumpStoreCompanionCS();
    }
    getCustomerService(autoText);
  }, [autoText, cscData]);

  const jumpNewCSC = useCallback(async () => {
    const gioPlatform = await getGioPlatform();
    const channel = await getCscChannel();
    const { designCode = '' } = cscData || {};
    gioTrack(gioPlatform, cscData)
    getCustomerServiceV2({ channel, designCode, autoText });
  }, [autoText, cscData]);

  const clickCSCHandle = async (notNeedCSC: boolean = false) => {
    onClick && onClick()
    // notNeedCSC,重新设计时这里为true，永远弹提示让客户留下电话号码
    if (!isWorkTime || notNeedCSC) {
      if (notNeedCSC) {
        setShowCSCTip(false);
        setInfoType(InfoType.RE_DESIGN);
        setCSCContent(
          "我们建议您留下联系方式，我们的 VIP 线上设计师将会尽快与您联系，并免费与您一起进行设计。"
        );
      } else {
        setInfoType(InfoType.NON_WORKING);
      }
      setOpenCSC(true);
      return;
    }
    jumpNewCSC(); // jumpNewCSC();
  };

  return {
    autoText,
    isWorkTime,
    jumpCSC,
    clickCSCHandle,
    openCSC,
    setOpenCSC,
    infoType,
    showCSCTip,
    CSCContent,
    jumpNewCSC,
  };
}
