import React, { createContext, useContext, useReducer } from 'react'
import { DoorType, PeopleType, STORAGE_KEYS } from "../common/CommonType";
import { debounce } from "lodash";

interface IConfigState {
  doorType: DoorType;
  // width: number;
  // height: FrameHeight;
  // depth: FrameDepth;
  // frameColor: FrameColor;
  peopleType: PeopleType;
  combination: string;
  fulfillLevel: string;
  doorTipVisibility: boolean;
  showDoorTip: boolean;
  needList: { order: number, id: number, important: boolean }[];
  dislikeList: string[];
  wallBg: number;
  floorBg: number;
  ranking: IRecommendType;
}

export enum SearchConfActionType {
  UpdateConfig,
  UpdateDoorTipTime,
  ResetSate
}

export enum IRecommendType {
  Priority = 'Priority',  //1
  SaleVolume = 'SaleVolume',//4
  MCCSimilarity = 'MCCSimilarity',//3
  StorageNeed = 'StorageNeed',//2
  Performance = 'Performance'//5
}

const initialConfigState: IConfigState = {
  doorType: DoorType.HINGE,
  // width: 200,
  // height: 236,
  // depth: 58,
  // frameColor: FrameColor.WHITE,
  peopleType: PeopleType.COUPLE,
  combination: '',
  fulfillLevel: '',
  doorTipVisibility: false,
  showDoorTip: false,
  needList: [
    {
      order: 0,
      id: 5,
      important: true
    },
    {
      order: 1,
      id: 10,
      important: false
    },
    {
      order: 2,
      id: 4,
      important: false
    },
    {
      order: 3,
      id: 1,
      important: false
    },
    {
      order: 7,
      id: 6,
      important: false
    },
    {
      order: 8,
      id: 6,
      important: false
    }
  ],
  dislikeList: [],
  wallBg: 1,
  floorBg: 1,
  ranking: IRecommendType.Priority
}

interface UpdateConfig {
  type: SearchConfActionType.UpdateConfig,
  payload: Partial<IConfigState>,
}

interface UpdateDoorTipTime {
  type: SearchConfActionType.UpdateDoorTipTime,
  payload: undefined | null
}

interface IResetSate {
  type: SearchConfActionType.ResetSate,
  payload: Partial<IConfigState> | undefined | null
}

type Actions = UpdateConfig | UpdateDoorTipTime | IResetSate

const checkLastNoDoorTipTime = (): boolean => {
  const lastTimestamp = (localStorage.getItem(STORAGE_KEYS.NO_DOOR_TIPS_TIMEOUT)) || 0
  return Number(lastTimestamp) < new Date().getTime() - 60 * 60 * 24 * 1000
}

const saveLocalStorage = debounce((state) => {
  console.log("save!!!")
  localStorage.setItem(STORAGE_KEYS.SEARCH_CONFIG, JSON.stringify(state))
}, 1000)

const searchConfReducer = (state: IConfigState, action: Actions): IConfigState => {
  switch (action.type) {
    case SearchConfActionType.UpdateConfig:
      const newState = Object.assign({}, state, action.payload)
      newState.doorTipVisibility = checkLastNoDoorTipTime()
      newState.showDoorTip = newState.doorTipVisibility && newState.doorType === DoorType.NONE
      saveLocalStorage(newState)
      return newState
    case SearchConfActionType.UpdateDoorTipTime:
      localStorage.setItem(STORAGE_KEYS.NO_DOOR_TIPS_TIMEOUT, String(new Date().getTime()))
      return Object.assign({}, state, { doorTipVisibility: false, showDoorTip: false })
    case SearchConfActionType.ResetSate:
      return Object.assign({}, initialConfigState, action.payload)
    default:
      return state
  }
}

const Context = createContext<{
  state: IConfigState,
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialConfigState,
  dispatch: () => undefined,
})

const init = () => {
  console.log("init state!!!!")
  try {
    const json = localStorage.getItem(STORAGE_KEYS.SEARCH_CONFIG)
    if (json) {
      const state = JSON.parse(json)
      state.doorTipVisibility = checkLastNoDoorTipTime()
      state.showDoorTip = false;
      return Object.assign({}, initialConfigState, state)
    }
  } catch (e) {
    console.warn("error to load localstorage settings!")
  }
  return initialConfigState
}

export const useConfig = () => useContext(Context)

export const SearchConfigCtxProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(searchConfReducer, initialConfigState, init)
  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  )
}
