import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { InternalToast, ToastProps } from './Toast'

const toastArray: (() => void)[] = []

export const noop = () => { }
export function resolveContainer(
  getContainer: HTMLDivElement | HTMLElement | null | undefined
) {
  const container = getContainer
  // console.log('getContainer', getContainer)
  return container || document.body
}

export type ToastShowProps = Omit<ToastProps, 'visible'>

export function show(p: ToastShowProps | string) {
  const props = typeof p === 'string' ? { content: p } : p
  let timer = 0
  const { afterClose = noop, getContainer = document.body } = props
  const container = document.createElement('div')
  const bodyContainer = resolveContainer(getContainer)
  bodyContainer.appendChild(container)
  const state = { duration: props.icon === 'loading' ? 0 : 2000, ...props }
  const TempToast = () => {
    const [visible, setVisible] = useState(true)

    // clearDOM after animation
    const _afterClose = () => {
      afterClose()
      setVisible(false)
      // const unmountResult = ReactDOM.unmountComponentAtNode(container)
      // if (unmountResult && container.parentNode) {
      //   container.parentNode.removeChild(container)
      // }
    }

    // close with animation
    const destroy = useCallback(() => {
      setVisible(false)
    }, [])

    useEffect(() => {
      _clear()
      // 当duration有值时，将push放到event loop中，这样就不会被event loop中的clear销毁掉（eg:请求失败后，在catch中做toast提示）
      timer = window.setTimeout(() => {
        toastArray.push(_afterClose)
      })
      return () => {
        window.clearTimeout(timer)
      }
    }, [])

    useEffect(() => {
      if (state.duration !== 0 && 'duration' in state) {
        timer = window.setTimeout(destroy, state.duration)
      }
      return () => {
        if (timer !== 0) {
          window.clearTimeout(timer)
        }
      }
    }, [destroy])

    // 当修改 duration 时，重新计时
    useEffect(() => {
      if ('duration' in state && state.duration !== 0) {
        window.clearTimeout(timer)
        timer = window.setTimeout(destroy, state.duration)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.duration])

    return (
      <InternalToast
        {...state}
        getContainer={container}
        visible={visible}
        afterClose={_afterClose}
      />
    )
  }
  ReactDOM.render(<TempToast />, container)
}

// 同步的销毁
function _clear() {
  let fn = toastArray.pop()
  while (fn) {
    fn()
    fn = toastArray.pop()
  }
}

// 针对 toast 还没弹出来就立刻销毁的情况，将销毁放到下一个 event loop 中，避免销毁失败。
export function clear() {
  setTimeout(_clear, 100)
  _clear()
}
