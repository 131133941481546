import { useCallback, useEffect, useState, useRef } from 'react';
import { PLATFORM } from '@/common/CommonType';
import { isLogin, addToShoppingCart, goLogin, addCart, getGioPlatform, whichPlatform, addToCart, goToCartPage, goToCartPageInStoreCompanion } from '@/utils/ikea-sdk';
import { getCurrentStep } from '@/utils/common-tool';
import Toast from "components/Toast";

export interface IProduct {
  productFullId: string
  quantity: number
}

export function useCart(params: { productList: IProduct[] }) {
  const { productList } = params;
  const [login, setLogin] = useState(false);
  const refProds = useRef(productList);

  useEffect(() => {
    (async () => {
      const res = await isLogin();
      setLogin(res);
    })();
  }, []);

  const updateProductList = (list: IProduct[]) => {
    refProds.current = list;
  }

  const gioTrack = (platform: string, price: number, designCode:string) => {
    const step = getCurrentStep()
    try {
      window.gio('track', 'addToBag', {
        'page_var': `online_${step}`,
        'designCode_var': designCode,
        'platform_var': platform,
        'payAmount_var': price
      });
    } finally {
      console.log('gio埋点')
    }
  }

  const goCart = useCallback(async (price, designCode) => {
    const gioPlatform = await getGioPlatform();
    gioTrack(gioPlatform, price, designCode)
    const platform = await whichPlatform()
    // 全部使用sdk加购
    const res = await addToCart(refProds.current)
    if (res.status !== 200) {
      Toast.show('加购失败');
      return;
    } else {
      const { products } = res.data;
      const p = products[0];
      // 调用sdk的加购可能因为某些原因无法加入购物车，所以需要判断，如果不能加购成功，则取消后续的跳转操作
      if (p.added === 0) {
        Toast.show(p.message || '加购失败');
        return;
      }
      if (platform === PLATFORM.STORE_COMPANION) {
        await goToCartPageInStoreCompanion();
      } else {
        await goToCartPage()
      }
    }
    // if (platform === PLATFORM.SMP) {
    //   // 在smp用新的今后统一的jssdk
    //   const products = refProds.current.map(({ productFullId, quantity }) => {
    //     const isVTP = /^v/i.test(productFullId);
    //     return {
    //       id: isVTP ? productFullId.slice(1) : productFullId,
    //       itemType: isVTP ? 'VTP' : 'ART',
    //       quantity,
    //     };
    //   })
    //   addToShoppingCart({
    //     products: JSON.stringify(products)
    //   });
    //   return;
    // }

    // if (platform === PLATFORM.APP) {
    //   // 在app暂时沿用老一套，等app发版后再看，临时方案
    //   await window.flutter_inappwebview.callHandler('addToCart', { products: refProds.current })
    //   await window.flutter_inappwebview.callHandler('push', {
    //     path: 'https://ikeaapp/shopping_cart',
    //     popCurrent: true
    //   })
    //   return;
    // }
    // if (platform === PLATFORM.IKEA) {
    //   // 加入购物车时，未登录的话跳到登录页进行登录
    //   if (!login) return goLogin(window.location.href)
    //   // 在官网，这个是错误的折中方案
    //   await addCart(refProds.current);
    //   window.location.href = `https://${window.location.host === 'res-qa.app.ikea.cn' ? 'www-qa.web.ikea.cn' : 'www.ikea.cn'}/cn/zh/pay/cart`;
    //   return;
    // }
  }, [refProds]);

  return {
    goCart,
    updateProductList,
  };
}
