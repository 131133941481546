import { useCallback, useEffect, useState } from 'react';
import { getUserInfo, setUserInfo } from '@/api/commonRequest';
import Toast from '@/components/Toast';
import { isLogin } from '@/utils/ikea-sdk';

interface IUserInfo {
  mobileNumber?: number;
  customerName?: number;
}

export enum InfoType {
  OUT_STOCK = 'OUT_STOCK',
  NON_WORKING = 'NON_WORKING',
  RE_DESIGN = 'RE_DESIGN',
  L_SHAPE = 'L_SHAPE',
  U_SHAPE = 'U_SHAPE'
}

export function useUserInfo(params: any) {
  const { templateId } = params;
  const [customerName, setCustomerName] = useState('尊敬的客户您好')
  const [defaultTel, setDefaultTel] = useState('尊敬的客户您好')
  const [inputVal, setInputVal] = useState('')
  const [showEdit, setShowEdit] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showErrorTel, setShowErrorTel] = useState(false)
  const [login, setLogin] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await isLogin();
      setLogin(res);
    })();
  }, []);

  const editTel = () => {
    setShowEdit(true)
    setInputVal('')
  }

  const onInputChange = (e: any) => {
    let value = e.target.value.trim();
    if (value.length >= 11) {
      value = value.slice(0, 11)
    }
    setInputVal(value)
   };
  
  const getUsrInfoData = useCallback(() => {
    if (login) {
      getUserInfo().then((res: any | IUserInfo) => {
        setShowEdit(false)
        setDefaultTel(res.mobileNumber)
        setInputVal(res.mobileNumber)
        setCustomerName(res.customerName)
      }).catch(e => {
        // 请求失败的话默认显示编辑电话号码状态
        setShowEdit(true)
        setInputVal('')
      })
    } else {
      editTel()
    }
  }, [login])

  const setUsrInfoData = (infoType: InfoType) => {
    setUserInfo({
      mobileNumber: inputVal,
      templateId,
      infoType
    }).then((res: any | IUserInfo) => {
      setDefaultTel(res.mobileNumber)
      setInputVal(res.mobileNumber)
      setCustomerName(res.customerName)
      setShowEdit(false)
      setShowSuccess(true)
    }).catch(e => {
      Toast.show({
        content: '请求失败，请重新尝试。'
      })
    })
  }

  const submit = (infoType: InfoType) => {
    if (inputVal.length !== 11) return
    if (inputVal === defaultTel || /^1[3-9]\d{9}$/.test(inputVal)) {
      console.log('正确')
      setUsrInfoData(infoType)
    } else {
      console.log('您输入的号码格式错误，请输入正确的 11 位数电话号码。')
      setShowErrorTel(true)
    }
  }

  return {
    customerName, // 客户名称
    inputVal,  // 电话号码输入值
    setInputVal,
    showEdit, // 是否显示编辑电话号码的状态
    setShowEdit,
    editTel, // 点击修改电话号码按钮
    onInputChange, // input输入时
    getUsrInfoData, // 获取用户信息
    setUsrInfo: submit, // 保存号码
    showSuccess, // 保存成功是否显示成功提示弹窗
    setShowSuccess,// 保存成功设置是否显示成功提示弹窗
    showErrorTel, // 号码格式错误提示弹窗
    setShowErrorTel,
    
  };
}
