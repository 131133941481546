import Styles from "./tip.module.scss";

interface ITipProps {
  desc: string;
}

export default function Tip(props: ITipProps) {
  const { desc } = props;
  return <div className={Styles.tip}>{desc}</div>;
}
