import { post, get } from './request';
export interface IGetBestMatchParams {

}

interface IDetailParams  {
  templateId?: number;
  uniqueCode?: string;
  zipcode: number;
  province: string;
  city: string;
}

interface ISuits{
  templateId?: number;
  uniqueCode?: string;
}

// 获取最佳搭配
export const getMyDesign = <T>(params:Partial<IDetailParams>): Promise<T> => {
  return  post('UserDesignWardrobe/v3/getMyDesign', { ...params }, { isSilence: true })
}
// 获取最佳搭配
export const getBestMatch = <T>(params:Partial<IGetBestMatchParams>): Promise<T> => {
  return  post('/TemplatePro/v2/bestFriends', { ...params }, { isSilence: true })
}
//相似商品
export const getRecommendedProducts = <T>(params:Partial<IGetBestMatchParams>): Promise<T> => {
  return  post('/TemplatePro/v2/recommendedProducts', { ...params }, { isSilence: true })
}
// 获取套餐
export const getSuits = <T>(params: Partial<ISuits>): Promise<T> => {
  return  post('/UserDesignWardrobe/v3/getSuits', { ...params }, { isSilence: true, isErrorSilence: true })
}
// 加购
export const getVps = <T>(params: Partial<ISuits>): Promise<T> => {
  return  post('/UserDesignWardrobe/v2/vps', { ...params }, { isSilence: true })
}

export const getProductInfo = <T>(componentId: number): Promise<T> => {
  return get(`/component/${componentId}`, {
    headers: {
      languageId: 1
    }
  })
}

export const getHiResImage = <T>(params: any): Promise<{ data: string }> => {
  return post('/offline/render/3d', { ...params }, { isSilence: true })
}
