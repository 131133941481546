import styles from "./Price.module.scss";
import cn from "clsx";

export enum PriceType {
  Primary, // 常规ui展示
  Invalid, // 带有中划线的展示
  Flat, // tag整数小数齐平的展示
  Poster, // 海报页的展示
  PosterPage,
  DTC
}

interface IProps {
  priceData: ITemplatePriceList[] | number;
  type?: PriceType;
  rootClass?: string;
  intClass?: string;
  floatClass?: string;
}
interface ITemplatePriceList {
  currencyCode?: string;
  price: number;
}

type Tag = "$" | "¥";
interface PriceObj {
  price: number;
  integerPart: number;
  fractionalPart: number;
  tag: Tag;
  integerPartStr: string;
  fractionalPartStr: string;
}

/**
 * @description: 将数字转化为带有逗号的字符串
 * @param {number} num
 * @return {*}
 */
function formatNumberByThousands(num: number): string {
  let result = "";
  const reverseNumArr = num.toString().split("").reverse();
  reverseNumArr.forEach((item, index) => {
    index > 0 && index % 3 === 0 && (result += ",");
    result += item;
  });
  return result.split("").reverse().join("");
}

/**
 * @description: 将数字类型价格转化为价格对象用于渲染
 * @param {number} price
 * @return {*}
 */
function getFormatPrice(price: number): PriceObj {
  const integerPart = Math.floor(price);
  const fractionalPart = Math.round((price - integerPart) * 100);
  const integerPartStr = formatNumberByThousands(integerPart);
  const fractionalPartStr = fractionalPart.toString().padEnd(2, "0");
  return {
    price,
    integerPart,
    fractionalPart,
    tag: "¥",
    integerPartStr: integerPartStr,
    fractionalPartStr: fractionalPartStr,
  };
}

/**
 * @description: 渲染不同的价格展示类型
 * @param {*} type
 * @param {PriceObj} priceObj
 * @return {*}
 */
function getTemplateByPriceType(type = PriceType.Primary, priceObj: PriceObj) {
  switch (type) {
    case PriceType.Primary:
      return (
        <div className={cn("inline-flex text-111111", styles.bold)}>
          <span className={styles.currencySymbol}>{priceObj.tag}</span>
          <span className={styles.currencyInt}>{priceObj.integerPartStr}</span>
          <span className={styles.currencyDecimal}>
            .{priceObj.fractionalPartStr}
          </span>
        </div>
      );
    case PriceType.Invalid:
      return (
        <div className="inline-flex text-767676 line-through text-xs">
          <span>{priceObj.tag}</span>
          <span>{priceObj.integerPartStr}</span>
          <span>.{priceObj.fractionalPartStr}</span>
        </div>
      );
    case PriceType.Flat:
      return (
        <div className={cn("inline-flex text-111111 text-sm", styles.bold)}>
          <span className="transform scale-90">{priceObj.tag}</span>
          <span>{priceObj.integerPart}</span>
          <span>.{priceObj.fractionalPartStr}</span>
        </div>
      );
    case PriceType.DTC:
      return (
        <div className={cn("inline-flex text-111111 text-sm", styles.bold, styles.dtc)}>
          <span >{priceObj.tag}</span>
          <span>{priceObj.integerPart}</span>
          <span>.{priceObj.fractionalPartStr}</span>
        </div>
      );
    case PriceType.PosterPage:
      return (
        <div className={cn("inline-flex text-111111", styles.bold)}>
          <span className={cn(styles.currencySymbol, styles.posterPage)}>{priceObj.tag}</span>
          <span className={cn(styles.currencyInt, styles.posterPage)}>{priceObj.integerPartStr}</span>
          <span className={cn(styles.currencyDecimal, styles.posterPage)}>
            .{priceObj.fractionalPartStr}
          </span>
        </div>
      );
    case PriceType.Poster:
      return (
        <div className={styles.bold}>
          <sup className="text-xs">{priceObj.tag}</sup>
          <span className="text-xl">{priceObj.integerPart}</span>
          <sup className="text-xs">.{priceObj.fractionalPartStr}</sup>
        </div>
      );
  }
}

const Price: React.FC<IProps> = (props) => {
  const { priceData, type } = props;
  const priceObj =
    typeof priceData === "number"
      ? getFormatPrice(priceData)
      : getFormatPrice(priceData[0].price);
  return (
    <div className={cn(styles.Price, props.rootClass)}>
      {getTemplateByPriceType(type, priceObj)}
    </div>
  );
};

export default Price;
