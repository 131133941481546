import AIImage from "@/assets/icons/ai.png";
import exampleImage from "@/assets/icons/example.svg";
import Styles from "./aiTip.module.scss";
import Modal from "@material-ui/core/Modal";
import classnames from "clsx";

interface AIProps {
  desc: string;
  onClose?: () => void;
  calssName?: string;
}

export function AITip(props: AIProps) {
  const { desc, onClose, calssName } = props;
  return (
    <div className={classnames(Styles.aiTip, calssName)}>
      <div className={Styles.avatar}>
        <img src={AIImage} alt="" />
      </div>
      <div className={Styles.right}>
        <div className={Styles.title}>PAX AI 小助手</div>
        <div className={Styles.content}>{desc}</div>
        <div className={Styles.know}>
          <span
            onClick={() => {
              onClose && onClose();
            }}
          >
            我知道了
          </span>
        </div>
      </div>
    </div>
  );
}

interface AIDialogProps {
  open: boolean;
  noCloseIcon?: boolean;
  onClose?: (event?: any, reason?: string) => boolean | void;
}

export enum AIPosition {
  InteriorFilter = "InteriorFilter",
  WardrobeList = "WardrobeList",
}

export function AIDialog(props: AIDialogProps) {
  const { open, onClose } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.aiDialog}>
        <div className={Styles.top}>
          <div className={Styles.avatar}>
            <img src={AIImage} alt="" />
          </div>
          <div className={Styles.right}>
            <div className={Styles.title}>PAX AI 小助手</div>
            <div className={Styles.content}>
              我们会根据您选择的储物需求，智能地对内部空间进行综合推荐。一般常见的分布如下：
            </div>
          </div>
        </div>
        {/* <div className={Styles.ModalNoDoorImage}>
        <img src={NoDoorImage} alt={'designer'}/>
      </div> */}
        <div className={Styles.exampleImg}>
          <img src={exampleImage} alt="" />
        </div>
        <div className={Styles.exampleText}>示例图片</div>
        <div className={Styles.knowBtn} onClick={onClose}>
          我知道了
        </div>
      </div>
    </Modal>
  );
}
