import { PLATFORM } from '@/common/CommonType';
import { whichPlatform, getAddressInfo } from '@/utils/ikea-sdk';

export function workTimeFn() {
  const timeNow = new Date().getHours();
  return timeNow >= 9 && timeNow < 22;
}

interface IResultPlatform {
  platform: PLATFORM
}

export async function fetchCurrentPlatform(): Promise<IResultPlatform> {
  let platform = PLATFORM.H5;
  try {
    platform = await whichPlatform();
  } catch(err) {
  }
  return { platform };
};

async function getCityInApp() {
  console.log('window.flutter_inappwebview',window.flutter_inappwebview)
  if (!window.flutter_inappwebview) return null
  try {
    if (typeof window.flutter_inappwebview?.callHandler === 'function') {
      console.log('获取native city成功')
      return await window.flutter_inappwebview.callHandler('getCity')
    } else {
      console.log('获取native city失败')
      return new Promise((resolve, reject) => {
        window.addEventListener("flutterInAppWebViewPlatformReady", async (event) => {
          console.log('等待初始化获取城市')
          if (typeof window.flutter_inappwebview?.callHandler === 'function') {
            const cityInfo = await window.flutter_inappwebview?.callHandler('getCity')
            console.log('获取城市成功',JSON.stringify(cityInfo))
            resolve(cityInfo);
          } else {
            reject(null);
          }
        })
      })
    }
  } catch(e) {
    console.log('getCity:', e)
    return null
  }
}

export async function  getCity() {
  let info = null;
  const platform = await whichPlatform();
  if (platform === PLATFORM.APP) {
    info = await getCityInApp();
  }
  if (platform === PLATFORM.SMP) {
    info = await getAddressInfo();
  }
  return info;
}
