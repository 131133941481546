import Styles from "./topToast.module.scss";
import CrossIcon from "@/assets/icons/cross.svg";
import ikea from "ikea-jssdk";
interface ITopToastProps {
  desc: string; // 描述
  descHref?: any; // 链接描述
  aHref?: string; // 链接
  onClose?: () => void;
}
export function TopToast(props: ITopToastProps) {
  const { desc, descHref, aHref, onClose } = props;

  const navigateTo = () => {
    aHref && ikea.navigateTo(aHref)
  }
  return (
    <div className={Styles.topToast}>
      {desc && <span>{desc}</span>}
      {descHref && aHref &&
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
        <a className={Styles.aHref} onClick={navigateTo} href='javascript:void(0)'>{descHref}</a>
      }
      <img className={Styles.image} src={CrossIcon} onClick={() => onClose && onClose()} alt="" />
    </div>
  );
}
