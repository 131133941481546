import { IIsShowStepGuide } from '@/common/CommonType';
import { abTestGetVariation, getDeviceId, whichPlatform, getAbtestList } from './ikea-sdk';
import * as Sentry from "@sentry/react"
import { getCookie } from "@/utils/cookie-tool"
import { GIO } from './gioUtils';
import aidTrack from "@/utils/aid-track";

function uploadDeviceInfo(abTestName: string, result: string) {
  getDeviceId().then(value => {
    const uploadDeviceMessage = async () => {
      const gioUuid = getCookie('gr_user_id')
      const platform = await whichPlatform()

      const message = `ikeaDeviceId:${value},gioUuid:${gioUuid},abName:${abTestName},abResult:${result},platform:${platform},
      url:${window.location.href},cookie:${document.cookie}`
      console.log("device message", message);
      Sentry.captureMessage(message);
    }
    setTimeout(uploadDeviceMessage, 2000)
  })
}

/**
 *
 * pax_online_redesign  :  off   100%
 */

const getAbTestPromise = () => {
  getAbtestList().then(res => {
    const abData = res.value
    /**
     * @description: 点击加购时，让顾客直接加购SKUBB（新加购方案），不需要提示（原加购方案），并且和无加购对比
     * @param {*} new
     * @return {*}
     */
    const skubb = abData['pax_skubb_popup'] || 'SHOW'
    sessionStorage.setItem('abctestPaxSkubbPopup', skubb);
    window.gio('visitor.set', 'abctest_pax_skubb_popup', skubb);

    const adjustRenderArea = abData['pax_adjust_render_area'] || 'clickable'
    sessionStorage.setItem('abTestAdjustRenderArea', adjustRenderArea);
    window.gio('visitor.set', 'abtest_pax_adjust_render_area', adjustRenderArea);

    const increaseAtv = abData['pax_increase_atv'] || 'SHOW'
    sessionStorage.setItem('abtestPaxIncreaseAtv', increaseAtv);
    window.gio('visitor.set', 'abtest_pax_increase_atv', increaseAtv);

    const recommendation = abData['pax_recommendation'] || 'unknown'
    sessionStorage.setItem('abtestPaxRecommendation', recommendation === 'unknown' ? 'SHOW' : recommendation);
    uploadDeviceInfo('abtest_pax_recommendation', recommendation);
    GIO('track', 'enterPAX', {
      attributeName1_var: "pax_recommendation_var",
      attributeValue1_var: `FY230315_${recommendation}`
    })
    window.gio('visitor.set', 'abtest_pax_recommendation', `FY230315_${recommendation}`);

    const mirrorFree = abData['pax_mirror_free'] || 'SHOW'
    sessionStorage.setItem('abtestPaxMirrorFree', mirrorFree);
    window.gio('visitor.set', 'abtest_pax_mirror_free', mirrorFree);

    const paxCustomLead = abData['abtest_pax_customlead'] || 'SHOW'
    sessionStorage.setItem('abtestPaxCustomlead', paxCustomLead);
    window.gio('visitor.set', 'abtest_pax_customlead', paxCustomLead);

    const issueAA = abData['pax_issue_aa'] || 'unknown'
    window.gio('visitor.set', 'pax_issue_aa', issueAA);

    const simpleQuotation = abData['pax_simple_quotation'] || 'SHOW'
    sessionStorage.setItem('abtestPaxSimpleQuotation', simpleQuotation);
    window.gio('visitor.set', 'abtest_pax_simple_quotation', simpleQuotation);

    const onlineRedesign = abData['pax_online_redesign'] || 'off'
    sessionStorage.setItem('abtestPaxOnlineRedesign', onlineRedesign);
    window.gio('visitor.set', 'abtest_pax_redesign', onlineRedesign);

    const online2offline = abData['pax_online2offline'] || 'SHOW'
    sessionStorage.setItem('abtestPaxOnline2offline', online2offline);
    window.gio('visitor.set', 'abtest_pax_online2offline', online2offline);
    // 新推荐降价策略
    const abtestRecommend = abData['abtest_pax_hot_decrease'] || 'CONTROL'
    sessionStorage.setItem('abtest_pax_hot_decrease', abtestRecommend);
    window.gio('visitor.set', 'abtest_pax_hot_decrease', abtestRecommend);
    aidTrack.setVisitor('abtest_pax_hot_decrease', abtestRecommend)
  })
}

export default getAbTestPromise
